import React from "react";
import style from "./index.module.scss";
import useRequest from "../../../Utils/useRequest";
import {getNoticeDetail} from "../../../Service/Notice";
import {useParams} from "react-router-dom"
import moment from "moment";
import {useTitle} from "react-use";

interface IParams {
    id:string;
}

export default function NoticeDetail() {

    useTitle("通知详情", {restoreOnUnmount: true});
    const {id} = useParams<IParams>()
    const [noticeDetail] = useRequest(() => getNoticeDetail(id), {}, [id]);

    return (
        <div className={style.root}>
            <div className={style.container}>
                {
                    noticeDetail && (
                        <>
                            <div className={style.title}>{noticeDetail.title}</div>
                            <div className={style.time}>
                                <div>{moment.unix(parseInt(noticeDetail.addtime)).format("YYYY-MM-DD")}</div>
                            </div>
                            {/*富文本编辑*/}
                            <div className={style.content}>
                                <div className={style.body} style={{display:'inline-block'}} dangerouslySetInnerHTML={{__html: noticeDetail.content}}/>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
}
