import Request from "Utils/Requests";
import qs from "qs";
import _ from "underscore";

const BaseUrl = process.env.REACT_APP_BASE_URL;

export interface PersonBaseInfo{
    qq: string;
    specialty: string;
    wxCode: string;
    weight: string;
    politicalFace: string;
    phone: string;
    belief: string;
    email: string;
    height: string;
    hobby: string;
    busSection: string;
}

export interface StudentInfo extends PersonBaseInfo {
    college: string;
    nation: string;
    sex: string;
    enterYear: string;
    eduSystem: string;
    employeeNo: string;
    major: string;
    cardId: string;
    name: string;
}

/**
 * 入学登记-个人基础信息查询
 */
export function getStudentInfo() {
    return Request.get<SuccessData<StudentInfo>>(`${process.env.REACT_APP_BASE_URL}/zhxg/getStudentInfo`)
        .then(data => data.data.data);
}

interface EditPerson extends PersonBaseInfo{
    checkPhoneCode: string;
}

/**
 * 入学登记-个人基础信息修改
 */
export function updateStudentInfo(params: EditPerson) {
    return Request.post<SuccessData<StudentInfo>>(`${process.env.REACT_APP_BASE_URL}/zhxg/updateStudentInfo`, qs.stringify({...params}))
        .then(data => data.data.data);
}

/**
 * 获取手机验证码
 * @param phone
 */
export function sendCheckCodeMessage(phone: string) {
    return Request.post<SuccessData<StudentInfo>>(`${process.env.REACT_APP_BASE_URL}/zhxg/sendCheckCodeMessage`, qs.stringify({phone}))
        .then(data => data.data.data);
}

/**
 * 手机验证码校验
 * @param phone
 * @param checkPhoneCode
 */
export function editPhoneCheckCode(phone: string, checkPhoneCode: string) {
    return Request.post<SuccessData<StudentInfo>>(`${process.env.REACT_APP_BASE_URL}/zhxg/editPhoneCheckCode`, qs.stringify({phone, checkPhoneCode}))
        .then(data => data.data.data);
}


export enum DictId {
    nation = 'xg_nation', // 民族
    shengfen = 'xg_shengfen_code',  // 省份
    political = 'xg_political', // 政治面貌
    religious = 'religious_code', // 宗教信仰
    kelei = 'kelei_code', // 科类
    pici = 'xg_pici_code', // 批次
    kaoshengleibie = 'kaoshengleibie_code', // 考生类别
    waiyuyuzhong = 'xg_waiyuyuzhong_code', // 外语语种
    jiatangguanxi = 'xg_jiatangguanxi_code', // 紧急联系人与本人关系/与学生关系
    leave_type = 'xg_leave_type', // 请假类型
    stationjob_time = 'xg_stationjob_time_slot', // 接站时间
    pick_station_location = 'xg_pick_station_location', // 接站地点
    enrollment_year = 'xg_enrollment_year', // 入学年月
    station_time_slot = 'xg_station_time_slot', // 到校时间
    transportation = 'xg_transportation', // 交通方式
}

interface DictItem {
    label: string;
    value: string;
}

/**
 * 字典id（1.政治面貌，2.宗教信仰，3.请假类型，4.交通方式，5.接站时间，6.接站地点7.入学年月）
 * @param dictId
 */

export function getDict(dictId: DictId) {
    return Request.post<SuccessData<{dataList: DictItem[]}[]>>(`${process.env.REACT_APP_BASE_URL}/zhxg/getDict`, qs.stringify({dictId}))
        .then(data => data.data.data[0].dataList);
}

/**
 * 从数据字典中获取值
 * @param value
 * @param dict
 */
export function findDictValue(value: string, dict: DictItem[]) {
    return dict.find((item) => item.value === value)?.label || "";
}

export interface FamilyInfo{
    address: string;
    urgentRelation: string;
    urgentConcat: string;
    urgentPhone: string;
}
/**
 * 入学登记-家庭基础信息查询
 */
export function getFamilyBasicInfo() {
    return Request.get<SuccessData<FamilyInfo>>(`${process.env.REACT_APP_BASE_URL}/zhxg/getFamilyBasicInfo`)
        .then(data => data.data.data);
}

interface AreaInfo{
    [key: string] : {
        name: string;
        child: {
            [key: string]:{
                name: string;
                child: {[key: string]: string}
            }
        }
    }
}

/**
 *  全国地区接口
 */
export function getAreaInfo() {
    return Request.post<SuccessData<AreaInfo>>(`${process.env.REACT_APP_BASE_URL}/zhxg/getAreaInfo`)
        .then(data => formatAreaData(data.data.data));
}

function formatAreaData(data: AreaInfo) {
    let res: {
        value: string;
        label: string;
        children: {
            value: string;
            label: string;
            children?: {
                value: string;
                label: string;
            }
        }[]
    }[] = [];

    _.map(data, (item) => {
       res.push({
           value: item.name,
           label: item.name,
           children: _.keys(item.child).length > 1 ?
               provinceData(_.values(item.child)) :
               municipalityData(_.values(item.child).length > 0 ? _.values(item.child)[0].child : item.child)
       })
    });
    return res;
}

/**
 * 省份数据
 * @param data
 */
function provinceData(data: any) {
    let res: {
        value: string;
        label: string;
        children: {
            value: string;
            label: string;
        }[]
    }[] =  [];
    if(data.length > 0) {
        res = data.map((item: {name: string; child: {[key: string]: string}}) => ({
            value: item.name,
            label: item.name,
            children: municipalityData(item.child)
        }));
    }
    return res;
}

/**
 * 直辖市数据
 * @param data
 */
function municipalityData(data: any) {
    let res: {value: string; label: string}[] = [];
     res = _.map(data, (item) => (
        {
            value: item,
            label: item
        }
    )).filter((target: any) => target.value !== '市辖区');
    return res;
}


/**
 * 点击签到时调用
 */

export function CheckIn() {
    return Request.get(`/zhxg/QRReport`)
}

/**
 * 点击在线签到
 */

export function onlineReportCheckIn() {
    return Request.get(`/zhxg/onlineReport`)
}


/**
 * 入学登记-家庭基础信息填写/修改
 * @param params
 */
export function updateFamilyBasicInfo(params: FamilyInfo) {
    return Request.post<SuccessData<{label: string; value: string}[]>>(`${process.env.REACT_APP_BASE_URL}/zhxg/updateFamilyBasicInfo`, qs.stringify({...params}))
        .then(data => data.data.data);
}

export interface Member{
    nation: string;
    phone: string;
    sex: string;
    name: string;
    birth: string;
    company: string;
    id: number;
    politicalFace: string;
    relation: string;
    perfect: string;
    income: string;
    healthy: string;
}

/**
 * 入学登记-查询家庭成员
 */
export function getFamilyMember() {
    return Request.get<SuccessData<Member[]>>(`/zhxg/getFamilyMember`)
        .then(data => data.data.data);
}

export type MemberInfo = Omit<Member, 'id'> & {
    income: string;
    healthy: string;
};

/**
 * 入学登记-查看家庭成员具体信息
 * @param id
 */
export function getMemberInfo(id: string) {
    return Request.get<SuccessData<MemberInfo>>(`/zhxg/getMemberInfo?id=${id}`)
        .then(data => data.data.data);
}

export type MemberNew = Omit<Member, 'id'>;

/**
 * 入学登记-添加家庭成员
 * @param params
 */
export function addFamilyMember(params: MemberNew) {
    return Request.post<SuccessData<any>>(`/zhxg/addFamilyMember`, qs.stringify({...params}))
        .then(data => data.data.data);
}

/**
 * 入学登记-修改家庭成员信息
 * @param params
 */
export function updateMemberInfo(params: Member) {
    return Request.post<SuccessData<any>>(`/zhxg/updateMemberInfo`, qs.stringify({...params}))
        .then(data => data.data.data);
}

/**
 * 入学登记-删除家庭成员
 * @param id
 */
export function deleteMember(id: number) {
    return Request.get<SuccessData<any>>(`/zhxg/deleteMember?id=${id}`)
        .then(data => data.data);
}

/**
 * 扫描签到二维码调用该接口
 */
export function updateSignQRFlag() {
    return Request.get(`/zhxg/updateSignQRFlag`)
}

/**
 * 查询乘车区间
 * @param params
 */
interface RideSection {
    quanpin:string;//拼音
    siteName:string;//车站名称
}

export function getRideSection(name:string) {
    return Request.post<SuccessData<RideSection[]>>(`/zhxg/getRideSection`, qs.stringify({name}))
        .then(data => data.data.data);
}
