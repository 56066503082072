import React from "react";
import style from "./index.module.scss";
import toastTop from "./img/toastTop.png"
import leftArr from "./img/leftArr.png"

interface IProps {
    toastHight?: string;  //容器高
    title?: string;
    tip?: React.ReactElement | string;
    children?: React.ReactElement;
}

export default function ConfirmToast({title, toastHight, tip, children}: IProps) {

    return (
        <div className={style.root}>
            <div className={style.container}>
                <div className={style.contDiv}>
                    <div className={`${style.toastCard} ${toastHight}`}>
                        <div>
                            <img src={toastTop} alt="" className={style.toastTop}/>
                            <div className={style.title}>{title}</div>
                        </div>

                        <div>
                            <div className={style.arr}>
                                <img src={leftArr} alt="" className={style.leftArr}/>
                                <img src={leftArr} alt="" className={style.rightArr}/>
                            </div>
                            <div className={style.tip}>
                                {tip}
                            </div>
                        </div>

                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
