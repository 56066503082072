import {useEffect, useRef} from "react";

interface useTitleOptions {
    restoreOnUnmount: boolean;
}

export default function useTitle(title: string, options?: useTitleOptions) {

    const {restoreOnUnmount = false} = options || {};
    const prevTitleRef = useRef(document.title);

    useEffect(function () {

        document.title = title;

        if (restoreOnUnmount) {
            return function () {
                document.title = prevTitleRef.current;
            };
        }
    }, [title]);
}
