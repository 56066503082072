import React, {useMemo, useState} from "react";
import ReportPage from "../../../Components/ReportPage";
import style from "./index.module.scss";
import classNames from "classnames";
import {Button} from "zarm";
import useRequest from "../../../Utils/useRequest";
import {getHomeStudentInfo} from "../../../Service/Home";
import {getLoveGift} from "../../../Service/GreenChannel";
import {useTitle} from "react-use";

export default function GiftBag() {

    useTitle("绿色通道", {restoreOnUnmount: true});
    const [stuInfo] = useRequest(() => getHomeStudentInfo())
    const [isReceive, setReceive] = useState(false)

    const handleReceive = () => {
        getLoveGift().then(res => setReceive(true))
    }

    //是否申请贫困生
    const poorFlag = useMemo(() =>{
        if(stuInfo?.poorFlag === '1') return true;
        return false;
    },[stuInfo?.poorFlag])

    //是否申请助学贷款
    const loanPic = useMemo(() =>{
        if(stuInfo?.loanPic !== '') return true;
        return false;
    },[stuInfo?.loanPic])

    return (
        <div className={style.root}>
            {stuInfo && <div className={style.container}>

                <div className={style.card}>
                    <div className={style.stuInfo}>
                        <ReportPage data={stuInfo}/>
                    </div>
                    <div className={style.state}>
                        <div className={classNames({[style.active] : poorFlag })}>{poorFlag ? '已申请贫困生' : '未申请贫困生'}</div>
                        <span className={classNames(style.line, {[style.lineHidden] : poorFlag !== loanPic})}>|</span>
                        <div className={classNames({[style.active] : loanPic})}>{loanPic  ? '已申请助学贷款' : '未申请助学贷款'}</div>
                    </div>
                </div>

                {/*按钮区域*/}
                <div className={style.btn}>
                    {(stuInfo.giftFlag === '0' && !isReceive) &&
                    <Button theme="primary" className={style.button} onClick={handleReceive} disabled={stuInfo.poorFlag === "1"?false:true}>确认领取</Button>}
                    {(stuInfo.giftFlag === '1' || isReceive ) &&
                    <Button theme="primary" className={style.alreadyButton} >已领取</Button>}
                </div>
            </div>}
        </div>
    );
}
