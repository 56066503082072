import React from "react";
import style from "./index.module.scss";
import buttonListIcon from "./img/buttonListIcon.png";
import xxjj from "./img/xxjj.png";
import xs from "./img/xs.png";
import tjsj from "./img/tjsj.png";
import gydc from "./img/gydc.png";
import yxsz from "./img/yxsz.png";
import bkjy from "./img/bkjy.png";
import yjsjy from "./img/yjsjy.png";
import lhjy from "./img/lhjy.png";
import jxjy from "./img/jxjy.png";
import xydh from "./img/xydh.png";
import kyjg from "./img/kyjg.png";
import szdw from "./img/szdw.png";

export default function ButtonList() {

    return (
        <div className={style.container}>
            <div className={style.buttonListDiv}>
                <div className={style.headerDiv}>
                    <div className={style.headerTitle}>
                        <img src={buttonListIcon}/>
                        <div className={style.headFont}>了解东财</div>
                        <div className={style.row}></div>
                    </div>
                </div>
                <div className={style.buttonRow}>
                    <div className={style.buttonDiv} onClick={() => window.location.href='https://www.dufe.edu.cn/xxgk/xxjj/'}>
                        <img src={xxjj}/>
                        <div className={style.buttonFont}>学校简介</div>
                    </div>
                    <div className={style.buttonDiv} onClick={() => window.location.href='https://www.dufe.edu.cn/xxgk/xiaoshi/'}>
                        <img src={xs}/>
                        <div className={style.buttonFont}>校史</div>
                    </div>
                    <div className={style.buttonDiv} onClick={() => window.location.href='https://www.dufe.edu.cn/gkfw/dh/'}>
                        <img src={xydh}/>
                        <div className={style.buttonFont}>校园导航</div>
                    </div>
                    <div className={style.buttonDiv} onClick={() => window.location.href='https://www.dufe.edu.cn/rcpy/bk/'}>
                        <img src={bkjy}/>
                        <div className={style.buttonFont}>本科教育</div>
                    </div>
                    <div className={style.buttonDiv} onClick={() => window.location.href='https://www.dufe.edu.cn/rcpy/yjs/'}>
                        <img src={yjsjy}/>
                        <div className={style.buttonFont}>研究生教育</div>
                    </div>
                </div>
                {/*todo:差来华教育和科研机构链接*/}
                <div className={style.buttonRow}>
                    <div className={style.buttonDiv}  onClick={() => window.location.href='https://www.dufe.edu.cn/rcpy/lxs/'}>
                        <img src={lhjy}/>
                        <div className={style.buttonFont}>来华教育</div>
                    </div>
                    <div className={style.buttonDiv} onClick={() => window.location.href='https://www.dufe.edu.cn/yxsz/'}>
                        <img src={yxsz}/>
                        <div className={style.buttonFont}>院系设置</div>
                    </div>
                    <div className={style.buttonDiv} onClick={() => window.location.href='https://www.dufe.edu.cn/xxgk/tjsj/'}>
                        <img src={tjsj}/>
                        <div className={style.buttonFont}>统计数据</div>
                    </div>
                    <div className={style.buttonDiv}  onClick={() => window.location.href='https://www.dufe.edu.cn/rcpy/szdw/'}>
                        <img src={szdw}/>
                        <div className={style.buttonFont}>师资队伍</div>
                    </div>
                    <div className={style.buttonDiv} onClick={() => window.location.href='https://www.dufe.edu.cn/xxgk/gydc/'}>
                        <img src={gydc}/>
                        <div className={style.buttonFont}>光影东财</div>
                    </div>
                </div>

            </div>
        </div>
    );
}
