import React from "react";
import style from "./index.module.scss";
import classNames from "classnames";
import right from "./img/success.png";
import error from "./img/error.png";

export default function Tips ({orphanFlag, reason}:{orphanFlag:string, reason?:string}) {
    return(

        // 孤儿审核状态（0.审核中，1.审核通过，2.审核未通过）
        <div className={style.root}>
            {
                orphanFlag === '0' && <div className={classNames(style.tip)}><div className={style.xing}>*</div><div>回执单已上传，等待校方审核</div></div>
            }

            {
                orphanFlag === '1' && <div className={classNames(style.tip,{[style.tipSuccess]:true})}><img src={right} alt="" className={style.iconPic}/>审核已通过，无需交费</div>
            }

            {
                orphanFlag === '2' && <div className={classNames(style.tip,{[style.tipError]:true})}><img src={error} alt="" className={style.iconPic}/>审核未通过，{reason}</div>
            }
        </div>
    )
}
