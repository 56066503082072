import React from "react";
import style from "./index.module.scss";
import {Button} from "zarm";
import {useHistory} from "react-router-dom";

interface Props {
    reportStatus?:string;
}

export default function ZaixianReport({ reportStatus}:Props) {

    const history = useHistory();

    return (
        <div className={style.container}>
            <div className={style.reportStepDiv}>
                {/*已签到未签到状态显示隐藏按钮*/}
                {
                    reportStatus && reportStatus =='0' &&
                    <Button theme="primary" className={style.button} onClick={() => history.push("/zaixianbaodaoSignIn")}>立即去报到</Button>
                }
                {
                    reportStatus && reportStatus =='1' &&
                    <Button theme="primary" className={style.alreadyButton}>已报到</Button>
                }
            </div>
        </div>
    );
}
