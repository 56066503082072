import React from "react";
import style from "./index.module.scss";
import {useHistory} from "react-router-dom";
import Storage from "../../../../Utils/Storage";

interface Props {
    onClose:() => void;
}

export default function Toast({onClose}:Props) {

    const history = useHistory();

    function clickClose () {
        onClose?.();
    }

    return (
        <div className={style.container}>
            <div className={style.toastDiv}>
                <div className={style.clostIcon} onClick={() => clickClose()}></div>
                <div className={style.toastContent}>
                    <div className={style.toastButton} onClick={() => {history.push("/admissionLetter");Storage.set("firstClick","false");}}></div>
                </div>
            </div>
        </div>
    );
}
