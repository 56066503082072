import React, {ChangeEvent, useEffect, useState} from "react";
import style from "./index.module.scss";
import {Button, Loading, Toast} from "zarm";
import UploadImgOne from "../../../Components/UploadImgOne";
import useRequestFn from "../../../Utils/useRequestFn";
import {getEnrollmentStatus, getMyPic, updateStudentPic, uploadToImg} from "../../../Service/Enrollment";
import {errorMessage} from "../../../Utils/utils";
import useRequest from "../../../Utils/useRequest";
import {getHomeStudentInfo} from "../../../Service/Home";
import {useTitle} from "react-use";

export default function PhotoCollection() {

    useTitle("入学登记-照片采集", {restoreOnUnmount: true});
    const [imgUrl,setImgUrl] = useState("");//组件传回头像路径
    const [uploadImg] = useRequestFn((pic:string) => updateStudentPic(pic));
    const [getImg] = useRequestFn((file: File,type:string) => uploadToImg(file,type));

    //照片状态：picFlag图片是否上传(0.否,1.是)，picStatusFlag图片是否通过审核(0.审核中,1.已通过2.未通过)
    const [enrollData, {}, reloadEnrollData] = useRequest(() => getEnrollmentStatus());
    //个人信息：有头像取头像
    const [infoData, {loading}] = useRequest(() => getMyPic());

    const [defaultImg,setDefaultImg] = useState("");//已上传默认头像

    useEffect(() => {
        if(infoData && infoData.pic) {
            setDefaultImg(infoData.pic);
        }
    },[infoData])

    useEffect(() => {
        if(enrollData?.picFlag == '' || enrollData?.picFlag == '0') {
            setDefaultImg("");
        }
    },[enrollData])

    useEffect(() => {
        if(loading ) {
            Loading.show();
        }
        if(!loading ) {
            Loading.hide();
        }
    }, [loading]);


    async function clickUpload () {
        if(imgUrl) {
            try{
                const res = await uploadImg(imgUrl);
                if(enrollData?.picFlag == '' || enrollData?.picFlag == '0') {
                    Toast.show("上传成功！");
                }else {
                    Toast.show("更换成功！");
                }
                setDefaultImg(imgUrl);
                await reloadEnrollData();
                setImgUrl("");
            }catch (e) {
                Toast.show(errorMessage(e));
            }
        }else {
            Toast.show("请选择照片上传");
        }

    }



    async function upload(e:ChangeEvent<HTMLInputElement>) {
        if(e.target.files!.length != 0) {//判断点击取消时不走底下逻辑

            const img = e.target.files![0];
            try{
                e.target.value = "";//解决不可重复上传问题
                const res = await getImg(img,"1");
                if(res.message){
                    setImgUrl(res.message);
                }
            }catch (e) {
                Toast.show(errorMessage(e));
            }
        } else {
            e.target.value = "";
        }
    }

    return (
        <div className={style.root}>
            <div className={style.photoCollectionDiv}>
            {
                enrollData?.picFlag == '0' &&
                <div className={style.tip}>* &nbsp;您尚未上传您的个人头像照片，请上传</div>
            }
            {
                enrollData?.picFlag == '1' && enrollData?.picStatusFlag == '0' &&
                <div className={style.tip}>* &nbsp;照片已上传，请等待审核</div>
            }
            {
                enrollData?.picStatusFlag == '1' &&
                <div className={style.greenTip}>照片审核已通过</div>
            }
            {
                enrollData?.picStatusFlag == '2' &&
                <div className={style.tip}>* &nbsp;照片审核未通过，请重新上传</div>
            }
                <div className={`${style.photoContent} ${enrollData?.picStatusFlag == '1'?style.paddingTop:""}`}>
                    {/*完成不显示*/}
                    {(enrollData?.picStatusFlag == "" || enrollData?.picStatusFlag == "0") &&
                        <>
                            <div className={style.photoTip}>
                                <div className={style.tipFont}>照片要求：正面蓝底免冠证件照；像素为480*640；大小为100-200kb；格式为jpg；不戴眼镜与美瞳、不修图。</div>
                            </div>
                        </>
                    }
                    {enrollData?.picStatusFlag == "2" &&
                    <>
                        <div className={style.photoTip}>
                            <div className={style.tipFont}>{enrollData?.picReason}</div>
                        </div>
                    </>
                    }
                    <div className={style.imgDiv}>
                        {enrollData?.picStatusFlag == '2' && !imgUrl &&
                            <img className={style.failShadow} src={require("./img/fail.png").default}/>
                        }
                        {enrollData?.picStatusFlag == '1' &&
                            <div className={style.passDiv}>
                                <img className={style.failPass} src={require("./img/pass.png").default}/>
                                <img className={style.passImg} src={infoData?.pic}/>
                            </div>

                        }
                        {/*上传头像start:通过了不显示*/}
                        {enrollData?.picStatusFlag != '1' && !loading &&
                            <div className={style.uploadImg}>
                                {
                                    defaultImg && !imgUrl &&
                                    <img src={defaultImg}/>
                                }
                                {
                                    imgUrl &&
                                    <img src={imgUrl}/>
                                }
                            </div>
                        }
                        {/*上传头像end*/}
                        {imgUrl &&
                            <img className={style.closeIcon} onClick={() => setImgUrl("")} src={require("./img/closeIcon.png").default}/>
                        }

                    </div>
                    {enrollData?.picStatusFlag != '1' &&
                        <>
                            {(enrollData?.picFlag == '' || enrollData?.picFlag == '0') && !imgUrl &&
                            <div className={style.uploadButton}>
                                <Button theme="primary" className={style.button}>上传</Button>
                                <input  type="file" accept="image/jpeg" onChange={(event) => upload(event)}/>
                            </div>
                            }
                            {(enrollData?.picFlag == '' || enrollData?.picFlag == '0') && imgUrl &&
                            <div className={style.uploadButton}>
                                <Button theme="primary" className={style.button} onClick={() => clickUpload()}>确认上传</Button>
                            </div>
                            }
                            {enrollData?.picFlag == '1' && enrollData?.picStatusFlag != '1' && !imgUrl &&
                            <div className={style.uploadButton}>
                                <Button theme="primary" className={style.button}>更换</Button>
                                <input  type="file" accept="image/jpeg" onChange={(event) => upload(event)}/>
                            </div>
                            }
                            {enrollData?.picFlag == '1' && enrollData?.picStatusFlag != '1' && imgUrl &&
                            <div className={style.uploadButton}>
                                <Button theme="primary" className={style.button} onClick={() => clickUpload()}>确认更换</Button>
                            </div>
                            }
                        </>
                    }
                    {(enrollData?.picStatusFlag == "" || enrollData?.picStatusFlag == "0") &&
                        <div className={style.uploadTip}>
                            温馨提示：请上传本人近期照片，如照片审核未通过，需要重新上传
                        </div>
                    }
                    {enrollData?.picStatusFlag == '2' &&
                    <div className={style.uploadTip}>
                        照片要求：正面蓝底免冠证件照；像素为480*640；大小为100-200kb；格式为jpg；不戴眼镜与美瞳、不修图。
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}



