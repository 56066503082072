import React from "react";
import style from "./index.module.scss";
import {Button as BtnZarm} from "zarm";

export default function Button({disabled, children, reRoot, reButton}: {disabled: boolean; children: React.ReactElement | string, reRoot?:string, reButton?:string}) {
    return (
        <div className= {`${style.root} ${reRoot}`}>
            <BtnZarm theme="primary" htmlType="submit" className={`${style.button} ${reButton}`}  disabled={disabled}>{children}</BtnZarm>
        </div>
    );
}
