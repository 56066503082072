import React, {useEffect, useState} from "react";
import Card from "../../../../Components/Card";
import style from "./index.module.scss";
import {Button, Input, Modal, Toast} from "zarm";
import Cell from "../../../../Components/Cell";
import Member from "../../../CheckIn/components/Member";
import { useHistory } from "react-router-dom";
import useRequest from "../../../../Utils/useRequest";
import {deleteMember, getFamilyMember} from "../../../../Service/CheckIn";
import {errorMessage} from "../../../../Utils/utils";
import useLoading from "../../../../Utils/useLoading";
import {Controller, useForm} from "react-hook-form";
import Select from "../../../../Components/Select";
import {addPoorFamilyEco, getPoor, ViewPoor} from "../../../../Service/GreenChannel";
import BtnSubmit from "../../../CheckIn/components/Button";
import FormPopup from "../../../../Components/Popup";
import Empty from "../../../../Components/Empty";

const jinjikunnanGuerData = [
    {label: '非孤儿', value: '非孤儿'},
    {label: '孤儿', value: '孤儿'},
    {label: '事实孤儿', value: '事实孤儿'},
];

const jinjikunnanDanqinData = [
    {label: '非单亲', value: '非单亲'},
    {label: '父母一方去世，扶养方大部分或完全丧失劳动力', value: '父母一方去世，扶养方大部分或完全丧失劳动力'},
    {label: '父母一方去世，扶养方无固定经济收入或固定经济收入少', value: '父母一方去世，扶养方无固定经济收入或固定经济收入少'},
    {label: '父母离异，扶养方经济收入低，另一方未支付抚养费或支付抚养费用少', value: '父母离异，扶养方经济收入低，另一方未支付抚养费或支付抚养费用少'},
    {label: '父母离异，扶养方经济收入一般，另一方未支付抚养费或支付抚养费用少', value: '父母离异，扶养方经济收入一般，另一方未支付抚养费或支付抚养费用少'},
];

const jinjikunnanCanjiData = [
    {label: '非残疾', value: '非残疾'},
    {label: '本人残疾，有残疾证', value: '本人残疾，有残疾证'},
    {label: '家人残疾，有残疾证，固定经济收入少', value: '家人残疾，有残疾证，固定经济收入少'},
    {label: '家人残疾，有残疾证，无工作能力', value: '家人残疾，有残疾证，无工作能力'},
];

const jinjikunnanJibingData = [
    {label: '非疾病', value: '非疾病'},
    {label: '本人患病费用支出较大', value: '本人患病费用支出较大'},
    {label: '家人长期患病，开销较大', value: '家人长期患病，开销较大'},
    {label: '曾患重大疾病，欠债较多', value: '曾患重大疾病，欠债较多'},
]

const peopleNumData = [
    {label: '0位', value: '0'},
    {label: '1位', value: '1'},
    {label: '2位', value: '2'},
    {label: '3位', value: '3'},
    {label: '4位', value: '4'},
    {label: '5位', value: '5'},
    {label: '6位', value: '6'},
    {label: '7位', value: '7'},
    {label: '8位', value: '8'},
    {label: '9位', value: '9'},
    {label: '10位', value: '10'},
]

export default function FamilyInfo({data, onHandleMark ,updateFn}: {data: ViewPoor; onHandleMark: (id: number) => void,updateFn:() => void;}) {
    const history = useHistory();
    const [memberData, {loading: memberDataLoading}, getFamilyMemberRefresh] = useRequest(() => getFamilyMember());
    const {register, handleSubmit, watch, formState: { errors }, control, setValue, getValues } = useForm();
    const [message, setMessage] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [visible, setVisible] = useState(false); // 提交表单提示
    const [formInfo, setFormInfo] = useState<{
        title: string;
        notice: string;
        type: 'success' | 'notice';
        callBack?: () => void;
    }>({
        title: '',
        notice: '',
        type: 'success'
    });
    watch("jiatingzicanYishangdouwu")
    useLoading([memberDataLoading], OnInit);

    useEffect(() => {
        if(message) {
            Modal.alert({
                title: `${message}！`,
                onCancel: () => setMessage('')
            });
        }
    }, [message]);

    function onDel(id: number, name: string) {
        Modal.confirm({
            content: `确定删除“${name}”？`,
            onOk: async () => {
                try{
                    const res = await deleteMember(id);
                    Toast.show({ content: "删除成功！"});
                    getFamilyMemberRefresh();
                }catch (e) {
                    Toast.show({ content: errorMessage(e)});
                }
            }
        });
    }

    function OnInit() {
        if(data) {
            setValue("jinjikunnanGuer", (data.jinjikunnanGuer && [data.jinjikunnanGuer]) || [jinjikunnanGuerData[0].value]); // 经济困难孤儿
            setValue("jinjikunnanDanqin", (data.jinjikunnanDanqin && [data.jinjikunnanDanqin]) || [jinjikunnanDanqinData[0].value]); // 经济困难单亲
            setValue("jinjikunnanCanji", (data.jinjikunnanCanji && [data.jinjikunnanCanji]) || [jinjikunnanCanjiData[0].value]); // 经济困难残疾
            setValue("jinjikunnanJibing", (data.jinjikunnanJibing && [data.jinjikunnanJibing]) || [jinjikunnanJibingData[0].value]); // 经济困难疾病
            setValue("jiatingfudanDuliwulaoren", [data.jiatingfudanDuliwulaoren] || [peopleNumData[0].value]); // 家庭需独立赡养无收入老人（位）
            setValue("jiatingfudanLianhewulaoren", [data.jiatingfudanLianhewulaoren] || [peopleNumData[0].value]); // 家庭需联合赡养无收入老人（位）
            setValue("jiatingfudanYougongzuobutiezinv", [data.jiatingfudanYougongzuobutiezinv] || [peopleNumData[0].value]); // 除本人外，家中另有已具备工作能力且可以较大程度补贴家用的子女（位）
            setValue("jiatingfudanYougongzuorengxubutiezinv", [data.jiatingfudanYougongzuorengxubutiezinv] || [peopleNumData[0].value]); // 除本人外，家中另有已具备工作能力但仍需家里较大程度补贴的子女（位）
            setValue("jiatingfudanYouzaidugaozhong", [data.jiatingfudanYouzaidugaozhong] || [peopleNumData[0].value]); // 除本人外，家中另有在校高中（含）以上就读学生
            setValue("jiatingfudanYouzaiduyiwujiaoyu", [data.jiatingfudanYouzaiduyiwujiaoyu] || [peopleNumData[0].value]); // 除本人外，家中另有义务阶段、中职学校或幼儿园就读学生（名）
            setValue("jiatingfudanOther", data.jiatingfudanOther || ''); // 家庭负担其他情况
            setValue("jiatingzicanYishangdouwu", parseInt(data.jiatingzicanYishangdouwu) === 0 ? 0 : 1); // 家庭资产以上都无
            setValue("jiatingzicanChengshifangchan", parseInt(data.jiatingzicanChengshifangchan) || 0); // 家庭资产城市房产
            setValue("jiatingzicanFangcantaoshu", data.jiatingzicanFangcantaoshu || ''); // 城市有（ ）套房
            setValue("jiatingzicanFangcanjiazhi", data.jiatingzicanFangcanjiazhi || ''); // 城市房产，共价值（ ）万元
            setValue("jiatingzicanNongcunfangchan", parseInt(data.jiatingzicanNongcunfangchan) || 0); // 家庭资产农村房产
            setValue("jiatingzicanNongcuntaoshu", data.jiatingzicanNongcuntaoshu || ''); // 农村自建房，房产共有（ ）处
            setValue("jiatingzicanNongcunjiazhi", data.jiatingzicanNongcunjiazhi || ''); // 农村自建房，共价值（ ）万元
            setValue("jiatingzicanNongcunjiutaoshu", data.jiatingzicanNongcunjiutaoshu || ''); // 旧村改造房，房产共（ ）处
            setValue("jiatingzicanNongcunjiujiazhi", data.jiatingzicanNongcunjiujiazhi || ''); // 农村-旧村改造房，价值（ ）万元
            setValue("jiatingzicanCheliang", parseInt(data.jiatingzicanCheliang) || 0); // 车辆
            setValue("jiatingzicanSijiacheshuliang", data.jiatingzicanSijiacheshuliang || ''); // 车辆-私家车共（ ）辆
            setValue("jiatingzicanSijiachejiazhi", data.jiatingzicanSijiachejiazhi || ''); // 车辆-私家车共价值（ ）万元
            setValue("jiatingzicanOther", parseInt(data.jiatingzicanOther) || 0); // 其它固定资产
            setValue("jiatingzicanOtherInfo", data.jiatingzicanOtherInfo || ''); //  其它固定资产
            setValue("jiatingrenjunnianshouru", data.jiatingrenjunnianshouru || ''); // 家庭人均年收入（ ）元
            setValue("jiatingzhaoyuzaihaiqingkuang", data.jiatingzhaoyuzaihaiqingkuang || ''); // 家庭遭受自然灾害情况（ ）元
            setValue("jiatingzhaoyutufayiwaishijian", data.jiatingzhaoyutufayiwaishijian || ''); // 家庭遭受突发意外事件（ ）元
            setValue("jiatingchengyuanshangshilaodongnengli", data.jiatingchengyuanshangshilaodongnengli || ''); // 家庭成员因残疾、年迈而丧失劳动能力情况（ ）元
            setValue("jiatingjianzaiqingkuang", data.jiatingjianzaiqingkuang || ''); // 家庭欠债情况

            if(
                !(
                    parseInt(data.jiatingzicanChengshifangchan) ||
                    parseInt(data.jiatingzicanNongcunfangchan) ||
                    parseInt(data.jiatingzicanCheliang) ||
                    parseInt(data.jiatingzicanOther)
                )
            ) setValue('jiatingzicanChengshifangchan', 1);
        }
    }

    async function onSubmit(data: any) {

        if(memberData) {//判断家庭成员信息不完善不让下一步
            for(let i=0;i<memberData.length;i++) {
                if(memberData[i].perfect == "0") {
                    setMessage("请完善家庭成员情况");
                    return ;
                }
            }
        }

        // if(memberData && (memberData.filter(item => item.relation== "父子").length>1)) {
        //     setMessage("家庭成员重复，请删除一个父子关系");
        //     return ;
        // }

        if(disabled) return;
        setDisabled(true);
        const params = {
            ...data,
            jinjikunnanGuer: data.jinjikunnanGuer?.[0] || jinjikunnanGuerData[0].value,
            jinjikunnanDanqin: data.jinjikunnanDanqin?.[0] || jinjikunnanDanqinData[0].value,
            jinjikunnanCanji: data.jinjikunnanCanji?.[0] || jinjikunnanCanjiData[0].value,
            jinjikunnanJibing: data.jinjikunnanJibing?.[0] || jinjikunnanJibingData[0].value,
            jiatingfudanDuliwulaoren: data.jiatingfudanDuliwulaoren?.[0] || peopleNumData[0].value,
            jiatingfudanLianhewulaoren: data.jiatingfudanLianhewulaoren?.[0] || peopleNumData[0].value,
            jiatingfudanYougongzuobutiezinv: data.jiatingfudanYougongzuobutiezinv?.[0] || peopleNumData[0].value,
            jiatingfudanYougongzuorengxubutiezinv: data.jiatingfudanYougongzuorengxubutiezinv?.[0] || peopleNumData[0].value,
            jiatingfudanYouzaidugaozhong: data.jiatingfudanYouzaidugaozhong?.[0] || peopleNumData[0].value,
            jiatingfudanYouzaiduyiwujiaoyu: data.jiatingfudanYouzaiduyiwujiaoyu?.[0] || peopleNumData[0].value,
            jiatingfudanOther: data.jiatingfudanOther || '',
            jiatingrenjunnianshouru: data.jiatingrenjunnianshouru || '',
            jiatingzhaoyuzaihaiqingkuang: data.jiatingzhaoyuzaihaiqingkuang || '',
            jiatingzhaoyutufayiwaishijian: data.jiatingzhaoyutufayiwaishijian || '',
            jiatingchengyuanshangshilaodongnengli: data.jiatingchengyuanshangshilaodongnengli || '',
            jiatingjianzaiqingkuang: data.jiatingjianzaiqingkuang || '',
            jiatingzicanChengshifangchan:getValues("jiatingzicanYishangdouwu") === 0?data.jiatingzicanChengshifangchan:0,
            jiatingzicanNongcunfangchan:getValues("jiatingzicanYishangdouwu") === 0?data.jiatingzicanNongcunfangchan:0,
            jiatingzicanCheliang:getValues("jiatingzicanYishangdouwu") === 0?data.jiatingzicanCheliang:0,
            jiatingzicanOther:getValues("jiatingzicanYishangdouwu") === 0?data.jiatingzicanOther:0,

        };

        try{
            const res = await addPoorFamilyEco(params);
            setFormInfo({
                title: '修改成功',
                notice: '您已成功修改个人信息',
                type: 'success',
                // callBack: () => onHandleMark(3)
            });
            updateFn();
            onHandleMark(3);
            setVisible(true);
        }catch (e) {
            setFormInfo({
                title: '修改失败',
                notice: `${errorMessage(e)}`,
                type: 'notice'
            });
            setVisible(true);
        }
    }

    function onError(error: any){
        let checkList = [
            'jinjikunnanGuer',
            'jinjikunnanDanqin',
            'jinjikunnanCanji',
            'jinjikunnanJibing',
            'jiatingfudanDuliwulaoren',
            'jiatingfudanLianhewulaoren',
            'jiatingfudanYougongzuobutiezinv',
            'jiatingfudanYougongzuorengxubutiezinv',
            'jiatingfudanYouzaidugaozhong',
            'jiatingfudanYouzaiduyiwujiaoyu',
            'jiatingrenjunnianshouru',
        ];

        if(watch("jiatingzicanYishangdouwu") === 0) {
            if(watch("jiatingzicanChengshifangchan") === 1) {
                checkList = checkList.concat([
                    'jiatingzicanFangcantaoshu',
                    'jiatingzicanFangcanjiazhi'
                ]);
            }
            if(watch("jiatingzicanNongcunfangchan") === 1) {
                checkList = checkList.concat([
                    'jiatingzicanNongcuntaoshu',
                    'jiatingzicanNongcunjiazhi',
                    'jiatingzicanNongcunjiutaoshu',
                    'jiatingzicanNongcunjiujiazhi',
                ]);
            }
            if(watch("jiatingzicanCheliang") === 1) {
                checkList = checkList.concat([
                    'jiatingzicanSijiacheshuliang',
                    'jiatingzicanSijiachejiazhi',
                ]);
            }
        }

        const keyArr = Object.keys(error);
        for(let i=0; i<checkList.length; i++) {
            const keyName = checkList[i];
            if(keyArr.indexOf(keyName) !== -1) {
                setMessage(error[keyName].message);
                return;
            }
        }
    }

    function checkZiChan(name: string) {
        const tagNameArr = [
            'jiatingzicanChengshifangchan',
            'jiatingzicanNongcunfangchan',
            'jiatingzicanCheliang',
            'jiatingzicanOther'
        ];

        const res = tagNameArr
            .filter(item => item !== name)
            .map(item => getValues(item));
        let sum = res.reduce((prev, next) => (prev + next));
        sum += getValues(name) !== 0 ? 0 : 1;

        if(sum >= 1)  setValue(name, getValues(name) !== 0 ? 0 : 1);
    }

    return (
        <div className={style.root}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Card className={style.card}>
                    <>
                        <div className={`${style.title}`}><span>家庭成员情况</span></div>
                        {memberData && <Member
                            data={memberData.slice(0, 4)}
                            onAdd={() => history.push(`/member/1`)}
                            onDel={(id, name) => onDel(id, name)}
                            showImgHead={false}
                            className={style.memberWrapper}
                            poor={"1"}
                        />}
                    </>
                </Card>
                {/*经济困难家庭详细情况*/}
                <Card className={style.rectangleCard}>
                    <>
                        <div className={`${style.title}`}><span>经济困难家庭详细情况</span></div>
                        <div className={style.formContent}>
                            <Controller
                                name="jinjikunnanGuer"
                                control={control}
                                defaultValue={getValues("jinjikunnanGuer")}
                                rules={{ required: "请填写孤儿项" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>孤儿</span>
                                                <div className={style.description}>孤儿可减免学费、住宿费（含事实孤儿）</div>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        leftClassName={style.large}
                                    >
                                        <Select
                                            visible={true}
                                            dataSource={jinjikunnanGuerData}
                                            defaultValue={getValues("jinjikunnanGuer")}
                                            placeholder="请选择"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("jinjikunnanGuer", selected.map(item => item.value));
                                            }}
                                        />
                                    </Cell>
                                )}
                            />
                            <Controller
                                name="jinjikunnanDanqin"
                                control={control}
                                defaultValue={getValues("jinjikunnanDanqin")}
                                rules={{ required: "请填写单亲项" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>单亲</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        leftClassName={style.large}
                                    >
                                        <Select
                                            visible={true}
                                            dataSource={jinjikunnanDanqinData}
                                            defaultValue={getValues("jinjikunnanDanqin")}
                                            placeholder="请选择"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("jinjikunnanDanqin", selected.map(item => item.value));
                                            }}
                                        />
                                    </Cell>
                                )}
                            />
                            <Controller
                                name="jinjikunnanCanji"
                                control={control}
                                defaultValue={getValues("jinjikunnanCanji")}
                                rules={{ required: "请填写残疾项" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>残疾</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        leftClassName={style.large}
                                    >
                                        <Select
                                            visible={true}
                                            dataSource={jinjikunnanCanjiData}
                                            defaultValue={getValues("jinjikunnanCanji")}
                                            placeholder="请选择"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("jinjikunnanCanji", selected.map(item => item.value));
                                            }}
                                        />
                                    </Cell>
                                )}
                            />
                            <Controller
                                name="jinjikunnanJibing"
                                control={control}
                                defaultValue={getValues("jinjikunnanJibing")}
                                rules={{ required: "请填写疾病项" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>疾病</span>
                                            </div>
                                        }
                                        className={`${style.cell} ${style.refCell}`}
                                        leftClassName={style.large}
                                    >
                                        <Select
                                            visible={true}
                                            dataSource={jinjikunnanJibingData}
                                            defaultValue={getValues("jinjikunnanJibing")}
                                            placeholder="请选择"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("jinjikunnanJibing", selected.map(item => item.value));
                                            }}
                                        />
                                    </Cell>
                                )}
                            />
                        </div>
                    </>
                </Card>
                {/*家庭负担情况*/}
                <Card className={style.rectangleCard}>
                    <>
                        <div className={`${style.title}`}><span>家庭负担情况</span></div>
                        <div className={style.formContent}>
                            <Controller
                                name="jiatingfudanDuliwulaoren"
                                control={control}
                                defaultValue={getValues("jiatingfudanDuliwulaoren")}
                                rules={{ required: "请填写家庭需独立赡养无收入老人项" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={`${style.required} ${style.fontStyle}`}>家庭需独立赡养无收入老人</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        leftClassName={style.large}
                                    >
                                        <Select
                                            visible={true}
                                            dataSource={peopleNumData}
                                            defaultValue={getValues("jiatingfudanDuliwulaoren")}
                                            placeholder="请选择"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("jiatingfudanDuliwulaoren", selected.map(item => item.value));
                                            }}
                                        />
                                    </Cell>
                                )}
                            />
                            <Controller
                                name="jiatingfudanLianhewulaoren"
                                control={control}
                                defaultValue={getValues("jiatingfudanLianhewulaoren")}
                                rules={{ required: "请填写家庭需联合赡养无收入老人项" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={`${style.required} ${style.fontStyle}`}>家庭需联合赡养无收入老人</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        leftClassName={style.large}
                                    >
                                        <Select
                                            visible={true}
                                            dataSource={peopleNumData}
                                            defaultValue={getValues("jiatingfudanLianhewulaoren")}
                                            placeholder="请选择"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("jiatingfudanLianhewulaoren", selected.map(item => item.value));
                                            }}
                                        />
                                    </Cell>
                                )}
                            />
                            <Controller
                                name="jiatingfudanYougongzuobutiezinv"
                                control={control}
                                defaultValue={getValues("jiatingfudanYougongzuobutiezinv")}
                                rules={{ required: "请填写除本人外，家中另有已具备工作能力且可以较大程度补贴家用的子女项" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={`${style.required} ${style.fontStyle}`}>除本人外，家中另有已具备工作能力且可以较大程度补贴家用的子女</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        rightClassName={style.right}
                                    >
                                        <Select
                                            visible={true}
                                            dataSource={peopleNumData}
                                            defaultValue={getValues("jiatingfudanYougongzuobutiezinv")}
                                            placeholder="请选择"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("jiatingfudanYougongzuobutiezinv", selected.map(item => item.value));
                                            }}
                                        />
                                    </Cell>
                                )}
                            />
                            <Controller
                                name="jiatingfudanYougongzuorengxubutiezinv"
                                control={control}
                                defaultValue={getValues("jiatingfudanYougongzuorengxubutiezinv")}
                                rules={{ required: "请填写除本人外，家中另有已具备工作能力但仍需家里较大程度补贴的子女项" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={`${style.required} ${style.fontStyle}`}>除本人外，家中另有已具备工作能力但仍需家里较大程度补贴的子女</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        rightClassName={style.right}
                                    >
                                        <Select
                                            visible={true}
                                            dataSource={peopleNumData}
                                            defaultValue={getValues("jiatingfudanYougongzuorengxubutiezinv")}
                                            placeholder="请选择"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("jiatingfudanYougongzuorengxubutiezinv", selected.map(item => item.value));
                                            }}
                                        />
                                    </Cell>
                                )}
                            />
                            <Controller
                                name="jiatingfudanYouzaidugaozhong"
                                control={control}
                                defaultValue={getValues("jiatingfudanYouzaidugaozhong")}
                                rules={{ required: "请填写除本人外，家中另有在校高中（含）以上就读学生项" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={`${style.required} ${style.fontStyle}`}>除本人外，家中另有在校高中（含）以上就读学生</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        rightClassName={style.right}
                                    >
                                        <Select
                                            visible={true}
                                            dataSource={peopleNumData}
                                            defaultValue={getValues("jiatingfudanYouzaidugaozhong")}
                                            placeholder="请选择"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("jiatingfudanYouzaidugaozhong", selected.map(item => item.value));
                                            }}
                                        />
                                    </Cell>
                                )}
                            />
                            <Controller
                                name="jiatingfudanYouzaiduyiwujiaoyu"
                                control={control}
                                defaultValue={getValues("jiatingfudanYouzaiduyiwujiaoyu")}
                                rules={{ required: "请填写除本人外，家中另有义务阶段、中职学校或幼儿园就读学生（名）项" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={`${style.required} ${style.fontStyle}`}>除本人外，家中另有义务阶段、中职学校或幼儿园就读学生（名）</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        rightClassName={style.right}
                                    >
                                        <Select
                                            visible={true}
                                            dataSource={peopleNumData}
                                            defaultValue={getValues("jiatingfudanYouzaiduyiwujiaoyu")}
                                            placeholder="请选择"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("jiatingfudanYouzaiduyiwujiaoyu", selected.map(item => item.value));
                                            }}
                                        />
                                    </Cell>
                                )}
                            />
                            <Controller
                                name="jiatingfudanOther"
                                control={control}
                                defaultValue={getValues("jiatingfudanOther")}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={`${style.fontStyle}`}>其他情况</span>
                                            </div>
                                        }
                                        className={`${style.cell} ${style.textArea} `}
                                        leftClassName={style.large}
                                        rightClassName={style.right}
                                    >
                                        <Input className={style.input} {...field} rows={2} placeholder="请输入家庭其他情况" maxLength={50}/>
                                    </Cell>
                                )}
                            />
                        </div>
                    </>
                </Card>
                {/*家庭固定资产情况*/}
                <Card className={style.rectangleCard}>
                    <>
                        <div className={`${style.title}`}><span>家庭固定资产情况</span></div>
                        <div className={style.tags}>
                            <div
                                className={`${style.tag} ${watch("jiatingzicanYishangdouwu") === 0 ? style.current : ''}`}
                                onClick={
                                    () => {
                                        setValue('jiatingzicanYishangdouwu', 0);
                                        // setValue('jiatingzicanChengshifangchan', 1);
                                    }
                                }
                            >
                                有固定资产
                            </div>
                            <div
                                className={`${style.tag} ${watch("jiatingzicanYishangdouwu") === 1 ? style.current : ''}`}
                                onClick={() => setValue('jiatingzicanYishangdouwu', 1)}
                            >
                                无固定资产
                            </div>
                        </div>
                        <div className={style.subHeading}>
                            <span>以下信息可多选</span>
                        </div>
                        {watch("jiatingzicanYishangdouwu") !== 1 && (
                            <>
                                <div className={style.subTags}>
                                    <div
                                        className={`${style.subTag} ${watch("jiatingzicanChengshifangchan") === 1 ? style.selected : ''}`}
                                        onClick={() => checkZiChan("jiatingzicanChengshifangchan")}
                                    >
                                        城市房产
                                    </div>
                                    <div
                                        className={`${style.subTag} ${watch("jiatingzicanNongcunfangchan") === 1 ? style.selected : ''}`}
                                        onClick={() => checkZiChan("jiatingzicanNongcunfangchan")}
                                    >
                                        农村房产
                                    </div>
                                    <div
                                        className={`${style.subTag} ${watch("jiatingzicanCheliang") === 1 ? style.selected : ''}`}
                                        onClick={() => checkZiChan("jiatingzicanCheliang")}
                                    >
                                        车辆
                                    </div>
                                    <div
                                        className={`${style.subTag} ${watch("jiatingzicanOther") === 1 ? style.selected : ''}`}
                                        onClick={() => checkZiChan("jiatingzicanOther")}
                                    >
                                        其他固定资产
                                    </div>
                                </div>
                                <div className={style.cardAssetWrapper}>
                                    <Card className={style.cardAsset}>
                                        <>
                                            {
                                                watch("jiatingzicanChengshifangchan") !== 1 &&
                                                watch("jiatingzicanNongcunfangchan") !== 1 &&
                                                watch("jiatingzicanCheliang") !== 1 &&
                                                watch("jiatingzicanOther") !== 1 &&
                                                <Empty />
                                            }
                                            <div className={style.formContent}>
                                                {/*城市房产*/}
                                                {watch("jiatingzicanChengshifangchan") === 1 && (
                                                    <>
                                                        <Controller
                                                            name="jiatingzicanFangcantaoshu"
                                                            control={control}
                                                            defaultValue={getValues("jiatingzicanFangcantaoshu")}
                                                            rules={{ required: "请填写城市有（ ）套房" }}
                                                            render={({ field }) => (
                                                                <Cell
                                                                    title={
                                                                        <div className={style.name}>
                                                                            <span className={`${style.required} ${style.fontStyle}`}>城市有（ ）套房</span>
                                                                        </div>
                                                                    }
                                                                    className={`${style.cell}`}
                                                                    leftClassName={style.large}
                                                                >
                                                                    <Input className={style.input} {...field} placeholder="请输入" maxLength={10} type={"number"}
                                                                           onChange={
                                                                               (e: string|undefined) => {
                                                                                   if(e && e.length < 5) {
                                                                                       setValue('jiatingzicanFangcantaoshu', e)
                                                                                   }else if(e?.length === 0){
                                                                                       setValue('jiatingzicanFangcantaoshu', '')
                                                                                   }else {
                                                                                       return
                                                                                   }
                                                                               }
                                                                           }
                                                                    />
                                                                </Cell>
                                                            )}
                                                        />
                                                        <Controller
                                                            name="jiatingzicanFangcanjiazhi"
                                                            control={control}
                                                            defaultValue={getValues("jiatingzicanFangcanjiazhi")}
                                                            rules={{ required: "请填写房产-城市房产，共价值（ ）万元" }}
                                                            render={({ field }) => (
                                                                <Cell
                                                                    title={
                                                                        <div className={style.name}>
                                                                            <span className={`${style.required} ${style.fontStyle}`}>房产-城市房产，共价值（ ）万元</span>
                                                                        </div>
                                                                    }
                                                                    className={`${style.cell}`}
                                                                    leftClassName={style.largeName}
                                                                >
                                                                    <Input className={style.input} {...field} placeholder="请输入" maxLength={10} type={"number"}
                                                                           onChange={
                                                                               (e: string|undefined) => {
                                                                                   if(e && e.length < 5) {
                                                                                       setValue('jiatingzicanFangcanjiazhi', e)
                                                                                   }else if(e?.length === 0){
                                                                                       setValue('jiatingzicanFangcanjiazhi', '')
                                                                                   }else {
                                                                                       return
                                                                                   }
                                                                               }
                                                                           }
                                                                    />
                                                                </Cell>
                                                            )}
                                                        />
                                                    </>
                                                )}

                                                {watch("jiatingzicanNongcunfangchan") === 1 && (
                                                    <>
                                                        <Controller
                                                            name="jiatingzicanNongcuntaoshu"
                                                            control={control}
                                                            defaultValue={getValues("jiatingzicanNongcuntaoshu")}
                                                            rules={{ required: "请填写房产-农村自建房，房产共有（ ）处" }}
                                                            render={({ field }) => (
                                                                <Cell
                                                                    title={
                                                                        <div className={style.name}>
                                                                            <span className={`${style.required} ${style.fontStyle}`}>房产-农村自建房，房产共有（ ）处</span>
                                                                        </div>
                                                                    }
                                                                    className={`${style.cell}`}
                                                                    leftClassName={style.largeName}
                                                                >
                                                                    <Input className={style.input} {...field} placeholder="请输入" maxLength={4} type={"number"}
                                                                           onChange={
                                                                               (e: string|undefined) => {
                                                                                   if(e && e.length < 5) {
                                                                                       setValue('jiatingzicanNongcuntaoshu', e)
                                                                                   }else if(e?.length === 0){
                                                                                       setValue('jiatingzicanNongcuntaoshu', '')
                                                                                   }else {
                                                                                       return
                                                                                   }
                                                                               }
                                                                           }
                                                                    />
                                                                </Cell>
                                                            )}
                                                        />
                                                        <Controller
                                                            name="jiatingzicanNongcunjiazhi"
                                                            control={control}
                                                            defaultValue={getValues("jiatingzicanNongcunjiazhi")}
                                                            rules={{ required: "请填写房产-农村自建房，共价值（ ）万元" }}
                                                            render={({ field }) => (
                                                                <Cell
                                                                    title={
                                                                        <div className={style.name}>
                                                                            <span className={`${style.required} ${style.fontStyle}`}>房产-农村自建房，共价值（ ）万元</span>
                                                                        </div>
                                                                    }
                                                                    className={`${style.cell}`}
                                                                    leftClassName={style.largeName}
                                                                >
                                                                    <Input className={style.input} {...field} placeholder="请输入" maxLength={4} type={"number"}
                                                                           onChange={
                                                                               (e: string|undefined) => {
                                                                                   if(e && e.length < 5) {
                                                                                       setValue('jiatingzicanNongcunjiazhi', e)
                                                                                   }else if(e?.length === 0){
                                                                                       setValue('jiatingzicanNongcunjiazhi', '')
                                                                                   }else {
                                                                                       return
                                                                                   }
                                                                               }
                                                                           }
                                                                    />
                                                                </Cell>
                                                            )}
                                                        />
                                                        <Controller
                                                            name="jiatingzicanNongcunjiutaoshu"
                                                            control={control}
                                                            defaultValue={getValues("jiatingzicanNongcunjiutaoshu")}
                                                            rules={{ required: "请填写农村-旧村改造房，房产共（ ）处" }}
                                                            render={({ field }) => (
                                                                <Cell
                                                                    title={
                                                                        <div className={style.name}>
                                                                            <span className={`${style.required} ${style.fontStyle}`}>农村-旧村改造房，房产共（ ）处</span>
                                                                        </div>
                                                                    }
                                                                    className={`${style.cell}`}
                                                                    leftClassName={style.largeName}
                                                                >
                                                                    <Input className={style.input} {...field} placeholder="请输入" maxLength={4} type={"number"}
                                                                           onChange={
                                                                               (e: string|undefined) => {
                                                                                   if(e && e.length < 5) {
                                                                                       setValue('jiatingzicanNongcunjiutaoshu', e)
                                                                                   }else if(e?.length === 0){
                                                                                       setValue('jiatingzicanNongcunjiutaoshu', '')
                                                                                   }else {
                                                                                       return
                                                                                   }
                                                                               }
                                                                           }/>
                                                                </Cell>
                                                            )}
                                                        />
                                                        <Controller
                                                            name="jiatingzicanNongcunjiujiazhi"
                                                            control={control}
                                                            defaultValue={getValues("jiatingzicanNongcunjiujiazhi")}
                                                            rules={{ required: "请填写农村-旧村改造房，价值（ ）万元" }}
                                                            render={({ field }) => (
                                                                <Cell
                                                                    title={
                                                                        <div className={style.name}>
                                                                            <span className={`${style.required} ${style.fontStyle}`}>农村-旧村改造房，价值（ ）万元</span>
                                                                        </div>
                                                                    }
                                                                    className={`${style.cell}`}
                                                                    leftClassName={style.largeName}
                                                                >
                                                                    <Input className={style.input} {...field} placeholder="请输入" maxLength={4} type={"number"}
                                                                           onChange={
                                                                               (e: string|undefined) => {
                                                                                   if(e && e.length < 5) {
                                                                                       setValue('jiatingzicanNongcunjiujiazhi', e)
                                                                                   }else if(e?.length === 0){
                                                                                       setValue('jiatingzicanNongcunjiujiazhi', '')
                                                                                   }else {
                                                                                       return
                                                                                   }
                                                                               }
                                                                           }
                                                                    />
                                                                </Cell>
                                                            )}
                                                        />
                                                    </>
                                                )}

                                                {watch("jiatingzicanCheliang") === 1 && (
                                                    <>
                                                        <Controller
                                                            name="jiatingzicanSijiacheshuliang"
                                                            control={control}
                                                            defaultValue={getValues("jiatingzicanSijiacheshuliang")}
                                                            rules={{ required: "请填写车辆-私家车共（ ）辆" }}
                                                            render={({ field }) => (
                                                                <Cell
                                                                    title={
                                                                        <div className={style.name}>
                                                                            <span className={`${style.required} ${style.fontStyle}`}>车辆-私家车共（ ）辆</span>
                                                                        </div>
                                                                    }
                                                                    className={`${style.cell}`}
                                                                    leftClassName={style.largeName}
                                                                >
                                                                    <Input className={style.input} {...field} placeholder="请输入" maxLength={4} type={"number"}
                                                                           onChange={
                                                                               (e: string|undefined) => {
                                                                                   if(e && e.length < 5) {
                                                                                       setValue('jiatingzicanSijiacheshuliang', e)
                                                                                   }else if(e?.length === 0){
                                                                                       setValue('jiatingzicanSijiacheshuliang', '')
                                                                                   }else {
                                                                                       return
                                                                                   }
                                                                               }
                                                                           }
                                                                    />
                                                                </Cell>
                                                            )}
                                                        />
                                                        <Controller
                                                            name="jiatingzicanSijiachejiazhi"
                                                            control={control}
                                                            defaultValue={getValues("jiatingzicanSijiachejiazhi")}
                                                            rules={{ required: "请填写车辆-私家车共价值（ ）万元" }}
                                                            render={({ field }) => (
                                                                <Cell
                                                                    title={
                                                                        <div className={style.name}>
                                                                            <span className={`${style.required} ${style.fontStyle}`}>车辆-私家车共价值（ ）万元</span>
                                                                        </div>
                                                                    }
                                                                    className={`${style.cell}`}
                                                                    leftClassName={style.largeName}
                                                                >
                                                                    <Input className={style.input} {...field} placeholder="请输入" maxLength={4} type={"number"}
                                                                           onChange={
                                                                               (e: string|undefined) => {
                                                                                   if(e && e.length < 5) {
                                                                                       setValue('jiatingzicanSijiachejiazhi', e)
                                                                                   }else if(e?.length === 0){
                                                                                       setValue('jiatingzicanSijiachejiazhi', '')
                                                                                   }else {
                                                                                       return
                                                                                   }
                                                                               }
                                                                           }
                                                                    />
                                                                </Cell>
                                                            )}
                                                        />
                                                    </>
                                                )}

                                                {watch("jiatingzicanOther") === 1 && (
                                                    <>
                                                        <Controller
                                                            name="jiatingzicanOtherInfo"
                                                            control={control}
                                                            defaultValue={getValues("jiatingzicanOtherInfo")}
                                                            render={({ field }) => (
                                                                <Cell
                                                                    title={
                                                                        <div className={style.name}>
                                                                            <span>其它固定资产</span>
                                                                        </div>
                                                                    }
                                                                    className={`${style.cell} ${style.textArea} `}
                                                                    leftClassName={style.large}
                                                                    rightClassName={style.right}
                                                                >
                                                                    <Input className={style.input} {...field} rows={2} placeholder="请输入其它固定资产" maxLength={50}/>
                                                                </Cell>
                                                            )}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    </Card>
                                </div>
                            </>
                        )}
                    </>
                </Card>
                {/*其他信息*/}
                <Card className={style.rectangleCard}>
                    <>
                        <div className={`${style.title}`}><span>其他信息</span></div>
                        <div className={style.formContent}>
                            <Controller
                                name="jiatingrenjunnianshouru"
                                control={control}
                                defaultValue={getValues("jiatingrenjunnianshouru")}
                                rules={{ required: "请填写家庭人均年收入（ ）元" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>家庭人均年收入（ ）元</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                    >
                                        <Input
                                            type={"number"}
                                            {...field}
                                            placeholder="请输入"
                                            onChange={
                                                (e: string|undefined) => {
                                                    console.log(e?.length)
                                                    if(e && e.length < 11) {
                                                      setValue('jiatingrenjunnianshouru', e)
                                                    }else if(e?.length === 0){
                                                        setValue('jiatingrenjunnianshouru', '')
                                                    }else {
                                                        return
                                                    }
                                                }
                                            }
                                        />
                                    </Cell>
                                )}
                            />
                            <Controller
                                name="jiatingzhaoyuzaihaiqingkuang"
                                control={control}
                                defaultValue={getValues("jiatingzhaoyuzaihaiqingkuang")}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span>家庭遭受自然灾害情况</span>
                                            </div>
                                        }
                                        className={`${style.cell} ${style.textArea}`}
                                        leftClassName={style.large}
                                        rightClassName={style.right}
                                    >
                                        <Input className={style.input} {...field} rows={2} placeholder="请输入家庭遭受自然灾害情况" maxLength={50}/>
                                    </Cell>
                                )}
                            />
                            <Controller
                                name="jiatingzhaoyutufayiwaishijian"
                                control={control}
                                defaultValue={getValues("jiatingzhaoyutufayiwaishijian")}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span>家庭遭受突发意外事件</span>
                                            </div>
                                        }
                                        className={`${style.cell} ${style.textArea}`}
                                        leftClassName={style.large}
                                        rightClassName={style.right}
                                    >
                                        <Input className={style.input} {...field} rows={2} placeholder="请输入家庭遭受突发意外事件" maxLength={50}/>
                                    </Cell>
                                )}
                            />
                            <Controller
                                name="jiatingchengyuanshangshilaodongnengli"
                                control={control}
                                defaultValue={getValues("jiatingchengyuanshangshilaodongnengli")}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span>家庭成员因残疾、年迈而丧失劳动能力情况</span>
                                            </div>
                                        }
                                        className={`${style.cell} ${style.textArea}`}
                                        leftClassName={style.large}
                                        rightClassName={style.right}
                                    >
                                        <Input className={style.input} {...field} rows={2} placeholder="请输入家庭成员因残疾、年迈而丧失劳动能力情况" maxLength={50}/>
                                    </Cell>
                                )}
                            />
                            <Controller
                                name="jiatingjianzaiqingkuang"
                                control={control}
                                defaultValue={getValues("jiatingjianzaiqingkuang")}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span>家庭欠债情况</span>
                                            </div>
                                        }
                                        className={`${style.cell} ${style.textArea} `}
                                        leftClassName={style.large}
                                        rightClassName={style.right}
                                    >
                                        <Input className={style.input} {...field} rows={2} placeholder="请输入家庭欠债情况" maxLength={50}/>
                                    </Cell>
                                )}
                            />
                        </div>
                    </>
                </Card>
                <div className={style.buttonDiv}>
                    <Button theme="primary" className={style.button} onClick={() => onHandleMark(1)}>上一步</Button>
                    <BtnSubmit reButton={style.reButton} reRoot={style.reRoot} disabled={disabled}>下一步</BtnSubmit>
                </div>

            </form>

            <FormPopup
                visible={visible}
                title={formInfo.title}
                notice={formInfo.notice}
                type={formInfo.type}
                onClose={() => {
                    setVisible(false);
                    setDisabled(false);
                    formInfo.callBack?.();
                }}
            />
        </div>

    );
}
