import Request from "Utils/Requests";
import qs from "qs";
import _ from "underscore";

/**
 * 绿色通道获取大礼包
 */
export function getLoveGift() {
    return Request.get(`/zhxg/getLoveGift`)
}

/**
 * 家庭经济困难个人经济情况填写
 */
interface PoorPersonEco {
    gerenjingjiYuetigongshouru: string; //个人经济情况家庭月提供费用
    gerenjingjiYuepingjunzhichu: string;  //个人经济情况学生月平均支出
    gerenjingjiGerenshouji: string;    //电子设备，个人手机（元）
    gerenjingjiGerendiannao: string;    //电子设备，个人电脑（元）
    gerenjingjiGerenotherdianzi: string;   //电子设备，其他
    gerenjingjiGerenotherdajian: string;    //其他大件
}

export function addPoorPersonEco(data: PoorPersonEco) {
    return Request.post(`/zhxg/addPoorPersonEco`, qs.stringify({...data}))
}

export interface SupportList {
    id:string;//资助id
    huoqushijian:string;//获助时间
    zizhumingcheng:string;//资助名称
    zijinlaiyuan:string;//资金来源（政府/学校/社会)
    zizhujine:string;//资助金额（元/年）
    zizhuqixian:string;//资助期限（年）
    updateFlag:string;//修改标识（0.不修改，1.要修改）
    deleteFlag:string;//删除标识（0.不删除，1.要删除）
    uniqueId: string;
}

interface FamilyList {
    income: string;  //年收入(元)
    nation: string;  //民族
    phone: string;  //联系电话
    healthy: string;  //健康状况
    sex: string;   //性别（1.男，2.女）
    name: string;   //姓名
    birth: string;   //出生日期
    company: string;  //工作单位
    id: string;    //成员id
    politicalFace: string  //政治面貌
    relation: string;        //与本人关系

}


export interface ViewPoor {
    gerenjingjiGerendiannao: string;
    gerenjingjiGerenotherdajian:  string;
    gerenjingjiGerenotherdianzi:  string;
    gerenjingjiGerenshouji:  string;
    gerenjingjiYuetigongshouru:  string;
    gerenjingjiYuepingjunzhichu : string;
    gucanxuesheng:  string;
    gucanxueshengZhengming: string;//孤残学生证明材料（如符合但无证书，请手写材料签字拍照上传）
    jiandangpingkunshengZhengming:  string;//建档立卡贫困家庭学生证明材料（如符合但无证书，请手写材料签字拍照上传）
    zuidishenghuobaozhengZhengming: string;//最低生活保障家庭学生证明材料（如符合但无证书，请手写材料签字拍照上传）
    jiandangpingkunsheng:  string;
    jiatingchengyuanshangshilaodongnengli:  string;
    jiatingfudanDuliwulaoren:  string;
    jiatingfudanLianhewulaoren:  string;
    jiatingfudanOther:  string;
    jiatingfudanYougongzuobutiezinv:  string;
    jiatingfudanYougongzuorengxubutiezinv:  string;
    jiatingfudanYouzaidugaozhong:  string;
    jiatingfudanYouzaiduyiwujiaoyu:  string;
    jiatingjianzaiqingkuang:  string;
    jiatingrenjunnianshouru:  string;
    jiatingrenkou:  string;
    jiatingzhaoyutufayiwaishijian:  string;
    jiatingzhaoyuzaihaiqingkuang:  string;
    jiatingzicanCheliang:  string;
    jiatingzicanChengshifangchan:  string;
    jiatingzicanFangcanjiazhi:  string;
    jiatingzicanFangcantaoshu:  string;
    jiatingzicanNongcunfangchan:  string;
    jiatingzicanNongcunjiazhi:  string;
    jiatingzicanNongcunjiujiazhi:  string;
    jiatingzicanNongcunjiutaoshu:  string;
    jiatingzicanNongcuntaoshu:  string;
    jiatingzicanOther:  string;
    jiatingzicanOtherInfo:  string;
    jiatingzicanSijiachejiazhi:  string;
    jiatingzicanSijiacheshuliang:  string;
    jiatingzicanYishangdouwu:  string;
    jinjikunnanCanji:  string;
    jinjikunnanDanqin:  string;
    jinjikunnanGuer:  string;
    jinjikunnanJibing:  string;
    lieshizinv:  string;
    liesizinvZhengming:  string;//烈士子女学生证明材料（如符合但无证书，请手写材料签字拍照上传）
    name:  string;
    zuidishenghuobaozhang: string;
    tekunsheng:string;
    canjirenzinv:string;
    flag:string;//是否填报过困难申请（0否1是）
    address: string;
    supportList: SupportList[];
    loanFlag: string; //是否申请助学贷款(0.未申请，1.已申请)
    tekungongyangxueshengZhengming: string;   //特困供养学生证明材料
    caijixueshengZhengming: string;   //家庭经济困难残疾学生及残疾人子女学生证明材料
    familyList: FamilyList[];
    urgentPhone: string;
    urgentRelation: string;
    urgentConcat: string;
}

/**
 *
 */
export function viewPoor() {
    return Request.get<SuccessData<ViewPoor>>('/zhxg/viewPoor')
        .then(data => data.data.data)
        .then(data => {

            if(!_.isEmpty(data)) {
                return {
                    ...data,
                    supportList: data.supportList.map(item => ({
                        ...item,
                        uniqueId: _.uniqueId(`${_.now()}_`)
                    }))
                }
            }
            return  data;
        });
}

/**
 * 家庭经济困难基本情况填写
 */
export interface IaddPoor {
    jiatingrenkou: string;//家庭人口
    jiandangpingkunsheng: string;//建档立卡贫困家庭学生
    zuidishenghuobaozhang: string;//最低生活保障家庭学生
    tekunsheng: string;//特困供养学生
    gucanxuesheng: string;//孤残学生
    lieshizinv: string;//烈士子女
    canjirenzinv: string;//家庭经济困难残疾学生及残疾人子女
}

export function addPoorBasic(params: IaddPoor) {
    return Request.post<SuccessData<IaddPoor>>(`/zhxg/addPoorBasic`, qs.stringify({...params}))
        .then(res => res.data.data);
}

/**
 * 家庭经济困难"证明材料"填写
 */
export interface Evidence {
    jiandangpingkunshengZhengming:string;
    zuidishenghuobaozhengZhengming:string;
    tekungongyangxueshengZhengming:string;
    gucanxueshengZhengming:string;
    liesizinvZhengming:string;
    caijixueshengZhengming:string;
}

export function addPoorEvidence (params:Evidence) {
    return Request.post<SuccessData<IaddPoor>>(`/zhxg/addPoorEvidence`, qs.stringify({...params}))
        .then(res => res.data.data);
}


/**
 * 家庭经济困难已受资助填写
 */

export interface Support {
    poorSupport:SupportList[];//资助list
}

export function addPoorSupport (params:Support) {
    return Request.post<SuccessData<IaddPoor>>(`/zhxg/addPoorSupport`, qs.stringify({support: JSON.stringify(params)}))
        .then(res => res.data.data);
}



type FamilyEco = Omit<ViewPoor, ''>;

/**
 * 家庭经济困难家庭经济情况填写
 * @param params
 */

export function addPoorFamilyEco(params: any) {
    return Request.post(`/zhxg/addPoorFamilyEco`, qs.stringify({...params}))
        .then(data => data.data.data);
}

export interface Poor{
    createTime: string;
    firstFlag: string;
    firstGrade: string;
    firstReason: string;
    firstTime: string;
    name: string;
    secondFlag: string;
    secondGrade: string;
    secondReason: string;
    secondTime: string;
}

/**
 * 家庭经济困难申请记录
 */
export function getPoor() {
    return Request.get<SuccessData<Poor>>(`/zhxg/getPoor`)
        .then(data => data.data.data);
}


/**
 *查看是否填写过家庭经济困难
 */
export function getIsPoor() {
    return Request.get<SuccessData<ViewPoor>>('/zhxg/getIsPoor')
        .then(res => res.data.data)
}


/**
 * 家庭经济困难等待修改完成提交
 */

export function updatePoorFlag() {
    return Request.get(`/zhxg/updatePoorFlag`)
}



/**
 * 查看家庭经济困难各种填写flag查看
 */
export interface ISubjectFlag {
    familyFlag:string;//家庭信息是否填写（0.否，1.是）
    moneyFlag:string;//个人经济情况是否填写（0.否，1.是）
    infoFlag:string;//基本信息是否填写（0.否，1.是）
    submitFlag:string;//是否点击提交按钮（0.否，1是）
}


export function getPoorFlag() {
    return Request.get<SuccessData<ISubjectFlag>>(`/zhxg/getPoorFlag`)
    .then(res => res.data.data)
}
