import React from "react";
import style from "./index.module.scss";
import toastTop from "./img/toastTop.png"
import leftArr from "./img/leftArr.png"

interface IProps {
    title?: string;
    children?: React.ReactElement;
}

export default function BottomPop({title, children}: IProps) {

    return (
        <div className={style.container}>
            <div className={style.bottomPopDiv}>
                <div className={style.bottomContent}>
                    <div className={style.title}>
                        {title}
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
}
