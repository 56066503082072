import Request from "Utils/Requests";

const CmsUrl = process.env.REACT_APP_CMS_URL;

/**
 * 公告列表
 */
export interface INotice {
    cid: string;
    title: string;
    addtime: string;
    content: string;
}

export function getNotice() {
    return Request.get<SuccessData<INotice[]>>(`${CmsUrl}/ajl/xsgl/tzgg/1_500.html`)
        .then(res => res.data.data);
}

/**
 * 公告详情
 */
export interface NoticeDetail {
    title: string;
    content: string;
    addtime: string;
}

export function getNoticeDetail(id: string) {
    return Request.get<SuccessData<NoticeDetail>>(`/noticeDetail/ajshow_${id}.html`, {params: {id}})
        .then(res => res.data.data)
}
