import React from "react";
import style from "./index.module.scss";
import {Button as BtnZarm} from "zarm";

export default function ModifyButton({disabled, children}: {disabled: boolean; children: React.ReactElement | string}) {
    return (
        <div className={style.root}>
            <BtnZarm theme="primary" htmlType="submit" className={style.alreadyButton}  disabled={disabled}>{children}</BtnZarm>
        </div>
    );
}
