import Request from "Utils/Requests";
import qs from "qs";

const BaseUrl = process.env.REACT_APP_BASE_URL;

interface onlinePay {
    money: string;      //交费金额
    payItems: string;   //交费项目
    statusFlag: string;  //交费状态
}

/**
 * 在线交费-交费金额
 */
export const getOnlinePay = () => {
    return Request.get<SuccessData<onlinePay[]>>(`${BaseUrl}/zhxg/getOnlinePay`)
        .then(res => res.data.data)
}


/**
 * 孤儿证明-保存孤儿证明图片
 */

interface OrphanPic {
    message:string;//报错信息或者图片信息
}

export const saveOrphanPic = (pic: string) => {
    return Request.post<SuccessData<OrphanPic>>(`${BaseUrl}/zhxg/saveOrphanPic`, qs.stringify({pic}))
}


/**
 * 孤儿证明-删除孤儿证明图片
 */

// export const deleteOrphanPic = () => {
//     return Request.post(`${BaseUrl}/zhxg/deleteOrphanPic`)
// }

/**
 * 保存助学贷款
 */
interface LoanPic {
    message:string;//报错信息或者图片信息
}

export const saveLoanPic = (pic: string, receiptCode:string) => {
    return Request.post<SuccessData<LoanPic>>(`${BaseUrl}/zhxg/saveLoanPic`, qs.stringify({pic, receiptCode}))
}


/**
 * 助学贷款删除
 */

// export const deleteLoanPic = () => {
//     return Request.post(`${BaseUrl}/zhxg/deleteLoanPic`)
// }
