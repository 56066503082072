import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import style from "./index.module.scss";
import deleteImg from "../../../../../Payment/TuitionWaiver/img/deleteImg.png";
import {Toast} from "zarm";
import {errorMessage} from "../../../../../../Utils/utils";
import {getQiniuToken, uploadToQiNiu} from "../../../../../../Service/Enrollment";
import useRequestFn from "../../../../../../Utils/useRequestFn";
interface IProps{
    defaultImg:string[];//初始图片
    onUpload:(value:string[]) => void;
}

export default function UploadMore({defaultImg,onUpload}: IProps){

    const [imgList, setImgList] = useState<string[]>(defaultImg);
    const [qiNiuImg] = useRequestFn((file: File,token:string) => uploadToQiNiu(file,token));

    //前端删除照片-不掉删除接口
    const deletePic = (index:number) => {
        const str = imgList;
        imgList.splice(index,1)
        setImgList([...str])
        onUpload?.(imgList);
    }

    //上传，修改
    async function upload(e:ChangeEvent<HTMLInputElement>, index:number) {
        if(e.target.files!.length !== 0) {
            const image = e.target.files![0];


            try {
                e.target.value = "";//解决无法重复上传问题
                let data = await getQiniuToken();
                let token = data.token;
                let domain = data.domain;
                const res = await qiNiuImg(image, token);

                if(index === -1){
                    setImgList([...imgList, `${domain}/${res.key}`])
                    onUpload?.([...imgList, `${domain}/${res.key}`]);//图片信息回调
                } else {
                    const change = imgList.map((item, i) => {
                        if(index === i) return `${domain}/${res.key}`;
                        return item;
                    });
                    setImgList(change);
                    onUpload?.(change);//图片信息回调
                }


            } catch (e) {
                Toast.show(errorMessage(e));
            }
        }
    }

    return (
        <div className={style.content}>
            {
                imgList.map ((item, index) =>
                    <div className={style.message} key={index}>
                        <div className={style.materialPic}>
                            <img src={imgList[index]} className={style.showImg}/>
                            {<img src={deleteImg} className={style.deleteImg} onClick={()=>deletePic(index)}/>}
                            {<input type="file" name="file" accept="image/*" onChange={(event) => upload(event,index)}/>}
                        </div>
                    </div>
                )
            }

            {
                imgList.length < 3 &&
                <div className={style.message}>
                    <div className={style.materialPic}>
                        <input type="file" name="file" accept="image/*" onChange={(event) => upload(event,-1)}/>
                    </div>
                </div>
            }
        </div>
    );
}
