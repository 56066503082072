import React from "react";
import style from "./index.module.scss";
import {useHistory} from "react-router-dom";
import dayjs from "dayjs";


interface headProps {
    pic?:string;//头像
    name:string;//姓名
    college?:string;//学院
    profession?:string;//专业
    classStatus?:string;//班级状态
    roomStatus?:string;//寝室状态
    reportStatus?:string;//报到状态
    eduStatus?:string;  //学历
    reportTime?:string;//报到时间
    employeeNo?:string;//学号
}

export default function HeadInfo({pic, name, college, profession, classStatus, roomStatus, reportStatus, eduStatus, reportTime, employeeNo}:headProps) {

    const history = useHistory();


    return (
        <div className={style.container}>
            <div className={style.headInfo}>
                <div className={style.headImage}>
                    {
                        dayjs(reportTime).valueOf() <= dayjs().valueOf() &&
                        <div className={style.signInDiv}>
                            {
                                reportStatus && reportStatus =="0" &&
                                <img src={require("./img/unSignIn.png").default}/>
                            }
                            {
                                reportStatus && reportStatus =="1" &&
                                <img src={require("./img/signIn.png").default}/>
                            }
                        </div>
                    }
                    <img src={pic}/>
                </div>
                <div className={style.headContent}>
                    <div className={`${style.headName} ${name.length>9?style.longName:""}`}><span>{name}</span><span className={style.number}>{employeeNo}</span></div>
                    <div className={style.headFont}>学院：{college}</div>
                    <div className={style.headFont}>专业：{profession}</div>
                    <div className={style.headButtonDiv}>
                        {(eduStatus && eduStatus == "1" || eduStatus && eduStatus == "5") &&
                            <div className={style.headButton} onClick={() => history.push("/admissionLetter")}>录取通知书</div>
                        }

                        <div className={style.headButton} onClick={() => history.push("/myclass")}>
                            {
                                classStatus && classStatus=="0" &&
                                <div className={style.headButtonNoBefore}><span>未分</span></div>
                            }
                            {
                                classStatus && classStatus=="1" &&
                                <div className={style.headButtonYesBefore}><span>已分</span></div>
                            }

                            我的班级
                        </div>
                        <div className={style.headButton} onClick={() => history.push("/dormitory")}>
                            {
                                classStatus && roomStatus=="0" &&
                                <div className={style.headButtonNoBefore}><span>未分</span></div>
                            }
                            {
                                classStatus && roomStatus=="1" &&
                                <div className={style.headButtonYesBefore}><span>已分</span></div>
                            }
                            我的宿舍
                        </div>
                    </div>
                </div>
                {/*签到状态*/}
                {/*{*/}
                {/*    dayjs(reportTime).valueOf() <= dayjs().valueOf() &&*/}
                {/*    <div className={style.signInDiv}>*/}
                {/*        {*/}
                {/*            reportStatus && reportStatus =="0" &&*/}
                {/*            <img src={require("./img/unSignIn.png").default}/>*/}
                {/*        }*/}
                {/*        {*/}
                {/*            reportStatus && reportStatus =="1" &&*/}
                {/*            <img src={require("./img/signIn.png").default}/>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
        </div>
    );
}
