import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import {Button} from "zarm";
import useRequest from "../../../Utils/useRequest";
import {getEnrollmentStatus} from "../../../Service/Enrollment";
import {useHistory} from "react-router-dom";
import {useTitle} from "react-use";
import {getHomeStudentInfo} from "../../../Service/Home";

export default function Enrollment() {

    useTitle("入学登记", {restoreOnUnmount: true});
    const history = useHistory();
    const [infoData] = useRequest(() => getHomeStudentInfo());
    const [enrollData,{loading}] = useRequest(() => getEnrollmentStatus());


    return (
        <div className={style.root}>
            <div className={style.enrollmentDiv}>
                <div className={style.tip}>* &nbsp;报到之前需完成信息登记，以免影响入学报到</div>
                <div className={style.enrollRow}>
                    <div className={`${style.topType} ${enrollData?.infoFlag == '1'?style.topTypeAlready:""}`}>{enrollData?.infoFlag == '1'?"已填报":"未填报"}</div>
                    <div className={style.font}>个人基础信息</div>
                    <div className={style.enFont}>Personal information</div>
                    <div className={style.buttonDiv}>
                        {enrollData?.infoFlag == '1' &&
                            <Button theme="primary" className={style.alreadyButton} onClick={() => history.push('/register')}>去修改</Button>
                        }
                        {enrollData?.infoFlag == '0' &&
                            <Button theme="primary" className={style.button} onClick={() => history.push('/register')}>去填报</Button>
                        }
                    </div>
                </div>
                <div className={style.enrollRow}>
                    <div className={`${style.topType} ${enrollData?.familyFlag == '1'?style.topTypeAlready:""}`}>{enrollData?.familyFlag == '1'?"已填报":"未填报"}</div>
                    <div className={style.font}>家庭信息</div>
                    <div className={style.enFont}>Family information</div>
                    <div className={style.buttonDiv}>
                        {enrollData?.familyFlag == "1" &&
                            <Button theme="primary" className={style.alreadyButton} onClick={() => history.push('/family')}>去修改</Button>
                        }
                        {enrollData?.familyFlag == "0" &&
                            <Button theme="primary" className={style.button} onClick={() => history.push('/family')}>去填报</Button>
                        }

                    </div>
                </div>
                {(infoData?.education == '1' || infoData?.education == '5') &&
                    <div className={style.enrollRow}>
                        <div className={`${style.topType} ${enrollData?.picFlag == '1'?style.topTypeAlready:""}`}>{enrollData?.picFlag == '1'?"已上传":"未上传"}</div>
                        <div className={style.font}>照片采集</div>
                        <div className={style.enFont}>Photo information</div>
                        <div className={style.buttonDiv}>
                            {enrollData?.picStatusFlag == '1' &&
                            <Button theme="primary" className={style.alreadyButton} onClick={() => history.push("/photoCollection")}>已完成</Button>
                            }
                            {enrollData?.picFlag == '1' && enrollData?.picStatusFlag == '0' &&
                            <Button theme="primary" className={style.alreadyButton} onClick={() => history.push("/photoCollection")}>审核中</Button>
                            }
                            {enrollData?.picStatusFlag == '2' &&
                            <Button theme="primary" className={style.alreadyButton} onClick={() => history.push("/photoCollection")}>未通过</Button>
                            }
                            {enrollData?.picFlag == '0' &&
                            <Button theme="primary" className={style.button} onClick={() => history.push("/photoCollection")}>去填报</Button>
                            }
                        </div>
                    </div>
                }

            </div>
        </div>
    );
}
