import React from "react";
import style from "./index.module.scss";
import img from "./img/nodata.png";
interface IProps{
    msg?: string;
    className?: string;
    fontClassName?: string;
    imgUrl?:string;
}

export default function ({msg, className, fontClassName, imgUrl}: IProps){
    return (
        <div className={`${style.root} ${className}`}>
            <img className={style.img} src={imgUrl?imgUrl:img} alt=""/>
            {msg && <div className={`${style.msg} ${fontClassName}`}>{msg}</div>}
        </div>
    );
}
