import React from "react";
import {useHistory} from "react-router-dom";
import style from "./index.module.scss";
import DormitoryItem from "./components/DormitoryItem";
import Empty from "../../Components/Empty";
import {Button} from "zarm";
import noMessage from "./img/noMessage.png"
import useRequest from "../../Utils/useRequest";
import {getMyRoom} from "../../Service/Dormitory";
import {getHomeStudentInfo} from "../../Service/Home";
import {useTitle} from "react-use";

export default function Dormitory() {

    useTitle("我的宿舍", {restoreOnUnmount: true});
    const [stuInfo] = useRequest(() => getHomeStudentInfo())
    const [roomData,{loading}] = useRequest(() => getMyRoom())
    const history = useHistory();

    return (
        <div className={style.root}>
            <div className={style.empty}>
                {roomData?.roomNum === undefined  && !loading &&(
                        <div className={style.container}>
                            <div className={`${style.nameAndNo}`}>姓名：{stuInfo?.name}</div>
                            <div className={`${style.nameAndNo} ${style.stuNo}`}>学号：{stuInfo?.employeeNo}</div>
                            <Empty msg={"尚未完成宿舍分配，请稍后查看"}/>
                            <Button theme="primary" className={style.button} onClick={() => history.goBack()}>返回</Button>
                        </div>
                    )
                }
            </div>
            {roomData?.roomNum !== undefined && !loading &&
                <div className={style.live}>
                    <DormitoryItem data={roomData} name={stuInfo?.name} stuId={stuInfo?.employeeNo}/>
                    <img src={noMessage} alt="" className={style.img}/>
                </div>
            }
        </div>
    );
}
