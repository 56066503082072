import React from "react";
import style from "./index.module.scss";
import arrowRight from "./img/arrowRight.png";
import noticeIcon from "./img/noticeIcon.png";
import {useHistory} from "react-router-dom";
import {INotice} from "../../../../Service/Home";
import dayjs from "dayjs";

interface props{
    dataList:INotice[];
}

export default function Notice({dataList}:props) {

    const history = useHistory();

    return (
        <div className={style.container}>
            <div className={style.noticeDiv}>
                <div className={style.headerDiv}>
                    <div className={style.headerTitle}>
                        <img src={noticeIcon}/>
                        <div className={style.headFont}>通知公告</div>
                        <div className={style.row}></div>
                    </div>
                    <div className={style.headerMore} onClick={() => history.push('/Notice')}>
                        <span>更多</span>
                        <img src={arrowRight}/>
                    </div>
                </div>
                {dataList.length > 0 &&
                    <div className={style.noticeList}>
                        {
                            dataList.map((item,index) => {
                                return <div key={index} className={style.noticeRow} onClick={() => history.push(`/notice/detail/${item.cid}`)}>
                                    <span>{dayjs(parseInt(item.addtime)*1000).format("MM-DD")}</span>&nbsp;&nbsp;
                                    {item.title}
                                </div>
                            })
                        }
                    </div>
                }
                {
                    dataList.length == 0 &&
                    <div className={style.empty}>
                        <img src={require("./img/empty.png").default}/>
                        暂无通知
                    </div>
                }

            </div>
        </div>
    );
}
