import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import { useForm, Controller } from "react-hook-form";
import Card from "../../../Components/Card";
import Cell from "../../../Components/Cell";
import {Button, Input, Loading, Modal, Toast} from "zarm";
import BtnSubmit from "../components/Button";
import useRequest from "../../../Utils/useRequest";
import {
    deleteMember,
    FamilyInfo,
    getAreaInfo,
    getFamilyBasicInfo,
    getFamilyMember,
    updateFamilyBasicInfo
} from "Service/CheckIn";
import {errorMessage} from "../../../Utils/utils";
import Member from "../components/Member";
import { useHistory } from "react-router-dom";
import {getEnrollmentStatus} from "../../../Service/Enrollment";
import ConfirmToast from "../../../Components/ConfirmToast";
import Select from "../../../Components/Select";
import Popup from "../../../Components/Popup";
import {useTitle} from "react-use";
import _ from "underscore";
import {getHomeStudentInfo} from "../../../Service/Home";

export default function Family() {
    const [message, setMessage] = useState('');
    const [familyFlag, setFamilyFlag] = useState('1'); // 家庭信息是否填写完整状态 '0'不完整'1'完整
    const [visible, setVisible] = useState(false); // 提交表单提示
    const [formInfo, setFormInfo] = useState<{
        title: string;
        notice: string;
        type: 'success' | 'notice'
    }>({
        title: '',
        notice: '',
        type: 'success'
    });
    const [infoData, {loading:infoDataLoading}] = useRequest(() => getHomeStudentInfo());//学生个人信息
    const {handleSubmit, formState: { errors }, control, setValue, getValues } = useForm();
    const [data, {loading}] = useRequest(() => getFamilyBasicInfo());
    const [areaData, {loading: areaDataLoading}] = useRequest(() => getAreaInfo());
    const [memberData, {loading: memberDataLoading}, getFamilyMemberRefresh] = useRequest(() => getFamilyMember());
    const [enrollmentStatus] = useRequest(() => getEnrollmentStatus());
    const [disabled, setDisabled] = useState(false);
    const [dataSource, setDataSource] = useState<{
        value: string;
        label: string;
        children: {
            value: string;
            label: string;
            children?: {
                value: string;
                label: string;
            }
        }[]
    }[]>([]);
    const [jump, setJump] = useState(true);
    const history = useHistory();
    useTitle("入学登记-家庭信息", {restoreOnUnmount: true});
    const [urgentDic, setUrgentDic] = useState<{value:string,label:string}[]>();

    useEffect(() => {//判断登录人是男女，显示与本人关系不同
        if(infoData && infoData.sex == "1") {
            setUrgentDic([{value:"父子",label:"父子"},{value:"母子",label:"母子"},{value:"兄弟",label:"兄弟"},{value:"姐弟",label:"姐弟"},{value:"兄妹",label:"兄妹"},{value:"监护人",label:"监护人"},{value:"其他",label:"其他"}])
        }else {
            setUrgentDic([{value:"父女",label:"父女"},{value:"母女",label:"母女"},{value:"姐弟",label:"姐弟"},{value:"姐妹",label:"姐妹"},{value:"兄妹",label:"兄妹"},{value:"监护人",label:"监护人"},{value:"其他",label:"其他"}])
        }
    },[infoData])

    useEffect(() => {
        if(enrollmentStatus) {
            setFamilyFlag(enrollmentStatus.familyFlag);
        }
    }, [enrollmentStatus]);

    useEffect(() => {
        if(loading || areaDataLoading || memberDataLoading) {
            Loading.show();
        }
        if(!loading && !areaDataLoading && !memberDataLoading) {
            Loading.hide();
        }
    }, [loading, areaDataLoading, memberDataLoading]);

    useEffect(() => {
        if(data) {
            onInit();
        }
    }, [data]);

    useEffect(() => {
        if(areaData) {
            setDataSource(areaData);
        }
    }, [areaData]);

    useEffect(() => {
        if(message) {
            Modal.alert({
                title: `${message}！`,
                onCancel: () => setMessage('')
            });
        }
    }, [message]);

    function onInit() {
        if(data) {
            Object.keys(data).forEach((value, index, array) => {
                if(value === 'address') {
                    const arr = data['address'].split('|');
                    const info = arr.pop();
                    setValue('addressArea', arr);
                    setValue('addressInfo', info);
                } else if(value === 'urgentRelation') {//与本人关系
                    setValue("urgentRelation", !_.isEmpty(data.urgentRelation)?[data.urgentRelation]:[]);
                }else {
                    setValue(value, data[value as keyof FamilyInfo]);
                }
            });
        }
    }

    async function onSubmit(data: any) {

        const phoneReg = /^1[3-9]\d{9}$/;
        if(!phoneReg.test(data.urgentPhone)) {//手机号效验
            setMessage("家庭紧急联系人电话格式不正确请检查");
            return;
        }

        if(disabled) return;
        setDisabled(true);
        const addressArea = data.addressArea.length > 0 ? data.addressArea.join("|") : '';
        const addressInfo = data.addressInfo ? data.addressInfo : '';
        const params = {
            address: `${addressArea}|${addressInfo}`,
            urgentRelation: data.urgentRelation ? data.urgentRelation[0] : "",
            urgentConcat: data.urgentConcat,
            urgentPhone: data.urgentPhone,
        };

        try{
            await updateFamilyBasicInfo(params);
            setFormInfo({
                title: enrollmentStatus?.familyFlag === '1' ? '修改成功' : '提交成功',
                notice: enrollmentStatus?.familyFlag === '1' ? '您已成功修改家庭信息' : '您已成功提交家庭信息',
                type: 'success'
            })
            setVisible(true);

        }catch (e) {
            setFormInfo({
                title: '修改失败',
                notice: `${errorMessage(e)}`,
                type: 'notice'
            })
            setVisible(true);
        }
    }

    async function onSubmitCopy(data: any, fun: any) {

        const phoneReg = /^1[3-9]\d{9}$/;
        if(!phoneReg.test(data.urgentPhone)) {//手机号效验
            setMessage("家庭紧急联系人电话格式不正确请检查");
            return;
        }

        if(disabled) return;
        setDisabled(true);
        const addressArea = data.addressArea.length > 0 ? data.addressArea.join("|") : '';
        const addressInfo = data.addressInfo ? data.addressInfo : '';
        const params = {
            address: `${addressArea}|${addressInfo}`,
            urgentRelation: data.urgentRelation ? data.urgentRelation[0] : "",
            urgentConcat: data.urgentConcat,
            urgentPhone: data.urgentPhone,
        };

        try{
            await updateFamilyBasicInfo(params);
            setFormInfo({
                title: enrollmentStatus?.familyFlag === '1' ? '修改成功' : '提交成功',
                notice: enrollmentStatus?.familyFlag === '1' ? '您已成功修改家庭信息' : '您已成功提交家庭信息',
                type: 'success'
            });
            fun();
        }catch (e) {

        }
    }

    function onError(error: any, func: any){
        const keyArr = Object.keys(error);
        if(keyArr.length > 0) {
            const keyName = keyArr[0];
            const msg = error[keyName].message;
            setMessage(msg);
        }
    }

    function onDel(id: number, name: string) {
        Modal.confirm({
            content: `确定删除“${name}”？`,
            onOk: async () => {
                try{
                    const res = await deleteMember(id);
                    Toast.show({ content: "删除成功！"});
                    getFamilyMemberRefresh();
                }catch (e) {
                    Toast.show({ content: errorMessage(e)});
                }
            }
        });
    }

    async function familyFunc(v: any) {
        handleSubmit((data) => onSubmitCopy(data, v), onError)();
    }

    return (
        <div className={style.root}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Card>
                    <>
                        <Controller
                            name="addressArea"
                            control={control}
                            rules={{ required: "请填写家庭所在地区" }}
                            render={({ field }) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>家庭所在地区</span>
                                        </div>
                                    }
                                    className={style.cell}
                                    leftClassName={style.large}
                                >
                                    <Select
                                        visible={true}
                                        dataSource={dataSource}
                                        defaultValue={getValues("addressArea")}
                                        placeholder="选择地区"
                                        onOk={(selected: {value: string; label: string}[]) => {
                                            setValue("addressArea", selected.map(item => item.value));
                                        }}
                                    />
                                </Cell>
                            )}
                        />


                        <Controller
                            name="addressInfo"
                            control={control}
                            defaultValue={getValues("addressInfo")}
                            rules={{ required: "请填写家庭详细地址" }}
                            render={({ field }) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>详细地址</span>
                                        </div>
                                    }
                                    className={`${style.cell} ${style.textArea}`}
                                    leftClassName={style.large}
                                    rightClassName={style.right}
                                >
                                    <Input className={style.input} {...field} rows={0} autoHeight placeholder="请输入您的家庭地址，例如**小区万达金街" maxLength={50}/>
                                </Cell>
                            )}
                        />
                    </>
                </Card>
                <Card>
                    <>
                        <Controller
                            name="urgentConcat"
                            control={control}
                            defaultValue={getValues("urgentConcat")}
                            rules={{ required: "请填写家庭紧急联系人姓名" }}
                            render={({ field }) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>家庭紧急联系人姓名</span>
                                        </div>
                                    }
                                    className={`${style.cell}`}
                                    leftClassName={style.large}
                                >
                                    <Input className={style.input} {...field} placeholder="请输入姓名" maxLength={20}/>
                                </Cell>
                            )}
                        />
                        <Controller
                            name="urgentPhone"
                            control={control}
                            defaultValue={getValues("urgentPhone")}
                            rules={{ required: "请填写家庭紧急联系人电话" }}
                            render={({ field }) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>家庭紧急联系人电话</span>
                                        </div>
                                    }
                                    className={style.cell}
                                    leftClassName={style.large}
                                >
                                    <Input className={style.input} {...field} placeholder="请输入电话号码" maxLength={11}/>
                                </Cell>
                            )}
                        />
                        <Controller
                            name="urgentRelation"
                            control={control}
                            rules={{ required: "请选择与本人关系" }}
                            render={({ field }) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>与本人关系</span>
                                        </div>
                                    }
                                    className={`${style.cell} ${style.refCell}`}
                                >
                                    <div className={style.row}>
                                        <Select
                                            visible={true}
                                            dataSource={urgentDic}
                                            defaultValue={getValues("urgentRelation")}
                                            placeholder="请选择"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("urgentRelation", selected.map(item => item.value));
                                            }}
                                        />
                                    </div>
                                </Cell>
                            )}
                        />
                    </>
                </Card>
                {memberData && (
                    <Card className={style.member}>
                        <Member
                            data={memberData.slice(0, 4)}
                            onAdd={() => history.push('/member')}
                            onDel={(id, name) => onDel(id, name)}
                            callback={familyFunc}
                        />
                    </Card>
                )}
                <BtnSubmit disabled={disabled}>提交</BtnSubmit>
            </form>
            {familyFlag === "0" && (
                <ConfirmToast
                    title="温馨提示"
                    tip={
                        <div className={style.tip}>还未填写您的家庭信息</div>
                    }
                >
                    <>
                        <div className={style.toastContent}>
                            <div className={style.toastRow}>
                                家庭地址需要精确到所在街道
                            </div>
                        </div>
                        <Button theme="primary" className={style.btn} onClick={() => {setFamilyFlag("1")}}>我知道了</Button>
                    </>
                </ConfirmToast>
            )}
            <Popup
                visible={visible}
                title={formInfo.title}
                notice={formInfo.notice}
                type={formInfo.type}
                onClose={() => {
                    setVisible(false);
                    setDisabled(false);
                    history.push(`/enrollment`)
                }}
            />
        </div>
    );
}
