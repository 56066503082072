import React from "react";
import style from "./index.module.scss";

interface IProps{
    children: React.ReactElement;
    className?: string;
}

export default function Card({children, className}: IProps) {
    return (
        <div className={`${style.root} ${className}`}>
            {children}
        </div>
    )
}
