import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import Cell from "../../../../Components/Cell";
import Select from "../../../../Components/Select";
import {Controller, useForm} from "react-hook-form";
import {Button, Input, Modal, Toast} from "zarm";
import BtnSubmit from "../../../CheckIn/components/Button";
import {errorMessage} from "../../../../Utils/utils";
import {addPoorPersonEco, ViewPoor} from "../../../../Service/GreenChannel";
import useRequestFn from "../../../../Utils/useRequestFn";

interface PersonEconomicProps{
    data: ViewPoor;
    onHandleMark: (id:number)=>void;
    updateFn: () => void;
}

export default function PersonEconomic({data, onHandleMark, updateFn}: PersonEconomicProps) {
    const {handleSubmit, control, setValue, getValues} = useForm();
    const [editPoorPersonEco] = useRequestFn((params) => addPoorPersonEco(params));
    const [disabled, setDisabled] = useState(false);
    const [message, setMessage] = useState('');

    const monthlyFeeDATA = [
        {value: '500元以下', label: '500元以下'},
        {value: '500-800元', label: '500-800元'},
        {value: '800-1000元', label: '800-1000元'},
        {value: '1000-1200元', label: '1000-1200元'},
        {value: '1200元以上', label: '1200元以上'},
    ];

    useEffect(() => {
       onInit();
    }, []);

    function onInit() {
        if(data) {
            if(data.gerenjingjiYuetigongshouru) {
                setValue("gerenjingjiYuetigongshouru", [data.gerenjingjiYuetigongshouru]);
            }
            if(data.gerenjingjiYuepingjunzhichu) {
                setValue("gerenjingjiYuepingjunzhichu", [data.gerenjingjiYuepingjunzhichu]);
            }
            setValue('gerenjingjiGerenshouji', data.gerenjingjiGerenshouji)
            setValue('gerenjingjiGerendiannao', data.gerenjingjiGerendiannao)
            setValue('gerenjingjiGerenotherdianzi', data.gerenjingjiGerenotherdianzi)
            setValue('gerenjingjiGerenotherdajian', data.gerenjingjiGerenotherdajian)
        }
    }

    useEffect(() => {
        if(message) {
            Modal.alert({
                title: `${message}！`,
                onCancel: () => setMessage('')
            });
        }
    }, [message]);


    // 提交相关
    async function onSubmit(data: any) {
        if (disabled) return;
        setDisabled(true);

        const params = {
            ...data,
            gerenjingjiYuetigongshouru: data.gerenjingjiYuetigongshouru[0] || '',
            gerenjingjiYuepingjunzhichu: data.gerenjingjiYuepingjunzhichu[0] || '',
            gerenjingjiGerenotherdianzi: data.gerenjingjiGerenotherdianzi || '',
            gerenjingjiGerenotherdajian: data.gerenjingjiGerenotherdajian || ''
        };

        try {
            await editPoorPersonEco(params);
            setDisabled(false);
            updateFn();
            onHandleMark(4);
        } catch (e) {
            Toast.show({ content: errorMessage(e)});
        }
    }

    function onError(error: any) {
        const checkList = [
            'gerenjingjiYuetigongshouru',
            'gerenjingjiYuepingjunzhichu',
            'gerenjingjiGerenshouji',
            'gerenjingjiGerendiannao',
        ];
        const keyArr = Object.keys(error);
        for (let i = 0; i < checkList.length; i++) {
            const keyName = checkList[i];
            if (keyArr.indexOf(keyName) !== -1) {
                setMessage(error[keyName].message);
                return;
            }
        }
    }

    return (
        <div className={style.root}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className={style.top}>
                    <div className={style.title}>
                        <div className={style.line}/>
                        <div>个人经济情况</div>
                    </div>
                    <div className={style.formContent}>
                        <Controller
                            name="gerenjingjiYuetigongshouru"
                            control={control}
                            defaultValue={getValues("gerenjingjiYuetigongshouru")}
                            rules={{required: "请选择您的家庭月提供费用"}}
                            render={({field}) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>家庭月提供费用</span>
                                        </div>
                                    }
                                    className={`${style.cell}`}
                                    leftClassName={style.large}
                                >
                                    <Select
                                        visible={true}
                                        dataSource={monthlyFeeDATA}
                                        defaultValue={getValues("gerenjingjiYuetigongshouru")}
                                        placeholder="请选择"
                                        onOk={(selected: { value: string, lable: string }[]) => {
                                            setValue("gerenjingjiYuetigongshouru", selected.map(item => item.value));
                                        }}
                                    />
                                </Cell>
                            )}
                        />
                        <Controller
                            name="gerenjingjiYuepingjunzhichu"
                            control={control}
                            defaultValue={getValues("gerenjingjiYuepingjunzhichu")}
                            rules={{required: "请选择您的月平均支出"}}
                            render={({field}) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>学生月平均支出</span>
                                        </div>
                                    }
                                    className={`${style.cell} ${style.refCell}`}
                                    leftClassName={style.large}
                                >
                                    <Select
                                        visible={true}
                                        dataSource={monthlyFeeDATA}
                                        defaultValue={getValues("gerenjingjiYuepingjunzhichu")}
                                        placeholder="请选择"
                                        onOk={(selected: { value: string; label: string }[]) => {
                                            setValue("gerenjingjiYuepingjunzhichu", selected.map(item => item.value));
                                        }}
                                    />
                                </Cell>
                            )}
                        />
                    </div>
                </div>
                <div className={style.divided}/>
                <div className={style.bottom}>
                    <div className={style.title}>
                        <div className={style.line}/>
                        学生大件物品情况
                    </div>
                    <div className={style.formContent}>
                        <Controller
                            name="gerenjingjiGerenshouji"
                            control={control}
                            defaultValue={getValues("gerenjingjiGerenshouji")}
                            rules={{required: "请输入您的电子设备（个人手机）"}}
                            render={({field}) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>电子设备（个人手机）</span>
                                        </div>
                                    }
                                    className={style.cell}
                                    leftClassName={style.large}
                                >
                                    <Input style={{textAlign: "right"}} {...field} placeholder="请输入金额" maxLength={20}/>
                                </Cell>
                            )}
                        />
                        <Controller
                            name="gerenjingjiGerendiannao"
                            control={control}
                            defaultValue={getValues("gerenjingjiGerendiannao")}
                            rules={{required: "请输入您的电子设备（个人电脑）"}}
                            render={({field}) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>电子设备（个人电脑）</span>
                                        </div>
                                    }
                                    className={style.cell}
                                    leftClassName={style.large}
                                >
                                    <Input style={{textAlign: "right"}} {...field} placeholder="请输入金额" maxLength={20}/>
                                </Cell>
                            )}
                        />
                        <Controller
                            name="gerenjingjiGerenotherdianzi"
                            control={control}
                            defaultValue={getValues("gerenjingjiGerenotherdianzi")}
                            render={({field}) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span>电子设备（其他）</span>
                                        </div>
                                    }
                                    className={`${style.cell} ${style.textArea}`}
                                    leftClassName={style.large}
                                    rightClassName={style.right}
                                >
                                    <Input className={style.input} autoHeight rows={1} {...field}
                                           placeholder="请输入购买的其他电子设备及其金额" maxLength={50}/>
                                </Cell>
                            )}
                        />
                        <Controller
                            name="gerenjingjiGerenotherdajian"
                            control={control}
                            defaultValue={getValues("gerenjingjiGerenotherdajian")}
                            render={({field}) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span>其他大件</span>
                                        </div>
                                    }
                                    className={`${style.cell} ${style.textArea} ${style.refCellLength}`}
                                    leftClassName={style.large}
                                    rightClassName={style.right}
                                >
                                    <Input className={style.input} autoHeight rows={1} {...field}
                                           placeholder="请输入购买的其他大件及其金额" maxLength={50}/>
                                </Cell>
                            )}
                        />
                    </div>
                </div>
                <div className={style.buttonDiv}>
                    <Button theme="primary" className={style.button} onClick={() => onHandleMark(2)}>上一步</Button>
                    <BtnSubmit reButton={style.reButton} reRoot={style.reRoot} disabled={disabled}>下一步</BtnSubmit>
                </div>
            </form>
        </div>
    )
}
