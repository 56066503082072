import Request from "Utils/Requests";
import qs from "qs";

const BaseUrl = process.env.REACT_APP_BASE_URL;
const CmsUrl =  process.env.REACT_APP_CMS_URL;

/**
 * 首页个人信息查询/录取通知书信息查询
 */
export interface HomeStudentInfo {
    college:string;     //学院
    registerFlag:string; //是否完成入学登记（0.否，1.是）
    classFlag:string;   //是否已经分班（0.否，1.是）
    roomFlag:string;    //是否已经分完宿舍（0.否，1.是）
    examNum:string;     //考号
    pic:string;         //头像
    employeeNo:string; //学号
    NoticeNo:string;    //通知书编号
    trafficFlag:string; //是否已经填写交通信息（0.否，1.是）
    major:string;       //专业
    name:string;        //姓名
    LaunchDate:string; //投档日期
    payFlag:string;     //是否已经完成缴费（0.否，1.是）
    reportFlag:string; //是否签到(0.否，1.是)
    reportTime:string; //报到时间
    classNo:string;     //班级
    poorFlag:string;    //是否申请了贫困生（0.否，1.是）
    giftFlag:string;    //是否领取了爱心大礼包（0.否，1.是）
    loanPic:string;     //助学贷款图片
    receiptCode:string;  //回执验证码
    education:string;   //学历(1.本科，2.研究生，3博士，4.专升本)
    cardId:string;      //身份证号
    loanFlag:string;    //助学贷款审核状态(0.待审核，1.审核通过，2.审核不通过)
    loanReason:string;  //助学贷款审核原因
    orphanPic:string;   //孤儿证明
    orphanFlag:string;  //孤儿审核状态（0.审核中，1.审核通过，2.审核未通过）
    orphanReason:string;//孤儿审核原因
    delayedFlag:string; //是否申请了延迟报到(0.否，1.是)
    tongzhishufafangriqi:string;//录取通知书发放日期
    baodaoriqi:string;//录取通知书报到日期
    sex:string;//性别
    gaokaoPic:string;//高考头像
    onlineReportFlag:string;//线上报到（0未报到，1已报到）
}

export function getHomeStudentInfo() {
    return Request.get<SuccessData<HomeStudentInfo>>(`${BaseUrl}/zhxg/getHomeStudentInfo`)
        .then(res => res.data.data);
}

/**
 * 查询各个功能有效时间(一趟按钮)
 */
export interface ProjectName {
    date:string;//时间
    indexName:string;//功能名称
    projectName:string;//功能索引
    isUser:string;//是否可以使用（0.否，1.是）
}

export function getIndexProjectName() {
    return Request.get<SuccessData<ProjectName[]>>(`${BaseUrl}/zhxg/getIndexProjectName`)
        .then(res => res.data.data);
}

/**
 * 通知公告
 */
export interface INotice {
    title:string;
    addtime:string;
    cid:string;
}

export function getNoticeList() {
    return Request.get<SuccessData<INotice[]>>(`${CmsUrl}/ajl/xsgl/tzgg/1_3.html`)
        .then(res => res.data.data);
}

export function loginNewTech(code: string) {
    return Request.post<SuccessData<{token: string; refreshToken: string}>>(`/zhxg/sso/loginNew`, qs.stringify({code}))
        .then(data => data.data.data);
}

interface PersonInfo{
    token: string;
    refreshToken: string;
    personType: string;
    userCode: string;
    userName: string;
}

/**
 * 统一身份认证登陆
 * @param ticket
 * @param service
 */
export function loginNew(ticket: string, service: string) {
    return Request.post<SuccessData<PersonInfo>>(`/zhxg/sso/loginNew`, qs.stringify({ticket, service}))
        .then(data => data.data.data);
}


/**
 * 查询是否扫过签到二维码flag
 */
export interface QRFlag {
    signQRFlag:string;//是否扫过二维码（0.否，1.是）
}

export function getSignQRFlag() {
    return Request.get<SuccessData<QRFlag>>(`${BaseUrl}/zhxg/getSignQRFlag`)
        .then(res => res.data.data);
}

/**
 * 获取登陆人员类型
 */
export interface PersonType {
    type:string;//登陆用户类型（1.老师，2.学生）
}

export function getPersonType() {
    return Request.get<SuccessData<PersonType>>(`${BaseUrl}/zhxg/getPersonType`)
        .then(res => res.data.data);
}
