import Request from "Utils/Requests";

/**
 * 我的宿舍
 */

export interface MyRoom {
    badNum:string; //床位号
    roomNum:string; //寝室号
    studentNames:string; //同寝人员名称
    gongyuqu:string; //公寓区
    louming:string; //楼名
    xiaoqu:string; //校区
    studentNum:string; //当前寝室人数
    capacity:string; //寝室容量
}


export function getMyRoom () {
    return Request.get<SuccessData<MyRoom>>(`/zhxg/getMyRoom`)
        .then(res => res.data.data)
}
