import React, {useEffect} from "react";
import useRequest from "../../../Utils/useRequest";
import {getHomeStudentInfo} from "../../../Service/Home";
import CheckSuccess from "./CheckSuccess"
import ElectronicPage from "./ElectronicPage"
import {updateSignQRFlag} from "../../../Service/CheckIn";
import {useTitle} from "react-use";

export default function ZaixianbaodaoSignIn() {

    useTitle("线上报到", {restoreOnUnmount: true});
    const [stuInfo] = useRequest(() => getHomeStudentInfo())


    return (
        <div>
            { stuInfo && stuInfo.onlineReportFlag !== '1' && <ElectronicPage stuInfo={stuInfo}/> }
            { stuInfo && stuInfo.onlineReportFlag === '1' && <CheckSuccess/> }
        </div>

    )
}

