import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import style from "./index.module.scss";
import {SupportList} from "../../../../../../Service/GreenChannel";
import {DateSelect, Input, Modal, Toast} from "zarm";
import Cell from "../../../../../../Components/Cell";
import dayjs from "dayjs";
interface IProps{
    data:SupportList;
    uniqueId: string;
    onChange: (name: string, value: string, uniqueId: string) => void;
}

export default function FormRow({data,uniqueId,onChange}: IProps){
    return (
        <div className={style.formRow}>
                <div className={style.formContent}>
                    <Cell
                        title={
                            <div className={style.name}>
                                <span className={style.required}>获助时间</span>
                            </div>
                        }
                        className={style.cell}>
                        <DateSelect
                            className={style.dateSelect}
                            title="请选择"
                            placeholder="请选择"
                            mode="date"
                            value={data.huoqushijian}
                            onOk={(value: string) => {
                                const dateStr = dayjs(value).format("YYYY-MM-DD");
                                onChange('huoqushijian', dateStr, uniqueId);
                            }}
                        />
                    </Cell>
                    <Cell
                        title={
                            <div className={style.name}>
                                <span className={style.required}>资助名称</span>
                            </div>
                        }
                        className={`${style.cell}`}
                        leftClassName={style.large}
                    >
                        <Input
                            className={style.input}
                            placeholder="请输入"
                            maxLength={20}
                            value={data.zizhumingcheng}
                            onChange={
                                (e: string|undefined) => {
                                    onChange('zizhumingcheng', e || '', uniqueId);
                                }
                            }
                        />
                    </Cell>
                    <Cell
                        title={
                            <div className={style.name}>
                                <span className={style.required}>资金来源(政府/学校/社会)</span>
                            </div>
                        }
                        className={`${style.cell}`}
                        leftClassName={style.large}
                    >
                        <Input
                            className={style.input}
                            placeholder="请输入"
                            maxLength={20}
                            value={data.zijinlaiyuan}
                            onChange={
                                (e: string|undefined) => {
                                    onChange('zijinlaiyuan', e || '', uniqueId);
                                }
                            }
                        />
                    </Cell>
                    <Cell
                        title={
                            <div className={style.name}>
                                <span className={style.required}>资助金额(元/年)</span>
                            </div>
                        }
                        className={`${style.cell}`}
                        leftClassName={style.large}
                    >
                        <Input
                            type={"number"}
                            className={style.input}
                            placeholder="请输入"
                            value={data.zizhujine}
                            maxLength={7}
                            onChange={
                                (e: string|undefined) => {
                                    if(e && e?.length < 8){
                                        onChange('zizhujine', e || '', uniqueId);
                                    }else if(e?.length === 0){
                                        onChange('zizhujine', '', uniqueId);
                                    }else {
                                        return
                                    }
                                }
                            }
                        />
                    </Cell>
                    <Cell
                        title={
                            <div className={style.name}>
                                <span className={style.required}>资助期限(年)</span>
                            </div>
                        }
                        className={`${style.cell}`}
                        leftClassName={style.large}
                    >
                        <Input
                            type={"number"}
                            className={style.input}
                            placeholder="请输入"
                            value={data.zizhuqixian}
                            maxLength={2}
                            onChange={
                                (e: string|undefined) => {
                                    if(e && e.length < 3) {
                                        onChange('zizhuqixian', e || '', uniqueId);
                                    }else if(e?.length === 0){
                                        onChange('zizhuqixian', '', uniqueId);
                                    }else {
                                        return
                                    }
                                }
                            }
                        />
                    </Cell>
                </div>
        </div>
    );
}
