import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {addFamilyMember, DictId, getDict, getMemberInfo, updateMemberInfo} from "../../../../Service/CheckIn";
import useRequestFn from "../../../../Utils/useRequestFn";
import {Controller, useForm} from "react-hook-form";
import Cell from "../../../../Components/Cell";
import {Button, DateSelect, Input, Loading, Modal, Toast} from "zarm";
import Card from "../../../../Components/Card";
import BtnSubmit from "../../components/Button";
import Select from "../../../../Components/Select";
import {errorMessage} from "../../../../Utils/utils";
import nation from "./nation";
import dayjs from "dayjs";
import useRequest from "../../../../Utils/useRequest";
import FormPopup from "../../../../Components/Popup";
import {useTitle} from "react-use";
import {getHomeStudentInfo} from "../../../../Service/Home";
import _ from "underscore";

export default function Detail({match}: RouteComponentProps<{id: string; poor: string; isPreview: string; step:string}>) {

    const id = match.params.id;
    const isPreview = match.params.isPreview;
    const poor = match.params.poor; // 是否显示年收入和健康状况 "1"显示 "0"不显示
    const step = match.params.step;
    const {handleSubmit, formState: { errors }, control, setValue, getValues } = useForm();
    const history = useHistory();
    const [disabled, setDisabled] = useState(false);
    const [message, setMessage] = useState('');
    const sexDataSource = [
        {label: '男', value: '1'},
        {label: '女', value: '2'}
    ];
    const [visible, setVisible] = useState(false); // 提交表单提示
    const [formInfo, setFormInfo] = useState<{
        title: string;
        notice: string;
        type: 'success' | 'notice'
    }>({
        title: '',
        notice: '',
        type: 'success'
    });
    const [infoData, {loading:infoDataLoading}] = useRequest(() => getHomeStudentInfo());//学生个人信息
    const [getMember, {loading}] = useRequestFn((id) => getMemberInfo(id));

    const [politicalFaceDic, {loading: politicalFaceLoading}] = useRequest(() => getDict(DictId.political));
    const [nationDic] = useRequest(() => getDict(DictId.nation));

    const [relationDic, setRelationDic] = useState<{value:string,label:string}[]>();
    useTitle("入学登记-家庭成员信息", {restoreOnUnmount: true});
    const [healthyDic] = useState([{value:"健康或良好",label:"健康或良好"},{value:"一般或较弱",label:"一般或较弱"},{value:"有慢性病",label:"有慢性病"},{value:"残疾",label:"残疾"},])
    const [sexState, setSexState] = useState(false);//为了通过监听性别变换与本人关系下拉列表

    useEffect(() => {
        if(politicalFaceLoading || loading) {
            Loading.show();
        }
        if(!politicalFaceLoading && !loading) {
            Loading.hide();
        }
    }, [politicalFaceLoading, loading]);

    useEffect(() => {
        if(id) {
            onInit();
        }
    }, [id]);

    useEffect(() => {
        if(message) {
            Modal.alert({
                title: `${message}！`,
                onCancel: () => setMessage('')
            });
        }
    }, [message]);

    useEffect(() => {//判断登录人是男女，显示与本人关系不同
        if(getValues("sex") && infoData && infoData.sex == "1") {//本人男
            if(getValues("sex")[0] == "1") {//家属男
                setRelationDic([{value:"父子",label:"父子"},{value:"兄弟",label:"兄弟"},{value:"监护人",label:"监护人"},{value:"其他",label:"其他"}])
            }else {//家属女
                setRelationDic([{value:"母子",label:"母子"},{value:"姐弟",label:"姐弟"},{value:"兄妹",label:"兄妹"},{value:"监护人",label:"监护人"},{value:"其他",label:"其他"}])
            }
        }else if(getValues("sex") && infoData && infoData.sex == "2") {//本人女
            if(getValues("sex")[0] == "1") {//家属男
                setRelationDic([{value:"父女",label:"父女"},{value:"姐弟",label:"姐弟"},{value:"兄妹",label:"兄妹"},{value:"监护人",label:"监护人"},{value:"其他",label:"其他"}])
            }else {//家属女
                setRelationDic([{value:"母女",label:"母女"},{value:"姐妹",label:"姐妹"},{value:"监护人",label:"监护人"},{value:"其他",label:"其他"}])
            }
        }
    },[infoData,sexState])

    async function onInit() {
        try{
            const res = await getMember(id);
            setValue("name", res.name || '');
            setValue("sex", [res.sex] || []);
            setValue("nation", [res.nation] || []);
            setValue("birth", res.birth || '');
            setValue("relation", [res.relation] || []);
            setValue("company", res.company || '');
            setValue("politicalFace", [res.politicalFace] || []);
            setValue("phone", res.phone || '');
            if(poor === '1') {
                setValue("income", res.income || '');
                setValue("healthy", (!_.isEmpty(res.healthy) && [res.healthy] || []));
            }
            setSexState(!sexState);
        }catch (e) {
            Toast.show({ content: errorMessage(e)});
        }
    }

    async function onSubmit(data: any) {

        const phoneReg = /^1[3-9]\d{9}$/;
        if(!phoneReg.test(data.phone)) {//手机号效验
            setMessage("联系电话格式不正确请检查");
            return;
        }

        if(disabled) return;
        setDisabled(true);

        const params = {
            ...(id ? Object.assign(data, {id: id}) : data),
            sex: data.sex[0] || '',
            nation: data.nation[0] || '',
            relation: data.relation[0] || '',
            politicalFace: data.politicalFace[0] || '',
        };

        if(poor === '1') {
            params.healthy = data.healthy[0] || '';
        }

        try{
            if(id){
                await updateMemberInfo(params);
                setFormInfo({
                    title: '修改成功',
                    notice: '您已成功修改家庭成员信息',
                    type: 'success'
                });
            }else{

                await addFamilyMember(params);
                setFormInfo({
                    title: '添加成功',
                    notice: '您已成功添加家庭成员信息',
                    type: 'success'
                })
            }

            setVisible(true);

        }catch (e) {
            setFormInfo({
                title: '修改家庭成员信息失败',
                notice: `${errorMessage(e)}`,
                type: 'notice'
            })
            setVisible(true);
        }
    }

    function onError(error: any){
        let checkList = [
            'name',
            'sex',
            'nation',
            'birth',
            'relation',
            'company',
            'politicalFace',
            'phone',
        ];

        if(poor === '1') {
            checkList = checkList.concat(['income', 'healthy']);
        }

        const keyArr = Object.keys(error);
        for(let i=0; i<checkList.length; i++) {
            const keyName = checkList[i];
            if(keyArr.indexOf(keyName) !== -1) {
                setMessage(error[keyName].message);
                return;
            }
        }
    }


    return (
        <div className={style.root}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Card>
                    <>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue={getValues("name")}
                            rules={{ required: "请填写家庭成员姓名" }}
                            render={({ field }) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>姓名</span>
                                        </div>
                                    }
                                    className={`${style.cell}`}
                                    leftClassName={style.large}
                                >
                                    <Input className={style.input} {...field} placeholder="请填写家庭成员姓名" maxLength={20} disabled={isPreview === "1" ? true : false}/>
                                </Cell>
                            )}
                        />
                        <Controller
                            name="sex"
                            control={control}
                            rules={{ required: "请选择家庭成员性别" }}
                            render={({ field }) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>性别</span>
                                        </div>
                                    }
                                    className={`${style.cell}`}
                                    leftClassName={style.large}
                                >
                                    <Select
                                        visible={true}
                                        dataSource={sexDataSource}
                                        defaultValue={getValues("sex")}
                                        placeholder="请选择家庭成员性别"
                                        onOk={(selected: {value: string; label: string}[]) => {
                                            setValue("sex", selected.map(item => item.value));
                                            setValue("relation",null);
                                            setSexState(!sexState);
                                        }}
                                        disabled={isPreview === "1" ? true : false}
                                    />
                                </Cell>
                            )}
                        />
                        <Controller
                            name="nation"
                            control={control}
                            rules={{ required: "请选择家庭成员的民族" }}
                            render={({ field }) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>民族</span>
                                        </div>
                                    }
                                    className={`${style.cell}`}
                                    leftClassName={style.large}
                                >
                                    <Select
                                        visible={true}
                                        dataSource={nationDic || []}
                                        defaultValue={getValues("nation")}
                                        placeholder="请选择家庭成员的民族"
                                        onOk={(selected: {value: string; label: string}[]) => {
                                            setValue("nation", selected.map(item => item.value));
                                        }}
                                        disabled={isPreview === "1" ? true : false}
                                    />
                                </Cell>
                            )}
                        />
                        <Controller
                            name="birth"
                            control={control}
                            rules={{ required: "请选择您家庭成员的出生日期" }}
                            render={({ field }) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>出生日期</span>
                                        </div>
                                    }
                                    className={`${style.cell}`}
                                    leftClassName={style.large}
                                >
                                    <DateSelect
                                        title="选择日期"
                                        placeholder="请选择您家庭成员的出生日期"
                                        mode="date"
                                        min="1900-01-01"
                                        max={dayjs().format('YYYY-MM-DD')}
                                        value={getValues("birth")}
                                        onOk={(value: string) => {
                                            setValue("birth", dayjs(value).format("YYYY-MM-DD"));
                                        }}
                                        disabled={isPreview === "1" ? true : false}
                                    />
                                </Cell>
                            )}
                        />
                        {/*<Controller*/}
                        {/*    name="relation"*/}
                        {/*    control={control}*/}
                        {/*    defaultValue={getValues("relation")}*/}
                        {/*    rules={{ required: "请输入与学生关系" }}*/}
                        {/*    render={({ field }) => (*/}
                        {/*        <Cell*/}
                        {/*            title={*/}
                        {/*                <div className={style.name}>*/}
                        {/*                    <span className={style.required}>与学生关系</span>*/}
                        {/*                </div>*/}
                        {/*            }*/}
                        {/*            className={`${style.cell}`}*/}
                        {/*            leftClassName={style.large}*/}
                        {/*        >*/}
                        {/*            <Input className={style.input} {...field} placeholder="请输入" maxLength={20} disabled={isPreview === "1" ? true : false}/>*/}
                        {/*        </Cell>*/}
                        {/*    )}*/}
                        {/*/>*/}

                        <Controller
                            name="relation"
                            control={control}
                            rules={{ required: "请选择与本人关系" }}
                            render={({ field }) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>与本人关系</span>
                                        </div>
                                    }
                                    className={`${style.cell} ${style.refCell}`}
                                >
                                    <div className={style.row}>
                                        <Select
                                            visible={true}
                                            dataSource={relationDic}
                                            defaultValue={getValues("relation")}
                                            disabled={isPreview === "1" ? true : false}
                                            placeholder="请选择"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("relation", selected.map(item => item.value));
                                            }}
                                        />
                                    </div>
                                </Cell>
                            )}
                        />

                        <Controller
                            name="company"
                            control={control}
                            defaultValue={getValues("company")}
                            rules={{ required: "请输入家庭成员的工作单位" }}
                            render={({ field }) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>工作（学习）单位</span>
                                        </div>
                                    }
                                    className={`${style.cell} ${style.textArea}`}
                                >
                                    <Input className={style.input} {...field} autoHeight rows={1} placeholder="请输入家庭成员的工作单位" maxLength={50} disabled={isPreview === "1" ? true : false}/>
                                </Cell>
                            )}
                        />
                        {politicalFaceDic && (
                            <Controller
                                name="politicalFace"
                                control={control}
                                defaultValue={getValues("politicalFace")}
                                rules={{ required: "请选择家庭成员的政治面貌" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>政治面貌</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        leftClassName={style.large}
                                    >
                                        <Select
                                            visible={true}
                                            dataSource={politicalFaceDic}
                                            defaultValue={getValues("politicalFace")}
                                            placeholder="请选择家庭成员的政治面貌"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("politicalFace", selected.map(item => item.value));
                                            }}
                                            disabled={isPreview === "1" ? true : false}
                                        />
                                    </Cell>
                                )}
                            />
                        )}
                        <Controller
                            name="phone"
                            control={control}
                            defaultValue={getValues("phone")}
                            rules={{ required: "请输入联系电话" }}
                            render={({ field }) => (
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span className={style.required}>联系电话</span>
                                        </div>
                                    }
                                    className={`${style.cell}`}
                                    leftClassName={style.large}
                                >
                                    <Input className={style.input} {...field} placeholder="请输入联系电话" maxLength={11} disabled={isPreview === "1" ? true : false}/>
                                </Cell>
                            )}
                        />

                        {poor === '1' && (
                            <>
                                <Controller
                                    name="income"
                                    control={control}
                                    defaultValue={getValues("income")}
                                    rules={{ required: "请输入家庭成员年收入" }}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span className={style.required}>年收入（元）</span>
                                                </div>
                                            }
                                            className={`${style.cell}`}
                                        >
                                            <Input
                                                type={"number"}
                                                className={style.input}
                                                {...field}
                                                placeholder="请输入家庭成员年收入"
                                                maxLength={10}
                                                disabled={isPreview === "1" ? true : false}
                                                onChange={
                                                    (e: string|undefined) => {
                                                        if(e && e.length < 11) {
                                                            setValue('income', e)
                                                        }else if(e?.length === 0){
                                                            setValue('income', '')
                                                        }else {
                                                            return
                                                        }
                                                    }
                                                }
                                            />
                                        </Cell>
                                    )}
                                />

                                <Controller
                                    name="healthy"
                                    control={control}
                                    rules={{ required: "请选择家庭成员健康状况" }}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span className={style.required}>健康状况</span>
                                                </div>
                                            }
                                            className={`${style.cell} ${style.refCell}`}
                                        >
                                            <div className={style.row}>
                                                <Select
                                                    visible={true}
                                                    dataSource={healthyDic}
                                                    defaultValue={getValues("healthy")}
                                                    placeholder="请选择"
                                                    disabled={isPreview === "1" ? true : false}
                                                    onOk={(selected: {value: string; label: string}[]) => {
                                                        setValue("healthy", selected.map(item => item.value));
                                                    }}
                                                />
                                            </div>
                                        </Cell>
                                    )}
                                />
                            </>
                        )}

                    </>
                </Card>
                {isPreview !== '1' && <BtnSubmit disabled={disabled}>提交</BtnSubmit>}
                {isPreview === '1' && <Button theme="primary" className={style.button} onClick={()=>history.push(`/poor/${step}/1`)}>返回</Button>}
            </form>
            <FormPopup
                visible={visible}
                title={formInfo.title}
                notice={formInfo.notice}
                type={formInfo.type}
                onClose={() => {
                    setVisible(false);
                    setDisabled(false);
                    if(poor == "1") {//1是绿色通道，0入学登记家庭信息
                        history.push("/poor/2");
                    }else {
                        history.goBack();
                    }

                }}
            />
        </div>
    );

}
