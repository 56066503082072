import React from "react";
import style from "./index.module.scss";
import {Button} from "zarm";
import Cell from "../../../../Components/Cell";
import {Poor} from "../../../../Service/GreenChannel";
import moment from "moment";
import {useHistory} from  "react-router-dom";

interface ApplyRecordProps {
    poorData: Poor;
    onHandleMark: (id: number) => void;
    onView: () => void;
    isUser:string;
}

export default function ApplyRecord({poorData, onHandleMark, onView, isUser}: ApplyRecordProps) {

    const history = useHistory();

    return (
        <div className={style.root}>
            <div className={style.title}>
                <div className={style.line}/>
                <div>您的申请记录：</div>
                <div className={style.buttonDiv}>
                    <Button theme="primary" className={style.yulanButton} onClick={() => onView()}>预览</Button>
                </div>
            </div>

            <div className={style.container}>
                <Cell
                    title={
                        <div className={style.name}>
                            <span className={style.required}>提交时间</span>
                        </div>
                    }
                >
                    <div
                        className={style.value}>{poorData.createTime !== "" ? moment(poorData.createTime).format("YYYY-MM-DD") : ""}</div>
                </Cell>
                <Cell
                    title={
                        <div className={style.name}>
                            <span className={style.required}>学院审核状态</span>
                        </div>
                    }
                >
                    <div
                        className={style.value}>{poorData.firstFlag === '0' ? '等待审核' : poorData.firstFlag === '1' ? "完成审核" : "等待修改"}</div>
                </Cell>
                {poorData.firstFlag === '2' &&
                <>
                    <Cell
                        title={
                            <div className={style.name}>
                                <span className={style.required}>学院审核说明</span>
                            </div>
                        }
                        className={`${style.cell}`}
                    >
                        <div className={style.value}>{poorData.firstReason}</div>
                    </Cell>
                </>
                }

                {poorData.firstFlag !== '0' &&
                <Cell
                    title={
                        <div className={style.name}>
                            <span className={style.required}>学院审核时间</span>
                        </div>
                    }
                >
                    <div
                        className={style.value}>{poorData.firstTime !== "" ? moment(poorData.firstTime).format("YYYY-MM-DD") : ""}</div>
                </Cell>
                }

                {poorData.firstFlag === "1" &&
                <>
                    <Cell
                        title={
                            <div className={style.name}>
                                <span className={style.required}>学校认定状态</span>
                            </div>
                        }
                        className={`${style.cell}`}
                    >
                        <div className={style.value}>{poorData.secondFlag === '0' ? "等待审核" : "完成审核"}</div>
                    </Cell>
                    <Cell
                        title={
                            <div className={style.name}>
                                <span className={style.required}>认定档次</span>
                            </div>
                        }
                        className={`${style.cell}`}
                    >
                        <div className={style.value}>{poorData.secondGrade}</div>
                    </Cell>
                    <Cell
                        title={
                            <div className={style.name}>
                                <span className={style.required}>学校认定时间</span>
                            </div>
                        }
                        className={`${style.cell}`}
                    >
                        <div
                            className={style.value}>{poorData.secondTime !== "" ? moment(poorData.secondTime).format("YYYY-MM-DD") : ""}</div>
                    </Cell>
                </>
                }


            </div>
            <div className={style.button}>
                {/*在等待修改状态，或绿色通道开启状态才显示修改按钮*/}
                {(poorData.firstFlag == '2' || isUser == "1") &&
                <Button theme="primary" className={style.btn1} onClick={() => onHandleMark(1)}>修改</Button>}
                <Button theme="primary" className={style.btn2} onClick={() => history.push("/")}>返回首页</Button>
            </div>

            <div className={style.tips}>
                <img src={require('./img/red.png').default} alt="" className={style.img}/>
                <div>提醒：家庭经济困难申请的同学，报到当天请到绿色通道领取爱心大礼包一份</div>
            </div>

        </div>
    )
}
