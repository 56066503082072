import React from "react";
import style from "./index.module.scss";
import {Member as MemberType} from "../../../../Service/CheckIn";
import { useHistory } from "react-router-dom";

interface IProps{
    data: MemberType[];
    onDel: (id: number, relation: string) => void;
    onAdd: () => void;
    showImgHead: boolean;
    className?: string;
    poor?: string;
    callback: (v: any) => void;
}

export default function Member({data, onDel, onAdd, className, showImgHead, poor, callback}: IProps){
    const history = useHistory();
    function onClick() {
        if(poor === '1') {
            history.push('/member/1');
        }else {
            callback(() => onAdd());
        }
    }

    return (
        <div className={`${style.root} ${className}`}>
            {showImgHead && (
                <div className={style.notice}>
                    <div className={style.noticeFont}>请填家庭成员或监护人信息</div>
                </div>
            )}
            <div className={style.list}>
                {data?.map(item => (
                    <div key={item.id} className={style.item}>
                        <Person data={item} poor={poor} callback={callback} />
                        <img className={style.del} src={require('./img/del.png').default} onClick={() => onDel(item.id, item.name)} alt=""/>
                    </div>
                ))}
                {(data.length < 4) && (
                    <div className={style.item}>
                        <div className={style.addBtn} onClick={onClick}>
                            <img className={style.addBtnBg} src={require('./img/add.png').default} alt=""/>
                            <div className={style.content}>添加家庭成员</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

Member.defaultProps = {
    showImgHead: true,
    callback: () => {}
}

function Person({data, poor, callback}: {data: MemberType, poor: string, callback: (v: any) => void}) {
    const history = useHistory();

    function onClick(poor: string, id: number) {
        if(poor === '0') {
            callback(() => history.push(`/member/${poor}/${id}`));
        }else {
            history.push(`/member/${poor}/${id}`)
        }
    }

    return (
        <div className={style.person}  onClick={() => onClick(poor, data.id)}>
            <div className={style.info}>
                <div className={style.name}>{data.name}</div>
                <div className={style.call}><span>{data.relation}</span></div>
                {parseInt(poor) === 1 && data.perfect === '0' && <div className={style.prefect}>
                    不完善
                </div>}
            </div>
        </div>
    );
}

Person.defaultProps = {
  poor: '0'
};
