import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import icon1 from "./img/rxdj.png";
import icon2 from "./img/lstd.png";
import icon3 from "./img/zxjf.png";
import icon4 from "./img/jtxx.png";
import icon5 from "./img/yqbd.png";
import dayjs from "dayjs";
import {ProjectName} from "../../../../Service/Home";
import {Toast} from "zarm";
import {useHistory} from "react-router-dom";
import {renderIntoDocument} from "react-dom/test-utils";
import useRequest from "../../../../Utils/useRequest";
import {getPoor, getPoorFlag} from "../../../../Service/GreenChannel";

interface props{
    rxStatus?:string;//入学状态
    jfStatus?:string;//交费状态
    jtStatus?:string;//交通状态
    admissionData?:ProjectName[];
    name?:string;    //填报人姓名
    employeeNo?:string;//学号
    delayedFlag?:string;//是否申请了延期报到
}


export default function Admission({rxStatus, jfStatus, jtStatus, admissionData, name, employeeNo, delayedFlag}:props) {

    const history = useHistory();
    const [ruxuedengji, setRuxuedengji] = useState<ProjectName>();
    const [lvsetongdao, setLvsetongdao] = useState<ProjectName>();
    const [zaixianjiaofei, setZaixianjiaofei] = useState<ProjectName>();
    const [jiaotongfangshi, setJiaotongfangshi] = useState<ProjectName>();
    const [yanqibaodao, setYanqibaodao] = useState<ProjectName>();
    const [lvsetongdaoguanbishijian, setLvsetongdaoguanbishijian] = useState<ProjectName>()
    const [PoorFlagData] = useRequest(() => getPoorFlag());//查看家庭经济困难各种填写flag查看

    useEffect(() => {
        admissionData?.map((item) => {
            if(item.indexName == "ruxuedengji") {
                setRuxuedengji(item);
            }
            if(item.indexName == "lvsetongdao") {
                setLvsetongdao(item);
            }
            if(item.indexName == "zaixianjiaofei") {
                setZaixianjiaofei(item);
            }
            if(item.indexName == "jiaotongfangshi") {
                setJiaotongfangshi(item);
            }
            if(item.indexName == "yanqibaodao") {
                setYanqibaodao(item);
            }
            if(item.indexName == "lvsetongdaoguanbishijian") {
                setLvsetongdaoguanbishijian(item);
            }
        })
    },[admissionData]);

    function buttonClick (isUser:string, button:string) {
        if(isUser === "0") {
            Toast.show('未开启');
        }else {
            if(button == '0') {
                history.push("/enrollment")
            }
            if(button == '1') {
                if(lvsetongdaoguanbishijian?.isUser == "0" && PoorFlagData?.submitFlag == "0") {//绿色通道结束并且没提交过
                    history.push("/greenTheEnd")
                }else {
                    history.push("/poor")
                }

            }
            if(button == '2') {
                history.push("/payment")
            }
            if(button == '3') {
                history.push(`/transportation/${name}`)
            }
            if(button == '4') {
                if(delayedFlag == "0") {//0未填
                    history.push(`/postponement/form/${name}/${employeeNo}`)
                }else {
                    history.push(`/postponement/detail/${name}/${employeeNo}`)
                }

            }
        }
    }

    return (
        <div className={style.container}>
            <div className={style.admissionDiv}>
                {
                    admissionData &&
                    <>
                        {ruxuedengji &&
                            <div className={style.button} onClick={() => buttonClick(ruxuedengji.isUser, "0")}>
                                <div className={style.buttonDiv}>
                                    <div className={style.buttonBefore}>{rxStatus && rxStatus == "0"?'未填报':'已填报'}</div>
                                    <img className={style.buttonIcon} src={icon1}/>
                                    <div className={style.font}>入学登记</div>
                                    <div className={style.detail}>{dayjs(ruxuedengji.date).format("M.D")}开启</div>
                                </div>
                            </div>
                        }
                        {lvsetongdao &&
                            <div className={style.button} onClick={() => buttonClick(lvsetongdao.isUser, "1")}>
                                <div className={style.buttonDiv}>
                                    <img className={style.buttonIcon} src={icon2}/>
                                    <div className={style.font}>绿色通道</div>
                                    <div className={style.detail}>{dayjs(lvsetongdao.date).format("M.D")}开启</div>
                                </div>
                            </div>
                        }
                        {zaixianjiaofei &&
                            <div className={style.button} onClick={() => buttonClick(zaixianjiaofei.isUser, "2")}>
                                <div className={style.buttonDiv}>
                                    <div className={style.buttonBefore}>{jfStatus && jfStatus == "0"?'未交费':'已交费'}</div>
                                    <img className={style.buttonIcon} src={icon3}/>
                                    <div className={style.font}>在线交费</div>
                                    <div className={style.detail}>{dayjs(zaixianjiaofei.date).format("M.D")}开启</div>
                                </div>
                            </div>
                        }
                        {jiaotongfangshi &&
                            <div className={style.button} onClick={() => buttonClick(jiaotongfangshi.isUser, "3")}>
                                <div className={style.buttonDiv}>
                                    <div className={style.buttonBefore}>{jtStatus && jtStatus == "0"?'未填报':'已填报'}</div>
                                    <img className={style.buttonIcon} src={icon4}/>
                                    <div className={style.font}>交通信息</div>
                                    <div className={style.detail}>{dayjs(jiaotongfangshi.date).format("M.D")}开启</div>
                                </div>
                            </div>

                        }
                        {yanqibaodao &&
                            <div className={style.button} onClick={() => buttonClick(yanqibaodao.isUser, "4")}>
                                <div className={style.buttonDiv}>
                                    <img className={style.buttonIcon} src={icon5}/>
                                    <div className={style.font}>延期报到</div>
                                    <div className={style.detail}>{dayjs(yanqibaodao.date).format("M.D")}开启</div>
                                </div>
                            </div>

                        }

                    </>
                }
            </div>
        </div>
    );
}
