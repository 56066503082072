import Request from "Utils/Requests";
import {ChangeEvent} from "react";
import Storage from "../Utils/Storage";
import qs from "qs";

const BaseUrl = process.env.REACT_APP_BASE_URL;



/**
 * 入学登记状态查询(个人信息是否填报等)
 */
export interface EnrollmentStatus {
    familyFlag:string;//家庭信息是否填报flag(0.否,1.是)
    employeeNo:string;//学号
    picStatusFlag:string;//图片是否通过审核flag(0.审核中,1.已通过2.未通过)
    picFlag:string;//图片是否上传flag(0.否,1.是)
    infoFlag:string;//个人信息是否填报flag(0.否,1.是)
    picReason:string;//未通过信息提示

}

export function getEnrollmentStatus() {
    return Request.get<SuccessData<EnrollmentStatus>>(`${BaseUrl}/zhxg/getEnrollmentStatus`)
        .then(res => res.data.data);
}

/**
 * 入学登记-保存个人照片
 */
interface StudentPic {
    message:string;//报错信息或者图片信息
}

export function updateStudentPic(pic: string) {
    return Request.post<SuccessData<StudentPic>>(`${BaseUrl}/zhxg/updateStudentPic`,qs.stringify({pic}))
        .then(res => res.data);
}


/**
 * 查看个人上传图片
 */
export interface MyPic {
    pic:string;//上传图片
}

export function getMyPic() {
    return Request.get<SuccessData<MyPic>>(`${BaseUrl}/zhxg/getMyPic`)
        .then(res => res.data.data);
}

/**
 * 上传图片接口（只传一张）
 */
interface UploadOne {
    code:string;
    message:string;//报错信息或者图片信息
}

export function uploadToImg(file: File,type:string) {
    const headers = {'Content-Type': 'multipart/Form-data'};
    const formData = new FormData();
    const size =  file.size;
    if(!(file.type.split("/")[1] ==="jpg" || file.type.split("/")[1] ==="jpeg" )) {
        throw new Error('图片格式必须是jpg格式');
    }
    if ((size < 100 * 1024) || (size > 200 *1024)) {
        throw new Error('图片大小限制在100~200kb以内');
    }


    formData.append("file", file);
    formData.append("checkPix", type);//是否上传个人照片；0：否（其他），1：是(入学登记使用)
    formData.append('token',Storage.get("token")||"");
    // formData.append("weekTimeId", weekTimeId.toString());

    return Request.post<SuccessData<UploadOne>>(`${BaseUrl}/file/uploadnew`, formData, {headers})
        .then(res => res.data);
}

/**
 * 七牛token
 */
interface qiniu {
    domain:string;//七牛域名后接返回key值拼成完整图片路径
    token:string;//七牛token
}

export function getQiniuToken () {
    return Request.post<SuccessData<qiniu>>(`${BaseUrl}/upload/getQiniuToken`)
        .then(res => res.data.data);
}


/**
 * 上传图片接口（七牛）
 */
interface UploadQiNiu {
    key:string;
    message:string;//报错信息或者图片信息
}

export async function uploadToQiNiu(file: File, token:string) {
    const headers = {'Content-Type': 'multipart/Form-data'};
    const formData = new FormData();
    const size =  file.size;
    if(!(file.type.split("/")[1] ==="jpg" || file.type.split("/")[1] ==="jpeg" || file.type.split("/")[1] ==="png" )) {
        throw new Error('图片格式必须是jpg或png格式');
    }
    if (size > 10 * 1024 * 1024) {
        throw new Error('图片大小限制在10M以内');
    }
    formData.append("file", file);
    formData.append('token',token);

    //todo:七牛地址未申请先暂时用校友上正式修改
    return Request.post<UploadQiNiu>(`https://up-z1.qiniup.com/`, formData, {headers})
        .then(res => res.data);
}
