import React from "react";
import {useHistory} from "react-router-dom";
import Empty from "../../../Components/Empty";
import style from "./index.module.scss";
import {Button} from "zarm";
import useRequest from "../../../Utils/useRequest";
import {getMyClass} from "../../../Service/MyClass";
import fingerprint from "./img/fingerprint.png"
import defaultAvater from "./img/avater.png";
import {getHomeStudentInfo} from "../../../Service/Home";
import {useTitle} from "react-use";

export default function ClassDetail() {

    useTitle("班级", {restoreOnUnmount: true});
    const history = useHistory();
    const [stuInfo] = useRequest(() => getHomeStudentInfo())
    const [classData, {loading}] = useRequest(() => getMyClass(), {});

    const checkClassmate = () => {
        history.push(`/stulist/${classData?.enterYear}/${classData?.classNo}/${classData?.studentNum}/${classData?.college}/${classData?.major}/${classData?.bianhao}`)
    }

    return (
        <div className={style.root}>
            {/*我的班级-未分配*/}
            {
                classData?.bianhao === undefined && (
                    <div className={style.empty}>
                        <div className={`${style.nameAndNo}`}>姓名：{stuInfo?.name}</div>
                        <div className={`${style.nameAndNo} ${style.stuNo}`}>学号：{stuInfo?.employeeNo}</div>
                        <Empty msg={"尚未完成分班，请稍后查看"}/>
                        <Button theme="primary" className={style.button1} onClick={() => history.goBack()}>返回</Button>
                    </div>
                )
            }

            {/*我的班级-已分配*/}
            {
                classData?.bianhao !== undefined  && !loading &&(
                    <>
                        <div className={style.card}>

                            <div className={style.header}>
                                <div className={style.avater}>
                                    {classData.pic === '' && <img src={defaultAvater} className={style.avaterPic}/>}
                                    {classData.pic !== '' && <img src={classData.pic} alt="" className={style.avaterPic}/>}
                                </div>
                                <div className={style.stuInfo}>
                                    <div className={style.name}>{classData.name}</div>
                                    <div className={style.no}>学号：{classData.employeeNo}</div>
                                </div>
                            </div>

                            <div className={style.content}>
                                    <div className={style.item}>
                                        <div className={style.outerRing}>
                                            <div className={style.innerRing}/>
                                        </div>
                                        院系：
                                        <div className={style.fontContent}>
                                            {classData.college}
                                        </div>
                                    </div>
                                    <div className={style.item}>
                                        <div className={style.outerRing}>
                                            <div className={style.innerRing}/>
                                        </div>
                                        年级：
                                        <div className={style.fontContent}>
                                            {classData.enterYear}
                                        </div>
                                    </div>
                                    <div className={style.item}>
                                        <div className={style.outerRing}>
                                            <div className={style.innerRing}/>
                                        </div>
                                        专业：
                                        <div className={style.fontContent}>
                                            {classData.major}
                                        </div>
                                    </div>
                                    <div className={style.item}>
                                        <div className={style.outerRing}>
                                            <div className={style.innerRing}/>
                                        </div>
                                        班级：
                                        <div className={style.fontContent}>
                                            {classData.classNo}
                                        </div>
                                    </div>
                                    <div className={style.item}><div className={style.outerRing}><div className={style.innerRing}/></div>班级人数：{classData.studentNum}人</div>
                                    <div className={style.item}><div className={style.outerRing}><div className={style.innerRing}/></div>辅导员：{classData.instructorName}</div>
                                    <div className={style.item}><div className={style.outerRing}><div className={style.innerRing}/></div>辅导员手机：{classData.phone}</div>
                                    <div className={style.item}><div className={style.outerRing}><div className={style.innerRing}/></div>辅导员办公电话：{classData.officePhone}</div>
                                    <div className={style.item}>
                                        <div className={style.outerRing}>
                                            <div className={style.innerRing}/>
                                        </div>
                                        辅导员微信：
                                        <div className={`${style.fontContent} ${style.width300}`}>
                                            {classData.weiXin}
                                        </div>
                                    </div>
                            </div>

                            <div className={style.line}/>

                            <div className={style.footer}>
                                <div>
                                    <div className={style.erweima}>
                                        <img src={classData.classQRCode} alt="" className={style.erweimaPic}/>
                                    </div>
                                    <div className={style.weiChatFont}>班级微信群二维码</div>
                                </div>
                                <div>
                                    <img src={fingerprint} className={style.finger}/>
                                    <div className={style.scaleFont}>可以截图保存二维码 在微信上识别扫描</div>
                                </div>
                            </div>
                        </div>

                        <Button theme="primary" className={style.button2} onClick={checkClassmate}>查看同班同学</Button>
                    </>
                )
            }
        </div>
    );
}
