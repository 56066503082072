import React from "react";
import style from "./index.module.scss";
import {Button as BtnZarm} from "zarm";
import {TogetherByIdInfo, TogetherInfo} from "../../../../Service/Transportation";

interface Props {
    data:TogetherByIdInfo;
    togetherShadowClose:() => void;//阴影隐藏
    togetherTransFormClose:() => void;//内容滑动隐藏
    togShadowIsShow:boolean;
    togTransformIsShow:boolean;

}

export default function CompanionDetail({data, togetherShadowClose, togetherTransFormClose, togShadowIsShow, togTransformIsShow }:Props) {

    function close() {
        togetherTransFormClose();
        setTimeout((() => {
            togetherShadowClose()
        }),300)
    }

    return (
        <div className={`${style.container} ${togShadowIsShow?style.show:style.hidden}`}>
            <div className={`${style.companionDetailDiv} ${togTransformIsShow?"":style.transform1400}`}>
                <div className={style.detailDiv}>
                    <img className={style.close} src={require("./img/comClose.png").default} onClick={() => close()}/>
                    <div className={style.headImg}>
                        <img src={data.pic}/>
                    </div>
                    <div className={style.content}>
                        <div className={style.contentRow}>
                            <div className={style.leftLabel}>
                                姓名
                            </div>
                            <div className={style.rightFont}>
                                {data.name || "无"}
                            </div>
                        </div>
                        <div className={style.contentRow}>
                            <div className={style.leftLabel}>
                                出发地
                            </div>
                            <div className={style.rightFont}>
                                {data.outPlace || "无"}
                            </div>
                        </div>
                        <div className={style.contentRow}>
                            <div className={style.leftLabel}>
                                来校方式
                            </div>
                            <div className={style.rightFont}>
                                {data.trafficModeName || "无"}
                            </div>
                        </div>
                        {data.trafficModeName !== "自驾" &&
                            <div className={style.contentRow}>
                                <div className={style.leftLabel}>
                                    {data.trafficModeName}
                                </div>
                                <div className={style.rightFont}>
                                    {data.trafficNum || "无"}
                                </div>
                            </div>
                        }

                        {/*<div className={style.contentRow}>*/}
                        {/*    <div className={style.leftLabel}>*/}
                        {/*        是否需要接站*/}
                        {/*    </div>*/}
                        {/*    <div className={style.rightFont}>*/}
                        {/*        {data.statusFlag=="0"?"否":"是"}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {data.statusFlag=="0" &&
                            <div className={style.contentRow}>
                                <div className={style.leftLabel}>
                                    到校时间
                                </div>
                                <div className={style.rightFont}>
                                    {data.arrivalData || "无"}&nbsp;&nbsp;{data.arrivalData?data.pickTimeName:""}
                                </div>
                            </div>
                        }
                        {data.statusFlag=="1" &&
                            <>
                                {data.trafficModeName !== "自驾" &&
                                    <div className={style.contentRow}>
                                        <div className={style.leftLabel}>
                                            到达站
                                        </div>
                                        <div className={style.rightFont}>
                                            {data.pickLocalName || "无"}
                                        </div>
                                    </div>
                                }
                                <div className={style.contentRow}>
                                    <div className={style.leftLabel}>
                                        到达时间
                                    </div>
                                    <div className={style.rightFont}>
                                        {data.arrivalData || "无"}&nbsp;&nbsp;{data.arrivalData?data.pickTimeName:""}
                                    </div>
                                </div>
                            </>
                        }
                        <div className={style.contentRow}>
                            <div className={style.leftLabel}>
                                陪同人员
                            </div>
                            <div className={style.rightFont}>
                                {data.familyNum?`${data.familyNum}人`:"无"}
                            </div>
                        </div>
                        <div className={style.contentRow}>
                            <div className={style.leftLabel}>
                                联系电话
                            </div>
                            <div className={style.rightFont}>
                                {data.phone || "无"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
