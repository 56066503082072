import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import redOne from "./img/redOne.png";
import redTwo from "./img/redTwo.png";
import whiteTwo from "./img/whiteTwo.png";
import whiteThree from "./img/whiteThree.png";
import error from "./img/error.png";
import right from "./img/success.png";
import classNames from "classnames";

export default function ProgressBar ({auditStatus}:{auditStatus:string}) {

    const [step, setStep] = useState( "");

    useEffect(()=>{
        if(auditStatus === undefined){
            setStep("")
        }
        setStep(auditStatus)
    },[auditStatus])

    return (
        // （0.审核中，1.审核通过，2.审核未通过,'':未上传）
        <div className={style.root}>
            <div className={style.container}>
                <div className={style.stepOne}>
                    <img src={redOne} alt=""/>
                    <div className={style.fontOne}>申请</div>
                </div>

                <div className={classNames(style.lineOne,{[style.lineRed]:step === '0'})}/>

                <div className={style.stepTwo}>
                    {(step !== '') && <img src={redTwo} alt="" className={style.redImg}/>}
                    {(step === '') && <img src={whiteTwo} className={style.whileImg} alt=""/>}
                    <div className={classNames(style.font,{[style.fontRed]:(step !== '')})}>等待审核</div>
                </div>

                <div className={classNames(style.lineTwo)}/>
                {(step === '1' || step === '2') &&  <div className={classNames(style.lineLong)}/>}

                <div className={style.stepTwo}>
                    { step === '1' &&
                        <>
                            <img src={right} alt="" className={style.redImg}/>
                            <div className={classNames(style.font,style.fontRed)}>审核通过</div>
                        </>
                    }
                    { step === '2' &&
                        <>
                            <img src={error} alt="" className={style.redImg}/>
                            <div className={classNames(style.font,style.fontMargin,style.fontRed)}>审核未通过</div>
                        </>
                    }
                    {(step === '' || step === '0') &&
                        <>
                            <img src={whiteThree} className={style.whileImg} alt=""/>
                            <div className={style.font}>审核结果</div>
                        </>
                    }
                </div>
            </div>

        </div>
    )
}
