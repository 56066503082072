import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import close from "./img/close.png";
import Cell from "../../../../../../Components/Cell";
import {ImagePreview, Input} from "zarm";
import {ViewPoor} from "../../../../../../Service/GreenChannel";
import ClassName from "classnames";
import {useHistory} from "react-router-dom";

interface SubmitDetailProps {
    onClick: (val: boolean) => void;
    data: ViewPoor;
    step: number;
}

export default function SubmitDetail({data, onClick, step}: SubmitDetailProps) {

    const history = useHistory();
    //图片预览的展示或隐藏
    const [jdlkVisible, setJdlkVisible] = useState(false);   //建档立卡
    const [zdshVisible, setZdshVisible] = useState(false);   //最低生活
    const [tkgyVisible, setTkgyVisible] = useState(false);   //特困供养
    const [gcxsVisible, setGcxsVisible] = useState(false);   //孤残
    const [lszvVisible, setLszvVisible] = useState(false);   //烈士子女
    const [jtjjknVisible, setJtjjknVisible] = useState(false);   //残疾人子女
    //证明材料图片预览，根据index查看同一数组下对应的图片
    const [pictureIndex1, setPictureIndex1] = useState<number>(0);
    const [pictureIndex2, setPictureIndex2] = useState<number>(0);
    const [pictureIndex3, setPictureIndex3] = useState<number>(0);
    const [pictureIndex4, setPictureIndex4] = useState<number>(0);
    const [pictureIndex5, setPictureIndex5] = useState<number>(0);
    const [pictureIndex6, setPictureIndex6] = useState<number>(0);


    const [addressArea, setAddressArea] = useState<string>(); //家庭所在地区
    const [addressInfo, setAddressInfo] = useState<string>(); //详细地址
    const [jdlkList] = useState(data.jiandangpingkunshengZhengming ? data.jiandangpingkunshengZhengming.split('|') : []);//建档立卡贫困家庭
    const [zdshList] = useState(data.zuidishenghuobaozhengZhengming ? data.zuidishenghuobaozhengZhengming.split('|') : []);//最低生活保障家庭
    const [tkgyList] = useState(data.tekungongyangxueshengZhengming ? data.tekungongyangxueshengZhengming.split('|') : []);//特困供养学生
    const [gcxsList] = useState(data.gucanxueshengZhengming ? data.gucanxueshengZhengming.split('|') : []);//孤残学生证
    const [lszvList] = useState(data.liesizinvZhengming ? data.liesizinvZhengming.split('|') : []);//烈士子女学生
    const [jtjjknList] = useState(data.caijixueshengZhengming ? data.caijixueshengZhengming.split('|') : []);//家庭经济困难残疾学生及残疾人子女学生

    useEffect(() => {
        if (data) {
            const addressData = data.address.split("|")
            const arr = [];
            for (let i = 0; i < addressData.length - 1; i++) {
                arr.push(addressData[i])
            }
            setAddressArea(arr.join(''))
            setAddressInfo(addressData?.pop());
        }
    }, [data])

    function handleClose() {
        onClick(false);
        history.push(`/poor/${step}`)
    }

    return (
        <div className={style.root}>

            <div className={style.container}>
                <img src={close} alt="" className={style.close} onClick={handleClose}/>
                <div className={style.content}>
                    {/*基本情况*/}
                    <div className={style.baseInfo}>
                        <div className={style.title}>
                            <div className={style.line}/>
                            <div>基本情况</div>
                        </div>
                        <div className={style.formContent}>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>姓名</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}} value={data.name} disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>家庭人口</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}} value={data.jiatingrenkou + "人"} disabled={true}/>
                            </Cell>
                        </div>
                    </div>
                    <div className={style.divided}/>

                    {/*助学贷款*/}
                    <div className={style.baseInfo}>
                        <div className={style.title}>
                            <div className={style.line}/>
                            <div>助学贷款</div>
                        </div>
                        <div className={style.formContent}>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>申请助学贷款</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}} value={data.loanFlag === '1' ? "已申请" : "未申请"}
                                       disabled={true}/>
                            </Cell>
                        </div>
                    </div>
                    <div className={style.divided}/>

                    {/*家庭通讯信息*/}
                    <div className={style.baseInfo}>
                        <div className={style.title}>
                            <div className={style.line}/>
                            <div>家庭通讯信息</div>
                        </div>
                        <div className={style.formContent}>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>家庭所在地区</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <div className={style.value}>{addressArea}</div>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>详细地址</span>
                                    </div>
                                }
                                className={`${style.cell} ${style.textArea}`}
                                leftClassName={style.large}
                                rightClassName={style.right}
                            >
                                <div className={style.info}>{addressInfo}</div>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>家庭联系人姓名</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}} value={data.urgentConcat} disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>家庭联系人电话</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}} value={data.urgentPhone} disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>与本人关系</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}} value={data.urgentRelation} disabled={true}/>
                            </Cell>
                        </div>
                    </div>
                    <div className={style.divided}/>

                    {/*特殊群体类型*/}
                    <div className={style.baseInfo}>
                        <div className={style.title}>
                            <div className={style.line}/>
                            <div>特殊群体类型</div>
                        </div>
                        <div className={style.formContent}>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>建档立卡贫困家庭学生</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                                leftClassName={style.large}
                            >
                                <Input style={{textAlign: 'right'}}
                                       value={data.jiandangpingkunsheng === "1" ? "是" : "否"} disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>最低生活保障家庭学生</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                                leftClassName={style.large}
                            >
                                <Input style={{textAlign: 'right'}}
                                       value={data.zuidishenghuobaozhang === "1" ? "是" : "否"} disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>特困供养学生</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                                leftClassName={style.large}
                            >
                                <Input style={{textAlign: 'right'}} value={data.tekunsheng === "1" ? "是" : "否"}
                                       disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>残孤学生</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                                leftClassName={style.large}
                            >
                                <Input style={{textAlign: 'right'}} value={data.gucanxuesheng === "1" ? "是" : "否"}
                                       disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>烈士子女</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                                leftClassName={style.large}
                            >
                                <Input style={{textAlign: 'right'}} value={data.lieshizinv === "1" ? "是" : "否"}
                                       disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>家庭经济困难残疾学生及残疾人子女</span>

                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}} value={data.canjirenzinv === "1" ? "是" : "否"}
                                       disabled={true}/>
                            </Cell>

                        </div>
                    </div>
                    <div className={style.divided}/>

                    {/*家庭成员情况*/}
                    <div className={style.baseInfo}>
                        <div className={style.title}>
                            <div className={style.line}/>
                            <div>家庭成员情况</div>
                        </div>
                        <div className={style.family}>
                            {data.familyList.map(item =>
                                <div className={style.familyItem} key={item.id}
                                     onClick={() => history.push(`/member/1/${item.id}/1/${step}`)}>
                                    <div className={style.familName}>{item.name}</div>
                                    <div className={style.familyCall}>{item.relation}</div>
                                </div>
                            )}

                        </div>
                    </div>
                    <div className={style.divided}/>

                    {/*经济困难家庭详细情况*/}
                    <div className={style.baseInfo}>
                        <div className={style.title}>
                            <div className={style.line}/>
                            <div>经济困难家庭详细情况</div>
                        </div>
                        <div className={style.formContent}>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>孤儿</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <div className={style.value}>{data.jinjikunnanGuer}</div>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>单亲</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <div className={style.value}>{data.jinjikunnanDanqin}</div>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>残疾</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <div className={style.value}>{data.jinjikunnanCanji}</div>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>疾病</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <div className={style.value}>{data.jinjikunnanJibing}</div>
                            </Cell>
                        </div>
                    </div>
                    <div className={style.divided}/>

                    {/*家庭负担情况*/}
                    <div className={style.baseInfo}>
                        <div className={style.title}>
                            <div className={style.line}/>
                            <div>家庭负担情况</div>
                        </div>
                        <div className={style.formContent}>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>家庭需独立赡养无收入老人</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                                leftClassName={style.large}
                            >
                                <Input style={{textAlign: 'right'}} value={data.jiatingfudanDuliwulaoren + "位"}
                                       disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>家庭需联合赡养无收入老人</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                                leftClassName={style.large}
                            >
                                <Input style={{textAlign: 'right'}} value={data.jiatingfudanLianhewulaoren + "位"}
                                       disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>除本人外，家中另有已具备工作能力且可以较大程度补贴家用的子女</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}} value={data.jiatingfudanYougongzuobutiezinv + "位"}
                                       disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>除本人外，家中另有已具备工作能力但仍需家里较大程度补贴的子女</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}}
                                       value={data.jiatingfudanYougongzuorengxubutiezinv + "位"} disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>除本人外，家中另有在校高中（含）以上就读学生</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}} value={data.jiatingfudanYouzaidugaozhong + "位"}
                                       disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>除本人外，家中另有义务阶段、中职学校或幼儿园就读学生</span>

                                    </div>
                                }
                                className={ClassName(style.cell)}
                            >
                                <Input style={{textAlign: 'right'}} value={data.jiatingfudanYouzaiduyiwujiaoyu + "位"}
                                       disabled={true}/>
                            </Cell>
                            {data.jiatingfudanOther !== "" &&
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>其他情况</span>
                                    </div>
                                }
                                className={`${style.cell} ${style.textArea}`}
                            >
                                <div className={style.info}>{data.jiatingfudanOther}</div>
                            </Cell>
                            }

                        </div>
                    </div>
                    <div className={style.divided}/>

                    {/*家庭固定资产情况*/}
                    {(data.jiatingzicanYishangdouwu === '0' && (data.jiatingzicanChengshifangchan !== "0" || data.jiatingzicanNongcunfangchan !== "0" || data.jiatingzicanCheliang !== "0" || data.jiatingzicanOther !== "0")) &&
                    <>
                        <div className={style.baseInfo}>
                            <div className={style.title}>
                                <div className={style.line}/>
                                <div>家庭固定资产情况</div>
                            </div>
                            <div className={style.formContent}>
                                {data.jiatingzicanChengshifangchan === "1" &&
                                <>
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>城市有（）套房</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        leftClassName={style.large}
                                    >
                                        <Input style={{textAlign: 'right'}} value={data.jiatingzicanFangcantaoshu}
                                               disabled={true}/>
                                    </Cell>
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>房产-城市房产，共价值（）万元</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        leftClassName={style.large}
                                    >
                                        <Input style={{textAlign: 'right'}} value={data.jiatingzicanFangcanjiazhi}
                                               disabled={true}/>
                                    </Cell>
                                </>
                                }

                                {data.jiatingzicanNongcunfangchan === "1" &&
                                <>
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>房产-农村自建房，房产共有（）处</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        leftClassName={style.large}
                                    >
                                        <Input style={{textAlign: 'right'}} value={data.jiatingzicanNongcuntaoshu}
                                               disabled={true}/>
                                    </Cell>
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>房产-农村自建房，共价值（）万元</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        leftClassName={style.large}
                                    >
                                        <Input style={{textAlign: 'right'}} value={data.jiatingzicanNongcunjiazhi}
                                               disabled={true}/>
                                    </Cell>
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>农村-旧村改造房，房产共（）处</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        leftClassName={style.large}
                                    >
                                        <Input style={{textAlign: 'right'}} value={data.jiatingzicanNongcunjiutaoshu}
                                               disabled={true}/>
                                    </Cell>
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>农村-旧村改造房，价值（）万元</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        leftClassName={style.large}
                                    >
                                        <Input style={{textAlign: 'right'}} value={data.jiatingzicanNongcunjiujiazhi}
                                               disabled={true}/>
                                    </Cell>
                                </>
                                }

                                {data.jiatingzicanCheliang === "1" &&
                                <>
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>车辆-私家车共（）辆</span>
                                            </div>
                                        }
                                        className={`${style.cell}`}
                                        leftClassName={style.large}
                                    >
                                        <Input style={{textAlign: 'right'}} value={data.jiatingzicanSijiacheshuliang}
                                               disabled={true}/>
                                    </Cell>
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>车辆-私家车共价值（）万元</span>
                                            </div>
                                        }
                                        className={ClassName(style.cell)}
                                        leftClassName={style.large}
                                    >
                                        <Input style={{textAlign: 'right'}} value={data.jiatingzicanSijiachejiazhi}
                                               disabled={true}/>
                                    </Cell>
                                </>
                                }

                                {data.jiatingzicanOther === "1" &&
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span>其他固定资产</span>
                                        </div>
                                    }
                                    className={`${style.cell} ${style.textArea}`}
                                >
                                    <div className={style.info}>{data.jiatingzicanOtherInfo}</div>
                                </Cell>
                                }
                            </div>
                        </div>
                        <div className={style.divided}/>
                    </>
                    }


                    {/*其他信息*/}
                    <div className={style.baseInfo}>
                        <div className={style.title}>
                            <div className={style.line}/>
                            <div>其他信息</div>
                        </div>
                        <div className={style.formContent}>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>家庭人均年收入（）元</span>
                                    </div>
                                }
                                className={ClassName(style.cell)}
                            >
                                <Input style={{textAlign: 'right'}} value={data.jiatingrenjunnianshouru}
                                       disabled={true}/>
                            </Cell>
                            {data.jiatingzhaoyuzaihaiqingkuang !== "" &&
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>家庭遭受自然灾害情况</span>
                                    </div>
                                }
                                className={ClassName(style.cell, style.textArea)}

                                leftClassName={style.large}
                            >
                                <div className={style.info}>{data.jiatingzhaoyuzaihaiqingkuang}</div>
                            </Cell>
                            }
                            {data.jiatingzhaoyutufayiwaishijian !== "" &&
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>家庭遭受突发意外事件</span>
                                    </div>
                                }
                                className={ClassName(style.cell, style.textArea)}
                                leftClassName={style.large}
                            >
                                <div className={style.info}>{data.jiatingzhaoyutufayiwaishijian}</div>
                            </Cell>
                            }
                            {data.jiatingchengyuanshangshilaodongnengli !== "" &&
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>家庭成员因残疾、年迈而丧失劳动能力情况</span>
                                    </div>
                                }
                                className={ClassName(style.cell, style.textArea)}
                                leftClassName={style.large}
                            >
                                <div className={style.info}>{data.jiatingchengyuanshangshilaodongnengli}</div>
                            </Cell>
                            }
                            {data.jiatingjianzaiqingkuang !== "" &&
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>家庭欠债情况</span>
                                    </div>
                                }
                                className={`${style.cell} ${style.textArea}`}
                                leftClassName={style.large}
                            >
                                <div className={style.info}>{data.jiatingjianzaiqingkuang}</div>
                            </Cell>
                            }
                        </div>
                    </div>
                    <div className={style.divided}/>

                    {/*个人经济情况*/}
                    <div className={style.baseInfo}>
                        <div className={style.title}>
                            <div className={style.line}/>
                            <div>个人经济情况</div>
                        </div>
                        <div className={style.formContent}>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>家庭月提供费用</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}} value={data.gerenjingjiYuetigongshouru}
                                       disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>学生月平均支出</span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}} value={data.gerenjingjiYuepingjunzhichu}
                                       disabled={true}/>
                            </Cell>
                        </div>
                    </div>
                    <div className={style.divided}/>

                    {/*学生大件物品情况*/}
                    <div className={style.baseInfo}>
                        <div className={style.title}>
                            <div className={style.line}/>
                            <div>学生大件物品情况</div>
                        </div>
                        <div className={style.formContent}>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>电子设备<span
                                            className={style.beizhu}>（个人手机）</span></span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}} value={data.gerenjingjiGerenshouji}
                                       disabled={true}/>
                            </Cell>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span className={style.required}>电子设备<span
                                            className={style.beizhu}>（个人电脑）</span></span>
                                    </div>
                                }
                                className={`${style.cell}`}
                            >
                                <Input style={{textAlign: 'right'}} value={data.gerenjingjiGerendiannao}
                                       disabled={true}/>
                            </Cell>
                            {data.gerenjingjiGerenotherdianzi !== "" &&
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>电子设备<span className={style.beizhu}>（其他）</span></span>
                                    </div>
                                }
                                className={ClassName(style.cell, style.textArea)}
                                leftClassName={style.large}
                            >
                                {/*<Input className={style.input} value={data.gerenjingjiGerenotherdianzi} disabled={true}*/}
                                {/*       autoHeight*/}
                                {/*       rows={3} maxLength={50}/>*/}
                                <div className={style.info}>{data.gerenjingjiGerenotherdianzi}</div>

                            </Cell>
                            }
                            {data.gerenjingjiGerenotherdajian !== "" &&
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>其他大件</span>
                                    </div>
                                }
                                className={`${style.cell} ${style.textArea}`}
                                leftClassName={style.large}
                            >
                                {/*<Input className={style.input} value={data.gerenjingjiGerenotherdajian} disabled={true}*/}
                                {/*       autoHeight*/}
                                {/*       rows={3} maxLength={50}/>*/}
                                <div className={style.info}>{data.gerenjingjiGerenotherdajian}</div>

                            </Cell>
                            }
                        </div>
                    </div>

                    {/*学生目前所受资助情况 --wu*/}
                    {data.supportList && data.supportList.length !== 0 &&
                    <>
                        <div className={style.divided}/>
                        <div className={style.baseInfo}>
                            <div className={style.title}>
                                <div className={style.line}/>
                                <div>学生目前所受资助情况</div>
                            </div>
                            {
                                data.supportList.map(item => (
                                    <div className={style.formContent} key={item.id}>
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span className={style.required}>获助时间</span>
                                                </div>
                                            }
                                            className={`${style.cell}`}
                                        >
                                            <Input style={{textAlign: 'right'}}
                                                   value={item.huoqushijian}
                                                   disabled={true}/>
                                        </Cell>
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span className={style.required}>资助名称</span>
                                                </div>
                                            }
                                            className={`${style.cell}`}
                                        >
                                            <Input style={{textAlign: 'right'}}
                                                   value={item.zizhumingcheng}
                                                   disabled={true}/>
                                        </Cell>
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                            <span className={style.required}>资金来源<span
                                                className={style.beizhu}>（政府/学校/社会）</span></span>
                                                </div>
                                            }
                                            className={`${style.cell}`}
                                            leftClassName={style.large}
                                        >
                                            <Input style={{textAlign: 'right'}}
                                                   value={item.zijinlaiyuan}
                                                   disabled={true}/>
                                        </Cell>
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                            <span className={style.required}>资助金额<span
                                                className={style.beizhu}>（元/年）</span></span>
                                                </div>
                                            }
                                            className={`${style.cell}`}
                                        >
                                            <Input style={{textAlign: 'right'}}
                                                   value={item.zizhujine}
                                                   disabled={true}/>
                                        </Cell>
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                            <span className={style.required}>资助期限<span
                                                className={style.beizhu}>（年）</span></span>
                                                </div>
                                            }
                                            className={`${style.cell}`}
                                        >
                                            <Input style={{textAlign: 'right'}}
                                                   value={item.zizhuqixian}
                                                   disabled={true}/>
                                        </Cell>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                    }

                    {/*证明材料上传*/}
                    {(data.jiandangpingkunsheng === "1" || data.zuidishenghuobaozhang === "1" || data.tekunsheng === "1" || data.gucanxuesheng === "1" || data.lieshizinv === "1" || data.canjirenzinv === "1") &&
                    <>
                        <div className={style.divided}/>
                        <div className={style.baseInfo}>
                            <div className={style.title}>
                                <div className={style.line}/>
                                <div>证明材料上传</div>
                            </div>
                            <div className={style.formContent}>
                                {data.jiandangpingkunsheng === "1" &&
                                <Cell
                                    title={
                                        <div className={`${style.name} ${style.all}`}>
                                            <span>建档立卡贫困家庭学生证明材料<span className={style.beizhu}>（如符合但无证书，请手写材料签字拍照上传）</span></span>
                                        </div>
                                    }
                                    className={ClassName(style.cell, style.textArea)}
                                    leftClassName={style.large}
                                >
                                    <div className={style.picContainer}>
                                        {jdlkList.length !== 0 && jdlkList.map((item, index) =>
                                            <div className={style.picItem} key={index} onClick={() => {setJdlkVisible(true); setPictureIndex1(index)}}>
                                                <img src={item} alt="" className={style.img}/>
                                            </div>
                                        )}
                                    </div>
                                </Cell>
                                }

                                {data.zuidishenghuobaozhang === "1" &&
                                <Cell
                                    title={
                                        <div className={`${style.name} ${style.all}`}>
                                            <span>最低生活保障家庭学生证明材料<span className={style.beizhu}>（如符合但无证书，请手写材料签字拍照上传）</span></span>
                                        </div>
                                    }
                                    className={ClassName(style.cell, style.textArea)}
                                    leftClassName={style.large}
                                >
                                    <div className={style.picContainer}>
                                        {zdshList.length !== 0 && zdshList.map((item, index) =>
                                            <div className={style.picItem} key={index} onClick={() => {setZdshVisible(true); setPictureIndex2(index)}}>
                                                <img src={item} alt="" className={style.img}/>
                                            </div>
                                        )}
                                    </div>
                                </Cell>
                                }

                                {data.tekunsheng === "1" &&
                                <Cell
                                    title={
                                        <div className={`${style.name} ${style.all}`}>
                                        <span>特困供养学生证明材料<span className={style.beizhu}>（如符合但无证书，请手写材料签字拍照上传）</span></span>
                                        </div>
                                    }
                                    className={ClassName(style.cell, style.textArea)}
                                    leftClassName={style.large}
                                >
                                    <div className={style.picContainer}>
                                        {tkgyList.length !== 0 && tkgyList.map((item, index) =>
                                            <div className={style.picItem} key={index} onClick={() => {setTkgyVisible(true); setPictureIndex3(index)}}>
                                                <img src={item} alt="" className={style.img}/>
                                            </div>
                                        )}
                                    </div>
                                </Cell>
                                }

                                {data.gucanxuesheng === "1" &&
                                <Cell
                                    title={
                                        <div className={`${style.name} ${style.all}`}>
                                            <span>孤残学生证明材料<span className={style.beizhu}>（如符合但无证书，请手写材料签字拍照上传）</span></span>
                                        </div>
                                    }
                                    className={ClassName(style.cell, style.textArea)}
                                    leftClassName={style.large}
                                >
                                    <div className={style.picContainer}>
                                        {gcxsList.length !== 0 && gcxsList.map((item, index) =>
                                            <div className={style.picItem} key={index} onClick={() => {setGcxsVisible(true); setPictureIndex4(index)}}>
                                                <img src={item} alt="" className={style.img}/>
                                            </div>
                                        )}
                                    </div>
                                </Cell>
                                }

                                {data.lieshizinv === "1" &&
                                <Cell
                                    title={
                                        <div className={`${style.name} ${style.all}`}>
                                        <span>烈士子女学生证明材料<span className={style.beizhu}>（如符合但无证书，请手写材料签字拍照上传）</span></span>
                                        </div>
                                    }
                                    className={ClassName(style.cell, style.textArea)}
                                    leftClassName={style.large}
                                >
                                    <div className={style.picContainer}>
                                        {lszvList.length !== 0 && lszvList.map((item, index) =>
                                            <div className={style.picItem} key={index} onClick={() => {setLszvVisible(true); setPictureIndex5(index)}}>
                                                <img src={item} alt="" className={style.img}/>
                                            </div>
                                        )}
                                    </div>
                                </Cell>
                                }

                                {data.canjirenzinv === "1" &&
                                <Cell
                                    title={
                                        <div className={`${style.name} ${style.all}`}>
                                        <span>家庭经济困难残疾学生及残疾人子女学生证明材料 <span className={style.beizhu}>（如符合但无证书，请手写材料签字拍照上传）</span></span> </div>
                                    }
                                    className={ClassName(style.cell, style.textArea)}
                                    leftClassName={style.large}
                                >
                                    <div className={style.picContainer}>
                                        {jtjjknList.length !== 0 && jtjjknList.map((item, index) =>
                                            <div className={style.picItem} key={+index} onClick={() => {setJtjjknVisible(true); setPictureIndex6(index)}}>
                                                <img src={item} alt="" className={style.img}/>
                                            </div>
                                        )}
                                    </div>
                                </Cell>
                                }
                            </div>
                        </div>
                    </>
                    }
                </div>
                <ImagePreview
                    visible={jdlkVisible}
                    images={jdlkList}
                    onClose={() => setJdlkVisible(false)}
                    activeIndex={pictureIndex1}
                />
                <ImagePreview
                    visible={zdshVisible}
                    images={zdshList}
                    onClose={() => setZdshVisible(false)}
                    activeIndex={pictureIndex2}
                />
                <ImagePreview
                    visible={tkgyVisible}
                    images={tkgyList}
                    onClose={() => setTkgyVisible(false)}
                    activeIndex={pictureIndex3}
                />
                <ImagePreview
                    visible={gcxsVisible}
                    images={gcxsList}
                    onClose={() => setGcxsVisible(false)}
                    activeIndex={pictureIndex4}
                />
                <ImagePreview
                    visible={lszvVisible}
                    images={lszvList}
                    onClose={() => setLszvVisible(false)}
                    activeIndex={pictureIndex5}
                />
                <ImagePreview
                    visible={jtjjknVisible}
                    images={jtjjknList}
                    onClose={() => setJtjjknVisible(false)}
                    activeIndex={pictureIndex6}
                />
                <div className={style.preview} onClick={handleClose}>关闭预览</div>
            </div>
        </div>
    )
}
