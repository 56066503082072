import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import ProgressBar from "./components/ProgressBar";
import BaseInfo from "./components/BaseInfo";
import FamilyInfo from "./components/FamilyInfo";
import PersonEconomic from "./components/PersonEconomic";
import useRequest from "../../Utils/useRequest";
import {getPoor, viewPoor, getIsPoor, getPoorFlag} from "../../Service/GreenChannel";
import useLoading from "../../Utils/useLoading";
import ConfirmSubmit from "./components/ConfirmSubmit";
import ProveUpload from "./components/ProveUpload";
import BottomPop from "../../Components/BottomPop";
import {Button} from "zarm";
import ApplyRecord from "./components/ApplyRecord";
import Funded from "./components/Funded";
import {RouteComponentProps, useHistory} from "react-router-dom";
import _ from 'underscore';
import SubmitDetail from "./components/ConfirmSubmit/components/SubmitDetail";
import moment from "moment";
import {getIndexProjectName, ProjectName} from "../../Service/Home";
import {useTitle} from "react-use";

export default function PoorStu({match}: RouteComponentProps<{step: string; isShow: string}>) {

    useTitle("绿色通道-家庭经济困难申请", {restoreOnUnmount: true});

    const step = parseInt(match.params.step) || 1;
    const isShow = match.params.isShow;

    const [refresh, setRefresh] = useState(false);
    const [data, {loading}, updateViewPoor] = useRequest(() => viewPoor(), {}, [refresh]);
    const [poorData, {loading: poorDataLoading}, updatePoor] = useRequest(() => getPoor());
    const [PoorFlagData, {loading:poorFlagLoading}, updatePoorFlag] = useRequest(() => getPoorFlag());//查看家庭经济困难各种填写flag查看

    const [indexProject, {loading: indexProjectLoading}] = useRequest(() => getIndexProjectName());
    const [isPoor, {loading:isPoorLoading}] = useRequest(() => getIsPoor());//查看是否填写过家庭经济困难
    const [step2Font, setStep2Font] = useState("学院审核");
    const [step3Font, setStep3Font] = useState("学校认定");
    const [step1Date, setStep1Date] = useState("");
    const [step2Date, setStep2Date] = useState("");
    const [step3Date, setStep3Date] = useState("");
    const [popIsShow, setPopIsShow] = useState(true);//提示弹窗
    const [lvsetongdao, setLvsetongdao] = useState<ProjectName>();
    const [lvsetongdaoguanbishijian, setLvsetongdaoguanbishijian] = useState<ProjectName>();
    const [lvsetongdaoxueyuanshenhekaishishijian, setLvsetongdaoxueyuanshenhekaishishijian] = useState<ProjectName>();
    const [lvsetongdaoxueyanshenhejieshushijian, setLvsetongdaoxueyanshenhejieshushijian] = useState<ProjectName>();
    const [lvsetongdaoxuexiaorendingkaishishijian, setLvsetongdaoxuexiaorendingkaishishijian] = useState<ProjectName>();
    const [lvsetongdaoxuexiaorendingjieshushijian, setLvsetongdaoxuexiaorendingjieshushijian] = useState<ProjectName>();

    const history = useHistory();
    const [show, setShow] = useState(false);
    useLoading([loading, poorDataLoading, indexProjectLoading,isPoorLoading,poorFlagLoading]);

    useEffect(() => {
        indexProject?.map((item) => {
            if(item.indexName == "lvsetongdao") {
                setLvsetongdao(item);
            }
            if(item.indexName == "lvsetongdaoguanbishijian") {
                setLvsetongdaoguanbishijian(item);
            }
            if(item.indexName == "lvsetongdaoxueyuanshenhekaishishijian") {
                setLvsetongdaoxueyuanshenhekaishishijian(item);
            }
            if(item.indexName == "lvsetongdaoxueyanshenhejieshushijian") {
                setLvsetongdaoxueyanshenhejieshushijian(item);
            }
            if(item.indexName == "lvsetongdaoxuexiaorendingkaishishijian") {
                setLvsetongdaoxuexiaorendingkaishishijian(item);
            }
            if(item.indexName == "lvsetongdaoxuexiaorendingjieshushijian") {
                setLvsetongdaoxuexiaorendingjieshushijian(item);
            }
        })
    },[indexProject]);


    useEffect(() => {
        if(poorData && !(_.isEmpty(poorData))) {

            if(poorData.firstFlag === '1'){
                setStep2Font("审核完成")
            }
            if(poorData.secondFlag === '1'){
                setStep3Font("认定完成")
            }
        }
    }, [poorData]);

    useEffect(() => {
        if(match.params.step) {
            onClick(parseInt(match.params.step));
        }else if(PoorFlagData?.submitFlag == "1") {//点过提交直接到审核页面
            if(isShow === '1') {   //isShow 为了查看家庭成员点击返回后弹窗仍显示
                history.replace(`/poor/7/${isShow}`);
            }else {
                history.replace(`/poor/7`);
            }
        }
    },[PoorFlagData])

    useEffect(() => {
        if(lvsetongdao && lvsetongdaoguanbishijian && lvsetongdaoxueyuanshenhekaishishijian && lvsetongdaoxueyanshenhejieshushijian &&lvsetongdaoxuexiaorendingkaishishijian && lvsetongdaoxuexiaorendingjieshushijian){
            setStep1Date(`${moment(lvsetongdao.date).format("M.D")}-${moment(lvsetongdaoguanbishijian.date).format("M.D")}`)
            setStep2Date(`${moment(lvsetongdaoxueyuanshenhekaishishijian.date).format("M.D")}-${moment(lvsetongdaoxueyanshenhejieshushijian.date).format("M.D")}`)
            setStep3Date(`${moment(lvsetongdaoxuexiaorendingkaishishijian.date).format("M.D")}-${moment(lvsetongdaoxuexiaorendingjieshushijian.date).format("M.D")}`)
        }
    },[lvsetongdao,lvsetongdaoguanbishijian,lvsetongdaoxueyuanshenhekaishishijian,lvsetongdaoxueyanshenhejieshushijian,lvsetongdaoxuexiaorendingkaishishijian,lvsetongdaoxuexiaorendingjieshushijian])



    function onClick(mark: number) {
        const step = mark || 1;
        if(isShow === '1') {  //isShow 为了查看家庭成员点击返回后弹窗仍显示
            history.push(`/poor/${step}/${isShow}`);
        }else {
            history.push(`/poor/${step}`);
        }
    }


    return (
        <div className={style.root}>
            <div className={style.wrapper}>
                {lvsetongdaoxueyuanshenhekaishishijian && lvsetongdaoxuexiaorendingkaishishijian &&
                <ProgressBar firstFlag={lvsetongdaoxueyuanshenhekaishishijian.date}  secondFlag={lvsetongdaoxuexiaorendingkaishishijian.date} stepTnfo={{step1Font:"申请", step1Date:step1Date, step2Font:step2Font, step2Date:step2Date, step3Font:step3Font, step3Date:step3Date}}/>
                }
            </div>
            {step !== 7 && (
                <div className={style.tags}>
                    <div className={`${style.tag} ${step === 1 ? style.current : ''}`}>基本情况</div>
                    <div className={`${style.tag} ${style.large} ${step === 2 ? style.current : ''}`}>家庭经济情况</div>
                    <div className={`${style.tag} ${style.large} ${step === 3 ? style.current : ''}`}>个人经济情况</div>
                    <div className={`${style.tag} ${step === 4 ? style.current : ''}`}>已受资助</div>
                    <div className={`${style.tag} ${style.large} ${step === 5 ? style.current : ''}`}>证明材料上传</div>
                    <div className={`${style.tag} ${step === 6 ? style.current : ''}`}>确认提交</div>
                </div>
            )}
            {step === 1 && data && <BaseInfo data={data} onHandleMark={(id:number)=>{history.push(`/poor/${id}`);}} updateFn={updateViewPoor}/>}
            {step === 2 && data && <FamilyInfo data={data} onHandleMark={(id:number)=>{history.push(`/poor/${id}`);}} updateFn={updateViewPoor}/>}
            {step === 3 && data && <PersonEconomic data={data} onHandleMark={(id:number)=>{history.push(`/poor/${id}`);}} updateFn={updateViewPoor}/>}
            {step === 4 && data && <Funded data={data} onHandleMark={(id:number)=>{history.push(`/poor/${id}`);}} updateFn={updateViewPoor}/>}
            {step === 5 && data && <ProveUpload data={data} onHandleMark={(id:number)=>{history.push(`/poor/${id}`);}} updateFn={updateViewPoor}/>}
            {step === 6 && data && PoorFlagData && <ConfirmSubmit onHandleMark={(id:number)=>{history.push(`/poor/${id}`);}} onView={() => setShow(true)} updateFn={updatePoor}/>}
            {step === 7 && poorData && lvsetongdaoguanbishijian && <div className={`${style.tags} ${style.record}`}>
                <ApplyRecord isUser={lvsetongdaoguanbishijian?.isUser} poorData={poorData} onHandleMark={(id:number)=>{history.push(`/poor/${id}`);}} onView={() => setShow(true)}/>
            </div>}


            {/*未填绿色通道弹窗*/}
            {data && isPoor?.flag == "0" && popIsShow &&
            <BottomPop title={"温馨提示"}>
                <div className={style.popContent}>
                    <div className={style.detailList}>
                        <div className={style.detailRow}>
                            “绿色通道”是为确保我校家庭经济困难新生顺利入学最直接最有效的措施，对因家庭经济困难无法按时缴纳学杂费用的新生，批准暂缓缴纳学杂费用并发放爱心大礼包。同时对灾区学生、特困学生、孤儿等特殊群体进行重点帮扶。
                        </div>
                        <div className={style.detailRow}>
                            “绿色通道”现场，勤工助学协会为家庭经济困难的新生分发的“爱心大礼包”包含了丰富而贴心的生活必需品，各种各样的生活物品一应俱全，这些物品的准备既是勤工助学协会对于大一新生的倾情帮助，也是对于他们努力学习的期望。
                        </div>
                    </div>
                    <div className={style.popButtonDiv}>
                        <Button theme="primary" className={style.popButton} onClick={() => setPopIsShow(false)}>家庭经济困难申请</Button>
                    </div>
                </div>
            </BottomPop>
            }

            {(isShow ==='1' || show )&& data && (
                <div style={{position: "absolute", top: "0"}}>
                    <SubmitDetail onClick={(val: boolean) => setShow(val)} data={data} step={step}/>
                </div>
            )}
        </div>
    );
}
