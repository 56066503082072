import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import HeadInfo from "./components/HeadInfo";
import Admission from "./components/Admission";
import Notice from "./components/Notice";
import Guide from "./components/Guide";
import ButtonList from "./components/ButtonList";
import ReportStep from "./components/ReportStep";
import {getHomeStudentInfo, getIndexProjectName, getNoticeList, getSignQRFlag} from "../../Service/Home";
import Storage from "../../Utils/Storage";
import useRequest from "../../Utils/useRequest";
import Toast from "./components/Toast";
import {useTitle} from "react-use";
import {Button} from "zarm";
import { useHistory } from "react-router-dom";
import ZaixianbaodaoToast from "./components/ZaixianbaodaoToast";
import ZaixianReport from "./components/ZaixianReport";

export default function Home() {
    useTitle("首页", {restoreOnUnmount: true});
    const [infoData, {loading}] = useRequest(() => getHomeStudentInfo());
    const [admissionData, {loading:admissionLoading}] = useRequest(() => getIndexProjectName());
    const [noticeList] = useRequest(() => getNoticeList());
    const [toastIsShow, setToastIsShow] = useState(true);
    const [qrFlag] = useRequest(() => getSignQRFlag());//是否扫过二维码（0.否，1.是）
    const [isUser, setIsUser] = useState("0");//是否显示报到流程，根据入学开启状态
    const [zxbdUser, setZxbdUser] = useState("0");//在线报道状态开启状态
    const history = useHistory();

    useEffect(() => {
        if(admissionData) {
            admissionData?.map((item) => {
                if(item.indexName == "baodaoliuchengkaishi") {
                    setIsUser(item.isUser);
                }
                if(item.indexName == "onlineReportTime") {
                    setZxbdUser(item.isUser);
                }
            })
        }
    },[admissionData])

    //关闭录取通知弹窗
    function closeToast (){
        setToastIsShow(false);
        Storage.set("firstClick","false");
    }

    function logout() {
        Storage.remove("token");
        Storage.remove("refreshToken");
        Storage.remove("personType");
        window.location.href="https://sso.dufe.edu.cn/auth/cas/logout";
    }

    return (
        <div className={style.root}>
            <div className={style.indexDiv}>
                {/*个人信息start*/}
                <HeadInfo pic={infoData?.pic} name={infoData?.name || ""} college={infoData?.college} profession={infoData?.major} eduStatus={infoData?.education} employeeNo={infoData?.employeeNo}
                classStatus={infoData?.classFlag} roomStatus={infoData?.roomFlag} reportStatus={infoData?.reportFlag} reportTime={infoData?.baodaoriqi}/>
                {/*报到流程start*/}
                {
                    infoData && isUser == "1"
                    && <ReportStep qrFlag={qrFlag?.signQRFlag} reportStatus={infoData?.reportFlag} giftStatus={infoData?.giftFlag} />
                }
                {zxbdUser === "1" &&
                    <ZaixianReport reportStatus={infoData?.onlineReportFlag}/>
                }
                {/*按钮start*/}
                {
                    admissionData && <Admission name={infoData?.name} rxStatus={infoData?.registerFlag} jfStatus={infoData?.payFlag} employeeNo={infoData?.employeeNo}
                                                jtStatus={infoData?.trafficFlag} admissionData={admissionData} delayedFlag={infoData?.delayedFlag}/>
                }
                {/*通知公告*/}
                <Notice dataList={noticeList || []}/>
                {/*入学指南start*/}
                <Guide/>
                {/*了解东财start*/}
                <ButtonList/>
                {/*录取通知弹窗：只有第一次点击，学生为本科生才显示*/}
                {
                    toastIsShow && Storage.get("firstClick") != "false" &&(infoData && infoData.education == "1") &&
                    <Toast onClose={closeToast}/>
                }
                {/*在线报到状态开启，并且未报到*/}
                {zxbdUser === "1" && infoData?.onlineReportFlag === "0" &&
                    <ZaixianbaodaoToast/>
                }
                <Button className={style.logout} onClick={logout}>退出当前登录</Button>
            </div>
        </div>
    );
}
