import React from "react";
import style from "./index.module.scss";

interface Props {
    children: React.ReactNode;
}

export default function PaneWithAngle({children}: Props) {

    return (
        <div className={style.root}>
            {children}
        </div>
    );
}
