import React from "react";
import style from "./index.module.scss";
import {Button} from "zarm";
import step1 from "./img/step1.png";
import step2 from "./img/step2.png";
import step3 from "./img/step3.png";
import alreadyShadow from "./img/alreadyShadow.png";
import {useHistory} from "react-router-dom";

interface Props {
    reportStatus?:string;
    giftStatus?:string;
    qrFlag?:string;
}

export default function ReportStep({qrFlag, reportStatus, giftStatus}:Props) {

    const history = useHistory();

    return (
        <div className={style.container}>
            {/*三步先不用勿删*/}
            {/*<div className={style.reportStepDiv}>*/}
            {/*    /!*第一步*!/*/}
            {/*    <div className={style.step1Div}>*/}
            {/*        <div className={style.stepImgDiv}>*/}
            {/*            /!*已签到未签到状态显示隐藏shadowImg*!/*/}
            {/*            <img className={style.stepImg} src={step1}/>*/}
            {/*            {*/}
            {/*                reportStatus && reportStatus =='1' &&*/}
            {/*                <img className={style.shadowImg} src={alreadyShadow}/>*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*        <div className={style.stepFont}>微信扫描校园内迎新大屏幕</div>*/}
            {/*        /!*已签到未签到状态显示隐藏按钮*!/*/}
            {/*        {*/}
            {/*            reportStatus && reportStatus =='0' &&*/}
            {/*            <Button theme="primary" className={style.button}>去签到</Button>*/}
            {/*        }*/}
            {/*        {*/}
            {/*            reportStatus && reportStatus =='1' &&*/}
            {/*            <Button theme="primary" className={style.alreadyButton}>已签到</Button>*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*    /!*第二步*!/*/}
            {/*    <div className={style.step2Div}>*/}
            {/*        <div className={style.stepImgDiv}>*/}
            {/*            /!*已签到未签到状态显示隐藏shadowImg*!/*/}
            {/*            <img className={`${style.stepImg} ${style.step2Img}`} src={step2}/>*/}
            {/*        </div>*/}
            {/*        <div className={style.stepFont}>前往报到处书面签到领取宿舍条</div>*/}
            {/*    </div>*/}
            {/*    /!*第三步*!/*/}
            {/*    <div className={style.step3Div}>*/}
            {/*        <div className={style.stepImgDiv}>*/}
            {/*            /!*已签到未签到状态显示隐藏shadowImg*!/*/}
            {/*            <img className={style.stepImg} src={step3}/>*/}
            {/*            {*/}
            {/*                reportStatus && giftStatus =='1' &&*/}
            {/*                <img className={style.shadowImg} src={alreadyShadow}/>*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*        <div className={style.stepFont}>微信扫描绿色通道二维码完成领取</div>*/}
            {/*        /!*已签到未签到状态显示隐藏按钮*!/*/}
            {/*        {*/}
            {/*            reportStatus && giftStatus =='0' &&*/}
            {/*            <Button theme="primary" className={style.button}>去领取</Button>*/}
            {/*        }*/}
            {/*        {*/}
            {/*            reportStatus && giftStatus =='1' &&*/}
            {/*            <Button theme="primary" className={style.alreadyButton}>已领取</Button>*/}
            {/*        }*/}

            {/*    </div>*/}
            {/*</div>*/}
            <div className={style.reportStepDiv}>
                {/*第一步*/}
                <div className={style.step1Div}>
                    <div className={style.stepImgDiv}>
                        {/*已签到未签到状态显示隐藏shadowImg*/}
                        <img className={style.stepImg} src={step1}/>
                        {
                            reportStatus && reportStatus =='1' &&
                            <img className={style.shadowImg} src={alreadyShadow}/>
                        }
                    </div>
                    <div className={style.stepFont}>微信扫描报到二维码</div>
                    {/*已签到未签到状态显示隐藏按钮*/}
                    {
                        reportStatus && reportStatus =='0' && qrFlag == '1' &&
                        <Button theme="primary" className={style.button} onClick={() => history.push("/electronicPage")}>去签到</Button>
                    }
                    {
                        reportStatus && reportStatus =='1' &&
                        <Button theme="primary" className={style.alreadyButton}>已签到</Button>
                    }
                </div>
                {/*第二步*/}
                <div className={style.step2Div}>
                    <div className={style.stepImgDiv}>
                        {/*已签到未签到状态显示隐藏shadowImg*/}
                        <img className={`${style.stepImg} ${style.step2Img}`} src={step2}/>
                    </div>
                    <div className={style.stepFont}>前往报到处书面签到领取宿舍条</div>
                </div>
            </div>
        </div>
    );
}
