import React, {Suspense} from "react";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import {RecoilRoot} from "recoil";
import NoticeDetail from "./Page/Notice/NoticeDetail";
import Dormitory from "./Page/Dormitory";
import ClassDetail from "./Page/MyClass/ClassDetail";
import Home from "./Page/Home";
import AdmissionLetter from "./Page/AdmissionLetter";
import Enrollment from "./Page/Enrollment/EnrollmentIndex";
import PhotoCollection from "./Page/Enrollment/PhotoCollection";
import GiftBag from "./Page/GreenChannel/GiftBag";
import ElectronicSignIn from "./Page/CheckIn/ElectronicSignIn";
import ZaixianbaodaoSignIn from "./Page/CheckIn/ZaixianbaodaoSignIn";
import CheckSuccess from "./Page/CheckIn/ElectronicSignIn/CheckSuccess";
import ZaixianbaodaoSuccess from "./Page/CheckIn/ZaixianbaodaoSignIn/CheckSuccess";
import Family from "./Page/CheckIn/Family";
import Transportation from "./Page/Transportation";
import Payment from "./Page/Payment/PaymentPage"
import Member from "./Page/CheckIn/Family/Detail";
import TuitionWaiver from "./Page/Payment/TuitionWaiver";
import StudentLoans from "./Page/Payment/StudentLoans";
import PoorStu from "./Page/PoorStu";
import Pdf from "./Page/Home/components/Pdf";
import Storage from "./Utils/Storage";
import {Loading} from "zarm";

const Login = React.lazy(() => import("./Page/Auth/Login"));
const StuList = React.lazy(() => import("./Page/MyClass/StuList"));
const Register = React.lazy(() => import("./Page/CheckIn/Register"));
const PostponementForm = React.lazy(() => import("./Page/Postponement/Form"));
const PostponementDetail = React.lazy(() => import("./Page/Postponement/Detail"));
const NoticeList = React.lazy(() => import("./Page/Notice/NoticeList"));
const GreenTheEnd = React.lazy(() => import("./Page/GreenTheEnd"));
const AdmissionGuide = React.lazy(() => import("./Page/AdmissionGuide"));
// const Home = React.lazy(() => import("./Page/Home"));

function App() {
    if(Storage.get("personType") === "1") {
        window.location.href = "https://stu.dufe.edu.cn/counsellor";
        return <Loading visible={true} />
    }

    return (
        <RecoilRoot>
            <Suspense fallback={<div />}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" component={Home} exact/>
                        <Route path="/notice" component={NoticeList} exact/>
                        <Route path="/notice/detail/:id?" component={NoticeDetail} exact/>
                        <Route path="/dormitory" component={Dormitory} exact/>
                        <Route path="/myclass" component={ClassDetail} exact/>
                        <Route path="/stulist/:grade/:classNo/:studentNum/:college/:major/:bianhao" component={StuList} exact />
                        <Route path="/login" component={Login} exact />
                        <Route path="/register" component={Register} exact />
                        <Route path="/family" component={Family} exact />
                        <Route path="/member/:poor?/:id?/:isPreview?/:step?" component={Member}></Route>
                        <Route path="/admissionLetter" component={AdmissionLetter} exact/>
                        <Route path="/enrollment" component={Enrollment} exact/>
                        <Route path="/photoCollection" component={PhotoCollection} exact/>
                        <Route path="/giftBag" component={GiftBag} exact/>
                        <Route path="/electronicPage" component={ElectronicSignIn} exact/>
                        <Route path="/zaixianbaodaoSignIn" component={ZaixianbaodaoSignIn} exact/>
                        <Route path="/checkSuccess" component={CheckSuccess} exact/>
                        <Route path="/zaixianbaodaoSuccess" component={ZaixianbaodaoSuccess} exact/>
                        <Route path="/transportation/:name" component={Transportation} exact/>
                        <Route path="/payment" component={Payment} exact/>
                        <Route path="/tuitionWaiver/:orphanFlag?" component={TuitionWaiver} exact/>
                        <Route path="/studentLoans/:name?/:employeeNo?/:loanFlag?" component={StudentLoans} exact/>
                        <Route path = "/postponement/form/:name/:employeeNo" component={PostponementForm} exact/>
                        <Route path = "/postponement/detail/:name/:employeeNo" component={PostponementDetail} exact/>
                        <Route path = "/poor/:step?/:isShow?" component={PoorStu} exact/>
                        <Route path = "/greenTheEnd" component={GreenTheEnd} exact/>
                        <Route path = "/pdf" component={Pdf}></Route>
                        <Route path = "/admissionGuide" component={AdmissionGuide}></Route>
                        <Redirect to="/" />
                    </Switch>
                </BrowserRouter>
            </Suspense>
        </RecoilRoot>
    );
}

export default App;
