import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import useRequest from "../../Utils/useRequest";
import {getHomeStudentInfo} from "../../Service/Home";
import {useTitle} from "react-use";

export default function AdmissionLetter() {
    useTitle("录取通知书", {restoreOnUnmount: true});
    const [infoData, {loading}] = useRequest(() => getHomeStudentInfo());

    // 日期格式化成中文
    function convert(dateStr:string, dateType:string) {

        let dict:any = {
            "0": "零",
            "1": "一",
            "2": "二",
            "3": "三",
            "4": "四",
            "5": "五",
            "6": "六",
            "7": "七",
            "8": "八",
            "9": "九",
            "01": "一",
            "02": "二",
            "03": "三",
            "04": "四",
            "05": "五",
            "06": "六",
            "07": "七",
            "08": "八",
            "09": "九",
            "10": "十",
            "11":"十一",
            "12":"十二",
            "13":"十三",
            "14":"十四",
            "15":"十五",
            "16":"十六",
            "17":"十七",
            "18":"十八",
            "19":"十九",
            "20":"二十",
            "21":"二十一",
            "22":"二十二",
            "23":"二十三",
            "24":"二十四",
            "25":"二十五",
            "26":"二十六",
            "27":"二十七",
            "28":"二十八",
            "29":"二十九",
            "30":"三十",
            "31":"三十一"

        };
        console.log(dateStr.substring(0,10))
        let date = dateStr.substring(0,10).split('-'),
            yy = date[0],
            mm = date[1],
            dd = date[2];

        let yearStr = dict[yy[0]] + dict[yy[1]] + dict[yy[2]] + dict[yy[3]] + '年',
            monthStr = dict[''+Number(mm)] + '月',
            dayStr = dict[dd]+ '日';

        console.log(yearStr +monthStr +dayStr);

        if(dateType == "mmdd") {
            return monthStr +dayStr;
        }else {
            return yearStr +monthStr +dayStr;
        }


    }

    return (
        <div className={style.root}>
            <div className={style.admissionLetterDiv}>
                <div className={style.dufeCode}>{infoData?.NoticeNo}</div>
                <div className={style.info}>
                    <div className={style.fontRow}>
                        <span className={style.rowLabel}>姓名：</span><span className={style.rowFont}>{infoData?.name}</span>
                    </div>
                    <div className={style.fontRow}>
                        <span className={style.rowLabel}>考生号：</span><span className={style.rowFont}>{infoData?.examNum}</span>
                    </div>
                    <div className={style.fontRow}>
                        <span className={style.rowLabel}>身份证号：</span><span className={style.rowFont}>{infoData?.cardId}</span>
                    </div>
                    <div className={style.fontRow}>
                        <span className={style.rowLabel}>学号：</span><span className={style.rowFont}>{infoData?.employeeNo}</span>
                    </div>
                </div>
                <div className={style.picImg}>
                    <img src={infoData?.gaokaoPic}/>
                </div>
                <div className={style.detail}>
                    经东北财经大学招生委员会审核，批准您入校<span className={style.fontRed}>{infoData?.college}</span>本科<span className={style.fontRed}>{infoData?.major}</span>学习，
                    请持此通知书于{ infoData && <span>{convert(infoData?.baodaoriqi,"mmdd")}</span>}到学校报到。
                </div>
                <div className={style.signature}>
                    <span>校长：</span>
                    <img src={require("./img/font.png").default}/>
                </div>
                <div className={style.dateDiv}>
                    <div className={style.name}>东北财经大学</div>
                    {
                        infoData &&
                        <div className={style.date}>{convert(infoData?.tongzhishufafangriqi,"yyyymmdd")}</div>
                    }
                </div>
            </div>
        </div>
    );
}
