import React, {Children} from "react";
import style from "./index.module.scss";

interface IPorps{
    children: React.ReactElement;
    title?: React.ReactElement | string;
    className?: string;
    leftClassName?: string;
    rightClassName?: string;
}

export default function Cell({children, title, className, leftClassName, rightClassName}: IPorps){
    return (
        <div className={`${style.root} ${className}`}>
            <div className={`${style.left} ${leftClassName}`}>{title}</div>
            <div className={`${style.right} ${rightClassName}`}>
                {children}
            </div>
        </div>
    );
}
