import Request from "Utils/Requests";
import qs from "qs";
import {StudentInfo} from "./CheckIn";


const BaseUrl = process.env.REACT_APP_BASE_URL;
const CmsUrl =  process.env.REACT_APP_CMS_URL;

/**
 * 首页个人信息查询/录取通知书信息查询
 */
export interface TrafficInfo {
    pickTime:string;        //接站时间code
    pickLocal:string;       //接站地点code
    phone:string;           //联系电话
    familyNum:string;       //陪同家属人数
    name:string;            //姓名
    arrivalData:string;     //到校日期
    trafficMode:string;     //交通方式code
    trafficNum:string;      //车次/航班
    outPlace:string;        //出发地
    statusFlag:string;      //是否接站（0.否，1.是）
    openFlag:string;        //是否公开（0.否，1.是）


}

export function getTrafficInfo() {
    return Request.get<SuccessData<TrafficInfo>>(`${BaseUrl}/zhxg/getTrafficInfo`)
        .then(res => res.data.data);
}

/**
 * 填报交通信息
 */

export function insertTrafficInfo(params: TrafficInfo) {
    return Request.post<SuccessData<TrafficInfo>>(`${process.env.REACT_APP_BASE_URL}/zhxg/insertTrafficInfo`, qs.stringify({...params}))
        .then(data => data.data.data);
}

/**
 * 修改交通信息
 */

export function updateTrafficInfo(params: TrafficInfo) {
    return Request.post<SuccessData<TrafficInfo>>(`${process.env.REACT_APP_BASE_URL}/zhxg/updateTrafficInfo`, qs.stringify({...params}))
        .then(data => data.data.data);
}

/**
 * 查看结伴同行
 */
export interface TogetherInfo {
    name:string;//姓名
    personId:string;//id
    pic:string;//头像
    outPlace:string;//出发地
    sex:string;//性别：1男2女
}

export function getGoTogether() {
    return Request.get<SuccessData<TogetherInfo[]>>(`${BaseUrl}/zhxg/getGoTogether`)
        .then(res => res.data.data);
}

/**
 * 查看结伴同行
 */
export interface TogetherByIdInfo {
    pic:string;//头像
    pickTime:string;//接站时间code
    pickLocal:string;//接站地点code
    phone:string;//联系电话
    familyNum:string;//陪同家属人数
    name:string;//姓名
    arrivalData:string;//到校日期
    trafficMode:string;//交通方式code
    trafficNum:string;//车次/航班
    outPlace:string;//出发地
    statusFlag:string;//是否接站（0.否，1.是）
    pickTimeName:string;//接站时间名称
    pickLocalName:string;//接站地点名称
    trafficModeName:string;//交通方式名称
}

export function getGoTogetherById(personId:string) {
    return Request.get<SuccessData<TogetherByIdInfo>>(`${BaseUrl}/zhxg/getGoTogetherById?${qs.stringify({personId})}`)
        .then(res => res.data.data);
}
