import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import {Button, Modal,Toast} from "zarm";
import {
    SupportList,
    addPoorSupport,
    ViewPoor
} from "../../../../Service/GreenChannel";
import Popup from "../../../../Components/Popup";
import {errorMessage} from "../../../../Utils/utils";
import FormRow from "./components/FormRow";
import Empty from "../../../../Components/Empty";
import BtnSubmit from "../../../CheckIn/components/Button";
import _ from "underscore";

interface Idata {
    data:ViewPoor;
    onHandleMark: (id:number)=>void;
    updateFn: () => void;
}

export default function Funded({data, onHandleMark, updateFn}:Idata) {
    const [message, setMessage] = useState('');
    const [successShow, setSuccessShow] = useState(false);//提交成功显示弹窗
    const [arrayList,setArrayList] = useState<SupportList[]>(data.supportList || []);

    useEffect(() => {
        if(message) {
            Modal.alert({
                title: `${message}！`,
                onCancel: () => setMessage('')
            });
        }
    }, [message]);

    async function subject () {
        const res = checkForm();
        if(res) {
            const params = {
                poorSupport: arrayList.filter(item => !(item.id === '' && item.deleteFlag === "1")).map(item => ({
                    ...item,
                    updateFlag:"1",
                    deleteFlag: item.deleteFlag || "0",
                }))
            }
            try {
                await addPoorSupport(params);
                onHandleMark(5);
                updateFn();
                // setSuccessShow(true);
            }catch (e) {
                Toast.show(errorMessage(e));
            }
        }
    }

    function successClose() {
        setSuccessShow(false);
        onHandleMark(6);
    }

    function addArray() {
        const res = checkForm();
        if(res) {

            let arr = arrayList.concat([{
                id:"",//资助id
                huoqushijian:"",//获助时间
                zizhumingcheng:"",//资助名称
                zijinlaiyuan:"",//资金来源（政府/学校/社会)
                zizhujine:"",//资助金额（元/年）
                zizhuqixian:"",//资助期限（年）
                updateFlag:"1",//修改标识（0.不修改，1.要修改）
                deleteFlag:"0",//删除标识（0.不删除，1.要删除）
                uniqueId: _.uniqueId(`${_.now()}_`)
            }]);
            setArrayList(arr);
        }
    }

    function checkForm() {
        return arrayList.filter(item => item.deleteFlag !== '1').every(item => {
           if(item.huoqushijian.length <= 0) {
               setMessage('请选择获助时间');
               return false;
           }
           if(item.zizhumingcheng.length <= 0) {
               setMessage('请填写资助名称');
               return false;
           }
           if(item.zijinlaiyuan.length <= 0) {
               setMessage('请填写资金来源');
               return false;
           }
           if(item.zizhujine.length <= 0) {
               setMessage('请填写资助金额');
               return false;
           }
           if(item.zizhuqixian.length <= 0) {
               setMessage('请填写资助期限');
               return false;
           }
           return true;
        });
    }

    function deleteArray(target: SupportList, index: number) {
        if(arrayList) {
            const res = arrayList.map((item, i) => {
                if(target === item) {
                    return {
                        ...item,
                        deleteFlag: "1"
                    }
                }
                return item;
            });
            setArrayList(res);
        }
    }

    function onChange(name: string, value: string,  uniqueId: string) {
        const res = arrayList.map((item, index) => {
            if(uniqueId === item.uniqueId) {
                return {
                    ...item,
                    [name]: value
                };
            }
            return item;
        });
        setArrayList(res);
    }

    return (
        <div className={style.container}>
            <div className={style.fundedDiv}>
                <div className={style.title}>
                    <div className={style.titleFont}>学生目前所受资助情况<div className={style.addButton} onClick={() => addArray()}>增加</div></div>
                </div>
                {
                    arrayList.filter(item => item.deleteFlag !== "1").map((item,index) => {
                        return <div className={style.formDiv} key={item.uniqueId}>
                            <FormRow onChange={onChange} data={item} uniqueId={item.uniqueId}/>
                            <div className={style.deleteButton} onClick={() => deleteArray(item, index)}>删除</div>
                        </div>
                    })

                }
                {
                    arrayList.filter(item => item.deleteFlag !== "1").length <= 0 && <Empty msg="未受过资助" />
                }
                <div className={style.buttonDiv}>
                    <Button theme="primary" className={style.button} onClick={() => onHandleMark(3)}>上一步</Button>
                    <Button theme="primary" className={style.button} onClick={() =>subject()}>下一步</Button>
                </div>
            </div>
            {successShow && (
                <Popup visible={true} type={"success"} onClose={() => successClose()} title={"提交成功"} notice={"您已成功提交全部证明材料"}/>
            )}
        </div>
    );
}
