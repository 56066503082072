import React, {useState} from "react";
import style from "./index.module.scss";
import {Button, Checkbox, Toast} from "zarm";
import {updatePoorFlag} from "../../../../Service/GreenChannel";
import {errorMessage} from "../../../../Utils/utils";
import {useHistory} from "react-router-dom";

interface ConfirmSubmitProps {
    onHandleMark: (id: number) => void;
    onView: () => void;
    updateFn: () => void;
}

export default function ConfirmSubmit({onHandleMark, onView, updateFn}: ConfirmSubmitProps) {

    const history = useHistory()
    const [checked, setChecked] = useState(false);

    const onChange = () => {
        setChecked(!checked);
    };

    const handlePoorFlag = async () => {

        try{
            await updatePoorFlag();
            Toast.show({
                content: "提交成功", afterClose: () => {
                    updateFn();
                    onHandleMark(7);
                    history.replace('/poor/7')
                }
            });
        }catch (e) {
            Toast.show(errorMessage(e));
        }
    }

    return (
        <>
            <div className={style.root}>
                <div className={style.title}>
                    <div className={style.line}/>
                    <div>预览填写内容</div>
                    <div className={style.buttonDiv}>
                        <Button theme="primary" className={style.button} onClick={() => onHandleMark(5)}>上一步</Button>
                    </div>
                </div>
                <div className={style.preview} onClick={() => onView()}>全屏预览全部</div>
                <div>
                    <Checkbox className={style.tip} onChange={onChange}>本人或法定监护人承诺以上所填写资料真实，如有虚假，愿承担相应责任。</Checkbox>
                </div>
                {checked && <Button theme="primary" className={style.btn} onClick={handlePoorFlag}>提交</Button>}
                {!checked && <Button className={style.preyBtn}>提交</Button>}
            </div>
        </>
    )
}
