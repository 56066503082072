import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom"
import ReportPage from "../../../../Components/ReportPage";
import style from "./index.module.scss";
import {Button, Toast} from "zarm";
import ConfirmToast from "../../../../Components/ConfirmToast"
import useRequest from "../../../../Utils/useRequest";
import {getIndexProjectName, HomeStudentInfo, ProjectName} from "../../../../Service/Home";
import {CheckIn} from "../../../../Service/CheckIn";
import {getEnrollmentStatus} from "../../../../Service/Enrollment";
import {useGeolocation} from 'react-use';
import moment from "moment";
import {errorMessage} from "../../../../Utils/utils";
import dayjs from "dayjs";

interface ElectronicPageProps{
    stuInfo: HomeStudentInfo
}

export default function ElectronicPage({stuInfo}:ElectronicPageProps) {

    const [enrollmentStatus,{loading:enrollmentLoading}] = useRequest(() => getEnrollmentStatus())  //个人信息是否填报
    const [admissionData, {loading:admissionLoading}] = useRequest(() => getIndexProjectName());  //获取报到日期
    const [isOpen, setIsOpen] = useState(false)   //是否开启
    const [isFillIn, setFillIn] = useState(false)  //是否填报入学信息
    const [isRange, setRange] = useState(false)  //是否超出范围
    const [baodaoriqi,setBaodaoriqi] = useState<ProjectName>()

    useEffect(() => {
        admissionData?.map((item) => {
            if(item.indexName == "baodaoriqi") {
                setBaodaoriqi(item);
            }
        })
    },[admissionData])

    const position = useGeolocation();
    const history = useHistory()

    // 经纬度转换成三角函数中度分表形式。
    function rad(d:any) {
        return d * Math.PI / 180.0;
    }

    // 根据经纬度计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
    function getDistance(lat1:number, lng1:number, lat2:number | null, lng2:number | null) {

        const radLat1 = rad(lat1);
        const radLat2 = rad(lat2);
        const a = radLat1 - radLat2;
        const b = rad(lng1) - rad(lng2);
        let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
        s = s * 6378.137;    // 地球半径;
        s = Math.round(s * 10000) / 10000; //输出为公里

        return s;
    }

    // 梁园为中心
    const distance = getDistance(38.889045,121.560311, position.latitude, position.longitude)

    const onHandleCheckIn = async () => {
        if(baodaoriqi?.isUser !== "1") return setIsOpen(true)    // 签到是否开启
        if(stuInfo.education === "2" || stuInfo.education === "3") {//2研究生，3博士生不效验电子头像采集
            if(enrollmentStatus?.infoFlag !== "1" || enrollmentStatus?.familyFlag !== '1')  return setFillIn(true)  //是否填报入学登记
        }else {
            if(enrollmentStatus?.infoFlag !== "1" || enrollmentStatus?.familyFlag !== '1' || enrollmentStatus?.picFlag !== '1')  return setFillIn(true)  //是否填报入学登记
        }

        if(distance > 2) return setRange(true)    // 超出范围
        try{
            const res = await CheckIn();
            Toast.show({content:"签到成功", afterClose:()  => {
                history.push(`/checkSuccess`);
            }});
        }catch (e) {
            Toast.show(errorMessage(e));
        }
    }

    return (
        <div className={style.root}>
            {stuInfo && !enrollmentLoading && !admissionLoading &&
                <div className={style.container}>

                    <div className={style.card}>
                        <div className={style.stuInfo}>
                            <ReportPage data={stuInfo}/>
                        </div>
                    </div>

                    {/*按钮区域*/}
                    <div className={style.btn}>
                        {!position.loading && <Button theme="primary" className={style.button} onClick={onHandleCheckIn}>确认签到</Button>}
                        {position.loading && <button className={style.graybutton}>正在加载...</button>}
                    </div>
                </div>
            }

            {/*未填报入学信息*/}
            { isFillIn &&
                <ConfirmToast
                    toastHight={style.notFillIn}
                    title={"签到失败"}
                    tip={
                        <div className={style.tip}>
                            <span>您尚未完成入学信息填报</span>
                            <span>请完成信息录入后重新使用微信</span>
                            <span>扫描电子签到二维码</span>
                        </div>
                    }
                >
                    <div className={style.toastContent}>
                        <Button theme="primary" className={style.notFillInBtn} onClick={() => history.push(`/enrollment`)}>去完成</Button>
                    </div>
                </ConfirmToast>
            }

            {/*未进入签到范围*/}
            {isRange &&
                <ConfirmToast
                    toastHight={style.notRange}
                    title={"签到失败"}
                    tip={
                        <div className={style.tip}>
                          您未进入签到范围
                        </div>
                    }
                >
                    <div className={style.toastContent}>
                        <Button theme="primary" className={style.notRangeBtn} onClick={() => window.location.reload()}>刷新</Button>
                    </div>
                </ConfirmToast>
            }

            {/*获取不到当前定位*/}
            { !position.loading && (position.longitude === null || position.latitude === null) &&
                <ConfirmToast
                    toastHight={style.notRange}
                    title={"签到失败"}
                    tip={
                        <div className={style.tip}>
                           获取不到当前定位
                        </div>
                    }
                >
                    <div className={style.toastContent}>
                        <Button theme="primary" className={style.notRangeBtn} onClick={() => window.location.reload()}>刷新</Button>
                    </div>
                </ConfirmToast>
            }

            {/*未开启*/}
            { isOpen && baodaoriqi &&
                <ConfirmToast
                    toastHight={style.notOpen}
                    title={"签到失败"}
                    tip={
                        <div className={style.tip}>
                            <span>签到功能将于</span>
                            <span>{dayjs(baodaoriqi.date).format("YYYY年M月D日开启")}</span>
                        </div>
                    }
                >
                    <div className={style.toastContent}>
                        <Button theme="primary" className={style.notOpenBtn} onClick={()=>setIsOpen(false)}>我知道了</Button>
                    </div>
                </ConfirmToast>
            }
        </div>
    )
}
