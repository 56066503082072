import React, {ChangeEvent, useEffect, useState} from "react";
import { useForm, Controller } from "react-hook-form";
import {
    Modal,
    Input,
    Radio,
    DatePicker,
    DateSelect,
    Checkbox,
    Picker,
    Loading,
    Button,
    Popup,
    Toast,
    Switch,
    Popper,
    Select
} from "zarm";
import style from "./index.module.scss";
import dayjs from "dayjs";
import Cell from "../../Components/Cell";
import Card from "../../Components/Card";
import {useParams} from "react-router-dom";
import useRequest from "../../Utils/useRequest";
import {errorMessage} from "../../Utils/utils";
import {DictId, FamilyInfo, getAreaInfo, getDict, getFamilyBasicInfo, getStudentInfo} from "../../Service/CheckIn";
import {
    getGoTogether,
    getGoTogetherById,
    getTrafficInfo,
    insertTrafficInfo,
    updateTrafficInfo,
    TrafficInfo
} from "../../Service/Transportation";
import BtnSubmit from "../CheckIn/components/Button";
import ModifyButton from "./components/modifyButton";
import {getHomeStudentInfo} from "../../Service/Home";
import ConfirmToast from "../../Components/ConfirmToast";
import Companion from "./components/Companion";
import CompanionDetail from "./components/CompanionDetail";
import useRequestFn from "../../Utils/useRequestFn";
import {useTitle} from "react-use";

interface Iparam {
    name:string;//填报人姓名
}

export default function Transportation() {

    useTitle("填报交通信息", {restoreOnUnmount: true});
    const {name} = useParams<Iparam>();
    const [message, setMessage] = useState('');
    const [disabled, setDisabled] = useState(false);
    const {register, handleSubmit, watch, formState: { errors }, control, setValue, getValues } = useForm();
    const [familyNumDic] = useState([{value:"1",label:"1人"},{value:"2",label:"2人"},{value:"3",label:"3人"},{value:"4",label:"4人"},{value:"5",label:"5人"}])
    const [successShow, setSuccessShow] = useState(false);//提交成功显示弹窗
    const [modifyShow, setModifyShow] = useState(false);//修改成功显示弹窗
    const [popTipShow,setPopTipShow] = useState(true);//未填报提示
    const [togShadowIsShow, setTogShadowIsShow] = useState(false);//弹窗阴影显示（动态效果）
    const [togTransformIsShow, setTogTransformIsShow] = useState(false);//弹窗内容显示
    const [buttonStatus, setButtonStatus] = useState("0");//填报状态（0：未填报 1：已填报）
    const [dataSource, setDataSource] = useState<{
        value: string;
        label: string;
        children: {
            value: string;
            label: string;
            children?: {
                value: string;
                label: string;
            }
        }[]
    }[]>([]);


    const [baseData, {loading:baseDataLoading}, loadBaseData] = useRequest(() => getTrafficInfo());//返显信息
    const [infoData, {loading:infoDataLoading}, loadInfoData] = useRequest(() => getHomeStudentInfo());//填报状态
    const [getTogetherList,,loadTogether] = useRequest(() => getGoTogether())//同行人列表
    const [loadTogById,getTogeterByPerson] = useRequestFn((personId:string) => getGoTogetherById(personId));//同行人详细信息
    const [areaData, {loading: areaDataLoading}] = useRequest(() => getAreaInfo());//省市区
    const [subjectData] = useRequestFn((param:TrafficInfo) => insertTrafficInfo(param));//提交
    const [modifyData] = useRequestFn((param:TrafficInfo) => updateTrafficInfo(param));//修改
    // （1.政治面貌，2.宗教信仰，3.请假类型，4.交通方式，5.接站时间，6.接站地点，7.入学年月，8.到校时间）
    const [trafficDic, {loading: trafficLoading}] = useRequest(() => getDict(DictId.transportation));
    const [pickTimeDic, {loading: pickTimeLoading}] = useRequest(() => getDict(DictId.stationjob_time));
    const [pickLocalDic, {loading: pickLocalLoading}] = useRequest(() => getDict(DictId.pick_station_location));
    const [pickDxTimeDic, {loading: pickDxTimeLoading}] = useRequest(() => getDict(DictId.station_time_slot));
    useEffect(() => {
        if(infoData) {
            setButtonStatus(infoData.trafficFlag);
        }
    },[infoData])

    useEffect(() => {
        if(areaData) {
            setDataSource(areaData);
        }
    }, [areaData]);

    useEffect(() => {
        if(trafficLoading && pickTimeLoading && pickLocalLoading && areaDataLoading && baseDataLoading) {
            Loading.show();
        }
        if(!trafficLoading && !pickTimeLoading && !pickLocalLoading && !areaDataLoading && !baseDataLoading) {
            Loading.hide();
            onInit();
        }
    }, [trafficLoading, pickTimeLoading, pickLocalLoading, areaDataLoading, baseDataLoading]);

    useEffect(() => {
        if(message) {
            Modal.alert({
                title: `${message}！`,
                onCancel: () => setMessage('')
            });
        }
    }, [message]);

    function onInit() {
        if(baseData) {
            if(baseData.arrivalData) {
                setValue('arrivalData', baseData.arrivalData?dayjs(baseData.arrivalData).format("YYYY-MM-DD"):"");//到校日期
            }
            if(baseData.trafficMode) {
                setValue('trafficMode', baseData.trafficMode);//交通方式code
            }
            if(baseData.outPlace) {
                setValue('outPlace', baseData.outPlace?baseData.outPlace.split('|'):"");//出发地
            }
            if(baseData.trafficNum) {
                setValue('trafficNum', baseData.trafficNum);//车次/航班
            }
            setValue('statusFlag', baseData.statusFlag === undefined ? true : baseData.statusFlag === "1" ? true : false);
            if(baseData.pickTime) {
                setValue('pickTime', baseData.pickTime);//接站时间
            }
            if(baseData.pickLocal) {
                setValue('pickLocal', baseData.pickLocal);//接站地点code
            }
            if(baseData.familyNum) {
                setValue('familyNum', baseData.familyNum);//陪同家属人数
            }
            setValue('phone', baseData.phone);//联系电话
            setValue('openFlag', baseData.openFlag=== undefined ? false :baseData.openFlag==="1"? true: false);//是否公开（0.否，1.是）
        }
    }

    // 提交相关
    async function onSubmit(data: any) {

        const phoneReg = /^1[3-9]\d{9}$/;
        if(!phoneReg.test(data.phone)) {//手机号效验
            setMessage("联系电话格式不正确请检查");
            return;
        }

        if(disabled) return;
        setDisabled(true);

        const params = {
            ...data,
            openFlag:data.openFlag? "1" : "0",//处理成0,1
            statusFlag:data.statusFlag? "1" : "0",
            outPlace:data.outPlace.length > 0 ? data.outPlace.join("|") : '',
            familyNum:data.familyNum ? data.familyNum[0] : "",
            trafficMode:data.trafficMode ? data.trafficMode[0] : "",
            pickLocal:data.pickLocal ? data.pickLocal[0] : "",
            pickTime:data.pickTime ? data.pickTime[0] : "",
        };
        console.log('params', params); // todo
        if(buttonStatus == "0") {
            try{
                const res = await subjectData(params);
                // Toast.show({ content: "提交成功！"});
                setSuccessShow(true);
                setDisabled(false);
                setButtonStatus("1");//填报状态改为已填报
                loadTogether();//同行人列表
            }catch (e) {
                Toast.show({ content: errorMessage(e)});
                setDisabled(false);
            }
        }else {
            try{
                const res = await modifyData(params);
                // Toast.show({ content: "提交成功！"});
                setModifyShow(true);
                setDisabled(false);
            }catch (e) {
                Toast.show({ content: errorMessage(e)});
                setDisabled(false);
            }
        }


    }

    function onError(error: any){
        console.log(error);
        const checkList = [
            'arrivalData',
            'trafficMode',
            'outPlace',
            'trafficNum',
            'pickLocal',
            'pickTime',
            'familyNum',
            'phone',

        ];
        const keyArr = Object.keys(error);
        for(let i=0; i<checkList.length; i++) {
            const keyName = checkList[i];
            if(keyArr.indexOf(keyName) !== -1) {
                setMessage(error[keyName].message);
                return;
            }
        }
    }
    //点击同行人信息
    function companionClick(personId:string) {
        try{
            loadTogById(personId);//加载同行人信息
            setTogShadowIsShow(true);//同行人弹窗显示
            setTimeout((()=> {
                setTogTransformIsShow(true);//同行人弹窗内容显示
            }),300);

        }catch (e) {
            Toast.show(errorMessage(e));
        }
    }
    //同行人弹窗关闭
    function togetherShadowClose() {
        setTogShadowIsShow(false);
    }

    //内容滑动隐藏
    function togetherTransFormClose() {
        setTogTransformIsShow(false);
    }

    return (
        <div className={style.root}>
            <div className={style.notice}>
                <img src={require('./img/red.png').default} />
                <span>为方便接站人员顺利接站，请填写实际交通方式</span>
            </div>

            {
                infoData?.trafficFlag == "0" && popTipShow &&
                <ConfirmToast
                    toastHight={style.notOpen}
                    title={"温馨提示"}
                    tip={
                        <div className={style.tip}>
                            <span>您还未填写您的交通方式</span>
                        </div>
                    }
                >
                    <div className={style.toastContent}>
                        <div className={style.toastRow}><span>1.</span>填报您的到校交通信息，便于学校安排接站工作；</div>
                        <div className={style.toastRow}><span>2.</span>还可以查看与您同行的同学，可以结伴一起到校报到；</div>
                        <div className={style.toastRow}><span>3.</span>如没有同行者可以稍后再查看下，或者修改您的交通方式。</div>
                        <div className={style.buttonDiv}>
                            <Button theme="primary" className={style.button} onClick={() => {setPopTipShow(false)}}>我知道了</Button>
                        </div>
                    </div>
                </ConfirmToast>
            }
            {/*成功弹窗*/}
            {successShow &&
                <div className={style.successPop}>
                    <div className={style.popDiv}>
                        <img src={require("./img/popClose.png").default} onClick={() => {setSuccessShow(false)}}/>
                    </div>
                </div>
            }
            {/*修改成功弹窗*/}
            {modifyShow &&
                <div className={style.successPop}>
                    <div className={style.popModifyDiv}>
                        <img src={require("./img/popClose.png").default} onClick={() => {setModifyShow(false)}}/>
                    </div>
                </div>
            }

            {
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Card>
                        <>
                            <Cell
                                title={
                                    <div className={style.name}>
                                        <span>姓名</span>
                                    </div>
                                }
                                className={`${style.cell} ${style.dashed}`}
                                leftClassName={style.large}
                            >
                                <Input className={style.input} value={name} disabled={true}/>
                            </Cell>

                            <Controller
                                name="arrivalData"
                                control={control}
                                rules={{ required: "请选择报到日期" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>报到日期</span>
                                            </div>
                                        }
                                        className={style.cell}>
                                        <DateSelect
                                            className={style.dateSelect}
                                            title="选择日期"
                                            placeholder="请选择日期"
                                            mode="date"
                                            defaultValue={getValues('arrivalData')}
                                            min="2021-07-01"
                                            value={getValues('arrivalData')}
                                            onOk={(value: string) => {
                                                setValue('arrivalData', dayjs(value).format("YYYY-MM-DD"))
                                            }}
                                        />
                                    </Cell>
                                )}
                            />
                            {
                                trafficDic &&
                                <Controller
                                    name="trafficMode"
                                    control={control}
                                    rules={{ required: "请选择来校方式" }}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span className={style.required}>来校方式</span>
                                                </div>
                                            }
                                            className={style.cell}
                                        >
                                            {/*修改为自驾，车次、是否需要接站、借展地点、车次信息是否公开值重置*/}
                                            <div className={style.row}>
                                                <Select
                                                    visible={true}
                                                    dataSource={trafficDic}
                                                    defaultValue={getValues("trafficMode")}
                                                    placeholder="选择来校方式"
                                                    onOk={(selected: {value: string; label: string}[]) => {
                                                        setValue("trafficMode", selected.map(item => item.value));
                                                        setValue("trafficNum",null);
                                                        setValue("statusFlag",true);
                                                        setValue("pickLocal",null);
                                                        setValue("openFlag",false);
                                                    }}
                                                />
                                            </div>
                                        </Cell>
                                    )}
                                />
                            }


                            <Controller
                                name="outPlace"
                                control={control}
                                rules={{ required: "请选择出发地" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>出发地</span>
                                            </div>
                                        }
                                        className={style.cell}
                                        leftClassName={style.large}
                                    >
                                        <Select
                                            visible={true}
                                            dataSource={dataSource}
                                            defaultValue={getValues("outPlace")}
                                            placeholder="选择出发地"
                                            onOk={(selected: {value: string; label: string}[]) => {
                                                setValue("outPlace", selected.map(item => item.value));
                                            }}
                                        />
                                    </Cell>
                                )}
                            />
                            {
                                watch("trafficMode") != '4' &&
                                <>
                                    <Controller
                                        name="trafficNum"
                                        control={control}
                                        defaultValue={getValues("trafficNum")}
                                        rules={{ required: "请填写车次/航班/船次" }}
                                        render={({ field }) => (
                                            <Cell
                                                title={
                                                    <div className={style.name}>
                                                        <span className={style.required}>车次/航班/船次</span>
                                                    </div>
                                                }
                                                className={style.cell}
                                                leftClassName={style.large}
                                            >
                                                <Input className={style.input} {...field} placeholder="请输入" maxLength={20}/>
                                            </Cell>
                                        )}
                                    />

                                    {/*修改是否接站清空接站地点（隐藏掉了功能暂时不用）*/}
                                    {/*<Controller*/}
                                    {/*    name="statusFlag"*/}
                                    {/*    control={control}*/}
                                    {/*    defaultValue={getValues("statusFlag")}*/}
                                    {/*    // rules={{ required: "请选择是否需要接站" }}*/}
                                    {/*    render={({ field }) => (*/}
                                    {/*        <Cell*/}
                                    {/*            title={*/}
                                    {/*                <div className={style.name}>*/}
                                    {/*                    <span className={style.required}>是否需要接站</span>*/}
                                    {/*                </div>*/}
                                    {/*            }*/}
                                    {/*            className={style.cell}*/}
                                    {/*            leftClassName={style.large}*/}
                                    {/*        >*/}
                                    {/*            <Switch checked={field.value}  {...field} defaultChecked={field.value}*/}
                                    {/*                    onChange={(val)=> {setValue("statusFlag", val);setValue("pickTime", null);*/}
                                    {/*                    setValue("pickLocal",null)}} />*/}
                                    {/*        </Cell>*/}
                                    {/*    )}*/}
                                    {/*/>*/}

                                    {
                                        pickLocalDic && watch("statusFlag") &&
                                        <Controller
                                            name="pickLocal"
                                            control={control}
                                            rules={{ required: "请选择到达站" }}
                                            render={({ field }) => (
                                                <Cell
                                                    title={
                                                        <div className={style.name}>
                                                            <span className={style.required}>到达站</span>
                                                        </div>
                                                    }
                                                    className={style.cell}
                                                >
                                                    <div className={style.row}>
                                                        <Select
                                                            visible={true}
                                                            dataSource={pickLocalDic}
                                                            defaultValue={getValues("pickLocal")}
                                                            placeholder="选择到达站"
                                                            onOk={(selected: {value: string; label: string}[]) => {
                                                                setValue("pickLocal", selected.map(item => item.value));
                                                            }}
                                                        />
                                                    </div>
                                                </Cell>
                                            )}
                                        />
                                    }
                                </>
                            }


                            {pickTimeDic && pickDxTimeDic &&
                                <Controller
                                    name="pickTime"
                                    control={control}
                                    rules={{ required: `请选择${watch("statusFlag") ? "到达时间" : "到校时间"}` }}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span className={style.required}>{watch("statusFlag") ? "到达时间" : "到校时间"}</span>
                                                </div>
                                            }
                                            className={style.cell}
                                        >
                                            <div className={style.row}>
                                                <Select
                                                    visible={true}
                                                    dataSource={pickDxTimeDic}
                                                    defaultValue={getValues("pickTime")}
                                                    placeholder={`选择${watch("statusFlag") ? "到达时间" : "到校时间"}`}
                                                    onOk={(selected: {value: string; label: string}[]) => {
                                                        setValue("pickTime", selected.map(item => item.value));
                                                    }}
                                                />
                                            </div>
                                        </Cell>
                                    )}
                                />
                            }


                            {familyNumDic &&
                            <Controller
                                name="familyNum"
                                control={control}
                                rules={{ required: "请选择陪同人员" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>陪同人员</span>
                                            </div>
                                        }
                                        className={style.cell}
                                    >
                                        <div className={style.row}>
                                            <Select
                                                visible={true}
                                                dataSource={familyNumDic}
                                                defaultValue={getValues("familyNum")}
                                                placeholder="选择陪同人员"
                                                onOk={(selected: {value: string; label: string}[]) => {
                                                    setValue("familyNum", selected.map(item => item.value));
                                                }}
                                            />
                                        </div>
                                    </Cell>
                                )}
                            />
                            }

                            <Controller
                                name="phone"
                                control={control}
                                defaultValue={getValues("phone")}
                                rules={{ required: "请填写联系电话" }}
                                render={({ field }) => (
                                    <Cell
                                        title={
                                            <div className={style.name}>
                                                <span className={style.required}>联系电话</span>
                                            </div>
                                        }
                                        className={style.cell}
                                        leftClassName={style.large}
                                    >
                                        <Input className={style.input} {...field} placeholder="请输入联系电话" maxLength={11}/>
                                    </Cell>
                                )}
                            />
                            {
                                <Controller
                                    name="openFlag"
                                    control={control}
                                    defaultValue={getValues("openFlag")}
                                    // rules={{ required: "请选择车次信息是否公开" }}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={`${style.name} ${style.desName}`}>
                                                    <span>车次信息是否公开</span>
                                                    <Popper
                                                        arrowPointAtCenter={false}
                                                        className={style.customArrowContent}
                                                        hasArrow
                                                        direction="top"
                                                        content="车次信息公开之后，同行的同学可以互相看到信息，方便与其他同学结伴而行，如不想使用该功能，选择默认“否”即可。"
                                                    >
                                                        <img className={style.desIcon} src={require("./img/desIcon.png").default}/>
                                                    </Popper>

                                                </div>
                                            }
                                            className={style.cell}
                                            leftClassName={style.large}
                                        >
                                            <Switch checked={field.value}  {...field} defaultChecked={field.value} onChange={(val)=> {setValue("openFlag", val)}} />
                                        </Cell>
                                    )}
                                />
                            }

                        </>
                    </Card>
                    {buttonStatus == "0" &&
                        <BtnSubmit disabled={disabled}>提交</BtnSubmit>
                    }
                    {buttonStatus == "1" &&
                        <ModifyButton disabled={disabled}>确定修改</ModifyButton>
                    }
                </form>
            }
            {/*同行人员列表*/}
            {buttonStatus == "1" && getTogetherList && getTogetherList.length > 0 &&
                <Companion dataList = {getTogetherList || []} companionClick={companionClick}/>
            }
            {getTogeterByPerson.data &&
                <CompanionDetail data={getTogeterByPerson.data} togetherShadowClose={togetherShadowClose}
                 togetherTransFormClose={togetherTransFormClose} togShadowIsShow={togShadowIsShow}  togTransformIsShow={togTransformIsShow}/>
            }
        </div>
    );
}


