import React from "react";
import style from "./index.module.scss";
import {useHistory} from "react-router-dom";
import Storage from "../../../../Utils/Storage";
import {Button} from "zarm";


export default function ZaixianbaodaoToast() {

    const history = useHistory();

    return (
        <div className={style.container}>
            <div className={style.toastDiv}>
                <div className={style.toastContent}>
                    <div className={style.toastButton} >
                        <Button theme="primary" className={style.button} onClick={() => {history.push("/zaixianbaodaoSignIn")}}>立即去报到</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
