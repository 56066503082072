import React from "react";
import {useHistory} from "react-router-dom"
import style from "./index.module.scss";
import ReportPage from "../../../../Components/ReportPage";
import avater from "./img/avater.png";
import checked from "./img/checked.png";
import {Button} from "zarm";
import {getHomeStudentInfo} from "../../../../Service/Home";
import useRequest from "../../../../Utils/useRequest";

export default function CheckSuccess() {
    const [stuInfo] = useRequest(() => getHomeStudentInfo())
    const history = useHistory()

    return (
        <div className={style.root}>
            {stuInfo &&
                <>
                    <div className={style.header}>
                        <div className={style.titlt}>已完成签到</div>
                        <div className={style.time}>{stuInfo.reportTime}</div>
                        <div className={style.avater}>
                            <div className={style.img}>
                                <img src={stuInfo.pic ? stuInfo.pic : avater} alt=""/>
                            </div>
                        </div>
                    </div>

                    <div className={style.card}>
                        <div className={style.stuInfo}>
                            <ReportPage data={stuInfo}/>
                        </div>
                        <div className={style.checked}>
                            <img src={checked} alt=""/>
                        </div>
                    </div>

                    {/*按钮区域*/}
                    <div className={style.btn}>
                        <Button theme="primary" className={style.button} onClick={() => history.push('/')}>关闭</Button>
                    </div>
                </>
            }
        </div>
    )
}
