import Request from "Utils/Requests";

const BaseUrl = process.env.REACT_APP_BASE_URL;

/**
 * 查看我的班级
 */
interface myClassInfo {
    college: string;        //学院
    instructorName: string; //辅导员名称
    weiXin: string;         //辅导员微信
    major: string;          //专业
    officePhone: string;    //辅导员办公电话
    phone: string;          //辅导员联系方式
    name: string;           //学生姓名
    classNo: string;        //班级（如1班2班等）
    bianhao: string;        //班级编号
    employeeNo: string;     //学号
    classQRCode: string;    //编辑二维码
    studentNum: string;     //学生数量
    enterYear: string;      //入学年
    pic: string;            //头像
}

export function getMyClass(){
    return Request.get<SuccessData<myClassInfo>>(`${BaseUrl}/zhxg/getMyClass`)
        .then(res => res.data.data)
}

/**
 * 查看班级具体成员
 */

export interface classStudent {
    province: string;    //省份
    name: string;        //姓名
    pic: string;         //头像
    employeeNo: string;  //学号
}

export const getClassStudent = (bianhao: string) => {
    return Request.get<SuccessData<classStudent[]>>(`${BaseUrl}/zhxg/getClassStudent`, {params: { bianhao }})
        .then(res => res.data.data)
}
