
/**
 * 从错误对象中获取错误消息
 * @param e
 * @param defaultMessage
 */
export function errorMessage(e: any, defaultMessage: string = "发生未知错误") {
    if (!e) return defaultMessage;
    const errCodes = [400, 401];

    if (e.response && !errCodes.includes(e.response.status)) return "数据请求失败";
    if (e.isAxiosError && e.message === "Network Error") return "网络异常";
    if (e.response && errCodes.includes(e.response.status) && e.response.data && e.response.data.message) return e.response.data.message;
    if (e instanceof Error && e.message) return e.message;
    if (typeof e === "string") return e;
    return defaultMessage;
}


// 将各种类型的值转成数值类型
export function toNumber(value: number | string | null | undefined) {
    if (!value) return 0;
    return Number(value) || 0;
}

// px转vw
export function rpx(num: string | number) {
    const numNoUnit = toNumber(num);
    return (numNoUnit * 100 / 750) + "vw";
}
