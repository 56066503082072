import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import Card from "../../../../Components/Card";
import Cell from "../../../../Components/Cell";
import {DateSelect, Input, Loading, Modal, Popper, Select, Switch, Toast} from "zarm";
import {Controller, useForm} from "react-hook-form";
import dayjs from "dayjs";
import BtnSubmit from "../../../CheckIn/components/Button";
import ModifyButton from "../../../Transportation/components/modifyButton";
import {errorMessage} from "../../../../Utils/utils";
import {useParams} from "react-router-dom";
import useRequest from "../../../../Utils/useRequest";
import {getHomeStudentInfo} from "../../../../Service/Home";
import {FamilyInfo, getAreaInfo, getDict, getFamilyBasicInfo, updateFamilyBasicInfo} from "../../../../Service/CheckIn";
import useLoading from "../../../../Utils/useLoading";
import {addPoorBasic, ViewPoor} from "../../../../Service/GreenChannel";
import Popup from "../../../../Components/Popup";
import _ from "underscore";

interface Idata {
    data:ViewPoor;
    onHandleMark: (id:number)=>void;
    updateFn:() => void;
}

export default function BaseInfo({data, onHandleMark, updateFn}:Idata) {

    const {register, handleSubmit, watch, formState: { errors }, control, setValue, getValues } = useForm();
    const [disabled, setDisabled] = useState(false);
    const [message, setMessage] = useState('');
    const [familyNumDic] = useState([{value:"1",label:"1人"},{value:"2",label:"2人"},{value:"3",label:"3人"}, {value:"4",label:"4人"}
        ,{value:"5",label:"5人"},{value:"6",label:"6人"},{value:"7",label:"7人"},{value:"8",label:"8人"},{value:"9",label:"9人"},{value:"10",label:"10人"}])
    const [dataSource, setDataSource] = useState<{
        value: string;
        label: string;
        children: {
            value: string;
            label: string;
            children?: {
                value: string;
                label: string;
            }
        }[]
    }[]>([]);
    const [successShow, setSuccessShow] = useState(false);//提交成功显示弹窗

    const [baseData, {loading:baseDataLoading}] = useRequest(() => getHomeStudentInfo());//基础信息
    const [areaData, {loading: areaDataLoading}] = useRequest(() => getAreaInfo());//省市区
    const [familyData, {loading:familyLoading}] = useRequest(() => getFamilyBasicInfo());//家庭基础信息
    const [urgentDic, setUrgentDic] = useState<{value:string,label:string}[]>();

    useEffect(() => {
        if(areaData) {
            setDataSource(areaData);
        }
    }, [areaData]);

    useEffect(() => {
        if(familyLoading && areaDataLoading && baseDataLoading) {
            Loading.show();
        }
        if(!familyLoading && !areaDataLoading && !baseDataLoading) {
            Loading.hide();
            onInit();
        }
    }, [familyLoading, areaDataLoading, baseDataLoading]);

    useLoading([familyLoading,areaDataLoading,baseDataLoading]);

    useEffect(() => {
        if(message) {
            Modal.alert({
                title: `${message}！`,
                onCancel: () => setMessage('')
            });
        }
    }, [message]);


    useEffect(() => {//判断登录人是男女，显示与本人关系不同
        if(baseData && baseData.sex == "1") {
            setUrgentDic([{value:"父子",label:"父子"},{value:"母子",label:"母子"},{value:"兄弟",label:"兄弟"},{value:"姐弟",label:"姐弟"},{value:"兄妹",label:"兄妹"},{value:"监护人",label:"监护人"},{value:"其他",label:"其他"}])
        }else {
            setUrgentDic([{value:"父女",label:"父女"},{value:"母女",label:"母女"},{value:"姐弟",label:"姐弟"},{value:"姐妹",label:"姐妹"},{value:"兄妹",label:"兄妹"},{value:"监护人",label:"监护人"},{value:"其他",label:"其他"}])
        }
    },[baseData])

    function onInit() {

        if(familyData) {
            Object.keys(familyData).forEach((value, index, array) => {
                if(value === 'address') {
                    const arr = familyData['address'].split('|');
                    const info = arr.pop();
                    setValue('addressArea', arr);
                    setValue('addressInfo', info);
                }else if(value === 'urgentRelation') {//与本人关系
                    setValue("urgentRelation", !_.isEmpty(data.urgentRelation)?[data.urgentRelation]:[]);
                }else {
                    setValue(value, familyData[value as keyof FamilyInfo]);
                }

            });
        }

        if(data) {
            if(data.jiatingrenkou) {
                setValue("jiatingrenkou", [data.jiatingrenkou]);
            }
            setValue('jiandangpingkunsheng', data.jiandangpingkunsheng === undefined ? false : data.jiandangpingkunsheng === "1" ? true : false);
            setValue('zuidishenghuobaozhang', data.zuidishenghuobaozhang === undefined ? false : data.zuidishenghuobaozhang === "1" ? true : false);
            setValue('tekunsheng', data.tekunsheng === undefined ? false : data.tekunsheng === "1" ? true : false);
            setValue('gucanxuesheng', data.gucanxuesheng === undefined ? false : data.gucanxuesheng	 === "1" ? true : false);
            setValue('lieshizinv', data.lieshizinv === undefined ? false : data.lieshizinv === "1" ? true : false);
            setValue('canjirenzinv', data.canjirenzinv === undefined ? false : data.canjirenzinv === "1" ? true : false);
        }
    }

    function successClose() {
        setSuccessShow(false);
    }


    // 提交相关
    async function onSubmit(data: any) {

        const phoneReg = /^1[3-9]\d{9}$/;
        if(!phoneReg.test(data.urgentPhone)) {//手机号效验
            setMessage("家庭联系人电话格式不正确请检查");
            return;
        }


        if(disabled) return;
        setDisabled(true);
        const addressArea = data.addressArea.length > 0 ? data.addressArea.join("|") : '';
        const addressInfo = data.addressInfo ? data.addressInfo : '';
        const addressParams = {
            address: `${addressArea}|${addressInfo}`,
            urgentRelation: data.urgentRelation[0],
            urgentConcat: data.urgentConcat,
            urgentPhone: data.urgentPhone,
        };
        const params = {
            jiatingrenkou:data.jiatingrenkou ? data.jiatingrenkou[0] : "",
            jiandangpingkunsheng:data.jiandangpingkunsheng? "1" : "0",//处理成0,1
            zuidishenghuobaozhang:data.zuidishenghuobaozhang? "1" : "0",
            tekunsheng:data.tekunsheng? "1" : "0",
            gucanxuesheng:data.gucanxuesheng? "1" : "0",
            lieshizinv:data.lieshizinv? "1" : "0",
            canjirenzinv:data.canjirenzinv? "1" : "0",
        }


        try{
            const res = await updateFamilyBasicInfo(addressParams);//地址修改
            const ress = await addPoorBasic(params);//信息提交
            // setSuccessShow(true);//不显示成功提示
            setDisabled(false);
            updateFn();
            onHandleMark(2);

        }catch (e) {
            Toast.show({ content: errorMessage(e)});
            setDisabled(false);
        }


    }

    function onError(error: any){
        const checkList = [
            'jiatingrenkou',
            'addressArea',
            'addressInfo',
            'urgentConcat',
            'urgentPhone',
            'urgentRelation',

        ];
        const keyArr = Object.keys(error);
        for(let i=0; i<checkList.length; i++) {
            const keyName = checkList[i];
            if(keyArr.indexOf(keyName) !== -1) {
                setMessage(error[keyName].message);
                return;
            }
        }
    }


    return (
        <div className={style.container}>
            <div className={style.baseInfoDiv}>
                <form onSubmit={handleSubmit(onSubmit, onError)}>

                    <Card>
                        <>
                            <div className={style.title}>
                                基本情况
                            </div>
                            <div className={style.formContent}>
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span>姓名</span>
                                        </div>
                                    }
                                    className={`${style.cell}`}
                                    leftClassName={style.large}
                                >
                                    <Input className={style.input} value={baseData?.name} disabled={true}/>
                                </Cell>
                                <Controller
                                    name="jiatingrenkou"
                                    control={control}
                                    rules={{ required: "请选择家庭人口" }}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span className={style.required}>家庭人口</span>
                                                </div>
                                            }
                                            className={`${style.cell} ${style.refCell}`}
                                        >
                                            <div className={style.row}>
                                                <Select
                                                    visible={true}
                                                    dataSource={familyNumDic}
                                                    defaultValue={getValues("jiatingrenkou")}
                                                    placeholder="请选择"
                                                    onOk={(selected: {value: string; label: string}[]) => {
                                                        setValue("jiatingrenkou", selected.map(item => item.value));
                                                    }}
                                                />
                                            </div>
                                        </Cell>
                                    )}
                                />
                            </div>

                        </>
                    </Card>

                    <div className={style.rowLine}></div>

                    <Card>
                        <>
                            <div className={style.title}>
                                助学贷款
                            </div>
                            <div className={`${style.formContent} ${style.resContent}`}>
                                <Cell
                                    title={
                                        <div className={style.name}>
                                            <span>申请助学贷款</span>
                                        </div>
                                    }
                                    className={`${style.cell} ${style.refCell}`}
                                    leftClassName={style.large}
                                >
                                    <Input className={style.input} value={baseData?.loanFlag?"已申请":"未申请"} disabled={true}/>
                                </Cell>
                            </div>
                        </>
                    </Card>

                    <div className={style.rowLine}></div>

                    <Card>
                        <>
                            <div className={style.title}>
                                家庭通讯信息
                            </div>
                            <div className={`${style.formContent}`}>
                                <Controller
                                    name="addressArea"
                                    control={control}
                                    rules={{ required: "请选择家庭所在地区" }}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span className={style.required}>家庭所在地区</span>
                                                </div>
                                            }
                                            className={style.cell}
                                            leftClassName={style.large}
                                        >
                                            <Select
                                                visible={true}
                                                dataSource={dataSource}
                                                defaultValue={getValues("addressArea")}
                                                placeholder="选择地区"
                                                onOk={(selected: {value: string; label: string}[]) => {
                                                    setValue("addressArea", selected.map(item => item.value));
                                                }}
                                            />
                                        </Cell>
                                    )}
                                />
                                <Controller
                                    name="addressInfo"
                                    control={control}
                                    defaultValue={getValues("addressInfo")}
                                    rules={{ required: "请填写详细地址" }}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span className={style.required}>详细地址</span>
                                                </div>
                                            }
                                            className={`${style.cell} ${style.textArea}`}
                                            leftClassName={style.large}
                                            rightClassName={style.right}
                                        >
                                            <Input className={style.input} {...field} rows={2} placeholder="请输入您的家庭地址，例如**小区万达金街" maxLength={50}/>
                                        </Cell>
                                    )}
                                />
                                <Controller
                                    name="urgentConcat"
                                    control={control}
                                    defaultValue={getValues("urgentConcat")}
                                    rules={{ required: "请填写家庭联系人姓名" }}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span className={style.required}>家庭联系人姓名</span>
                                                </div>
                                            }
                                            className={`${style.cell}`}
                                            leftClassName={style.large}
                                        >
                                            <Input className={style.input} {...field} placeholder="请输入姓名" maxLength={20}/>
                                        </Cell>
                                    )}
                                />
                                <Controller
                                    name="urgentPhone"
                                    control={control}
                                    defaultValue={getValues("urgentPhone")}
                                    rules={{ required: "请填写家庭联系人电话号码" }}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span className={style.required}>家庭联系人电话</span>
                                                </div>
                                            }
                                            className={style.cell}
                                            leftClassName={style.large}
                                        >
                                            <Input className={style.input} {...field} placeholder="请输入电话号码" maxLength={11}/>
                                        </Cell>
                                    )}
                                />
                                <Controller
                                    name="urgentRelation"
                                    control={control}
                                    rules={{ required: "请选择与本人关系" }}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span className={style.required}>与本人关系</span>
                                                </div>
                                            }
                                            className={`${style.cell} ${style.refCell}`}
                                        >
                                            <div className={style.row}>
                                                <Select
                                                    visible={true}
                                                    dataSource={urgentDic}
                                                    defaultValue={getValues("urgentRelation")}
                                                    placeholder="请选择"
                                                    onOk={(selected: {value: string; label: string}[]) => {
                                                        setValue("urgentRelation", selected.map(item => item.value));
                                                    }}
                                                />
                                            </div>
                                        </Cell>
                                    )}
                                />
                            </div>
                        </>
                    </Card>

                    <div className={style.rowLine}></div>

                    <Card>
                        <>
                            <div className={style.title}>
                                特殊群体类型
                            </div>
                            <div className={style.topTip}>
                                *&nbsp;&nbsp;特殊群体需上传证明材料
                            </div>
                            <div className={`${style.formContent} ${style.resContent}`}>
                                <Controller
                                    name="jiandangpingkunsheng"
                                    control={control}
                                    defaultValue={getValues("jiandangpingkunsheng")}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span>建档立卡贫困家庭学生</span>
                                                </div>
                                            }
                                            className={style.cell}
                                            leftClassName={style.large}
                                        >
                                            <Switch checked={field.value}  {...field} defaultChecked={field.value}
                                                    onChange={(val)=> {setValue("jiandangpingkunsheng", val)}} />
                                        </Cell>
                                    )}
                                />
                                <Controller
                                    name="zuidishenghuobaozhang"
                                    control={control}
                                    defaultValue={getValues("zuidishenghuobaozhang")}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span>最低生活保障家庭学生</span>
                                                </div>
                                            }
                                            className={style.cell}
                                            leftClassName={style.large}
                                        >
                                            <Switch checked={field.value}  {...field} defaultChecked={field.value}
                                                    onChange={(val)=> {setValue("zuidishenghuobaozhang", val)}} />
                                        </Cell>
                                    )}
                                />
                                <Controller
                                    name="tekunsheng"
                                    control={control}
                                    defaultValue={getValues("tekunsheng")}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span>特困供养学生</span>
                                                </div>
                                            }
                                            className={style.cell}
                                            leftClassName={style.large}
                                        >
                                            <Switch checked={field.value}  {...field} defaultChecked={field.value}
                                                    onChange={(val)=> {setValue("tekunsheng", val)}} />
                                        </Cell>
                                    )}
                                />
                                <Controller
                                    name="gucanxuesheng"
                                    control={control}
                                    defaultValue={getValues("gucanxuesheng")}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span>残孤学生</span>
                                                </div>
                                            }
                                            className={style.cell}
                                            leftClassName={style.large}
                                        >
                                            <Switch checked={field.value}  {...field} defaultChecked={field.value}
                                                    onChange={(val)=> {setValue("gucanxuesheng", val)}} />
                                        </Cell>
                                    )}
                                />
                                <Controller
                                    name="lieshizinv"
                                    control={control}
                                    defaultValue={getValues("lieshizinv")}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span>烈士子女</span>
                                                </div>
                                            }
                                            className={style.cell}
                                            leftClassName={style.large}
                                        >
                                            <Switch checked={field.value}  {...field} defaultChecked={field.value}
                                                    onChange={(val)=> {setValue("lieshizinv", val)}} />
                                        </Cell>
                                    )}
                                />

                                <Controller
                                    name="canjirenzinv"
                                    control={control}
                                    defaultValue={getValues("canjirenzinv")}
                                    render={({ field }) => (
                                        <Cell
                                            title={
                                                <div className={style.name}>
                                                    <span>家庭经济困难残疾学生及残疾人子女</span>
                                                </div>
                                            }
                                            className={`${style.cell} ${style.refLargeCell}`}
                                            leftClassName={style.largeName}
                                        >
                                            <Switch checked={field.value}  {...field} defaultChecked={field.value}
                                                    onChange={(val)=> {setValue("canjirenzinv", val)}} />
                                        </Cell>
                                    )}
                                />
                            </div>
                        </>
                    </Card>

                    {
                    <BtnSubmit disabled={disabled}>下一步</BtnSubmit>
                    }
                </form>
            </div>
            {successShow &&
            <Popup visible={true} type={"success"} onClose={() => successClose()} title={"提交成功"} notice={"您已成功提交基本情况信息"}/>
            }
        </div>
    );
}
