import axios from 'axios';
import Storage from "./Storage";
import {errorMessage} from "./utils";
import {Toast} from "zarm";

const Requests = axios.create();

Requests.interceptors.request.use(config => {
        const token = Storage.get("token");
        const refreshToken = Storage.get("refreshToken");
        config.headers = {...config.headers, token, refreshToken}
        return config
    }
)

Requests.interceptors.response.use((response) => {
    return response;
},
    async error => {
        if (error.response && error.response.status === 401) {

            if(error.response.data.code==="2001"){
                const { token, refreshToken } = error.response.data.data;
                await Storage.set("token", token);
                await Storage.set("refreshToken", refreshToken);

                error.response.config.headers = {...error.response.config.headers, token, refreshToken};
                return axios({...error.response.config});
            }

            await onAuthError(error);
            window.location.href = "/login";
        }
        return Promise.reject(error)
    });

// 相关方法
const onAuthError = skipUntil(async (error: any) => {
    return new Promise((resolve) => Toast.show({
        content: errorMessage(error),
        afterClose: resolve,
    }));
});

// 忽略其他请求, 直到上一次请求返回
function skipUntil(callback: any) {
    let pending = false;

    return function (...args: any[]) {
        if (pending) return;
        pending = true;

        return callback(...args).then(() => {
            pending = false;
        }, () => {
            pending = false;
        });
    };
}

export default Requests;
