import React from "react";
import style from "./index.module.scss";
import PaneWithAngle from "Components/PaneWithAngle";
import classNames from "classnames";
import {MyRoom} from "../../../../Service/Dormitory";

interface DormitoryProps {
    data: MyRoom;
    name?: string;
    stuId?: string;
}

export default function Dormitory({data, name, stuId}: DormitoryProps) {

    return (
        <PaneWithAngle>
            <div className={style.container}>
                <div className={style.header}>
                    <div className={classNames(style.title)}>{name}</div>
                    <div className={style.stuNo}><span>{stuId}</span></div>
                </div>

                <div className={classNames(style.content)}>
                    <div className={style.item}>
                        <div className={classNames(style.dot)}/>
                        <div>宿舍详情：
                            {data.gongyuqu}
                            <div className={style.line}/>
                            {data.louming}
                            <div className={style.line}/>
                            {data.roomNum}房间
                            <div className={style.line}/>
                            {data.badNum}床
                        </div>
                    </div>
                    <div className={style.item}>
                        <div className={classNames(style.dot)}/>
                        <div>容纳人数：{data.studentNum}/{data.capacity}</div>
                    </div>
                    <div className={style.classMate}>
                        <div className={classNames(style.dot)}/>
                        <div>同寝人员：{data.studentNames}</div>
                    </div>
                </div>
            </div>
        </PaneWithAngle>
    );
}
