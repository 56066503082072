import React, {useEffect, useState} from "react";
import style from "./index.module.scss";
import {Button, DateSelect, Input, Loading, Modal, Popper, Select, Switch, Toast} from "zarm";
import {addPoorBasic, addPoorEvidence, addPoorPersonEco, ViewPoor} from "../../../../Service/GreenChannel";
import Popup from "../../../../Components/Popup";
import UploadMore from "./components/UploadMore";
import useRequestFn from "../../../../Utils/useRequestFn";
import {errorMessage} from "../../../../Utils/utils";
import BtnSubmit from "../../../CheckIn/components/Button";

interface Idata {
    data:ViewPoor;
    onHandleMark: (id:number)=>void;
    updateFn: () => void;
}

export default function ProveUpload({data, onHandleMark, updateFn}:Idata) {

    const [jdlkList, setJdlkList] = useState(data.jiandangpingkunshengZhengming?data.jiandangpingkunshengZhengming.split('|'):[]);//建档立卡贫困家庭学生证明材料（如符合但无证书，请手写材料签字拍照上传）
    const [zdshList, setZdshList] = useState(data.zuidishenghuobaozhengZhengming?data.zuidishenghuobaozhengZhengming.split('|'):[]);//最低生活保障家庭学生证明材料（如符合但无证书，请手写材料签字拍照上传）
    const [tkgyList, setTkgyList] = useState(data.tekungongyangxueshengZhengming?data.tekungongyangxueshengZhengming.split('|'):[]);//特困供养学生证明材料（如符合但无证书，请手写材料签字拍照上传）
    const [gcxsList, setGcxsList] = useState(data.gucanxueshengZhengming?data.gucanxueshengZhengming.split('|'):[]);//孤残学生证明材料（如符合但无证书，请手写材料签字拍照上传）
    const [lszvList, setLszvList] = useState(data.liesizinvZhengming?data.liesizinvZhengming.split('|'):[]);//烈士子女学生证明材料（如符合但无证书，请手写材料签字拍照上传）
    const [jtjjknList, setJtjjknList] = useState(data.caijixueshengZhengming?data.caijixueshengZhengming.split('|'):[]);//家庭经济困难残疾学生及残疾人子女学生证明材料（如符合但无证书，请手写材料签字拍照上传）
    const [message, setMessage] = useState('');
    const [successShow, setSuccessShow] = useState(false);//提交成功显示弹窗

    const [subjectData] = useRequestFn((params) => addPoorEvidence(params));

    useEffect(() => {
        if(message) {
            Modal.alert({
                title: `${message}！`,
                onCancel: () => setMessage('')
            });
        }
    }, [message]);

    function jdlkUpload (value:string[]) {
        setJdlkList(value);
    }

    function zdshUpload (value:string[]) {
        setZdshList(value);
    }

    function tkgyUpload (value:string[]) {
        setTkgyList(value);
    }

    function gcxsUpload (value:string[]) {
        setGcxsList(value);
    }

    function lszvUpload (value:string[]) {
        setLszvList(value);
    }

    function jtjjknUpload (value:string[]) {
        setJtjjknList(value);
    }

    async function subject () {

        if(data.jiandangpingkunsheng == "1" && jdlkList.length == 0) {
            setMessage("请上传建档立卡贫困家庭学生证明材料");return;
        }
        if(data.zuidishenghuobaozhang == "1" && zdshList.length == 0) {
            setMessage("请上传最低生活保障家庭学生证明材料");return;
        }
        if(data.tekunsheng == "1" && tkgyList.length == 0) {
            setMessage("请上传特困供养学生证明材料");return;
        }
        if(data.gucanxuesheng == "1" && gcxsList.length == 0) {
            setMessage("请上传孤残学生证明材料");return;
        }
        if(data.lieshizinv == "1" && lszvList.length == 0) {
            setMessage("请上传烈士子女学生证明材料");return;
        }
        if(data.canjirenzinv == "1" && jtjjknList.length == 0) {
            setMessage("请上传家庭经济困难残疾学生及残疾人子女学生证明材料");return;
        }

        const params = {
            jiandangpingkunshengZhengming:jdlkList.join('|'),
            zuidishenghuobaozhengZhengming:zdshList.join('|'),
            tekungongyangxueshengZhengming:tkgyList.join('|'),
            gucanxueshengZhengming:gcxsList.join('|'),
            liesizinvZhengming:lszvList.join('|'),
            caijixueshengZhengming:jtjjknList.join('|')
        }

        try {
            await subjectData(params);
            // setSuccessShow(true);//不显示成功提示
            updateFn();
            onHandleMark(6);

        } catch (e) {
            Toast.show(errorMessage(e));
        }
    }

    function successClose() {
        setSuccessShow(false);
    }


    return (
        <div className={style.container}>
            <div className={style.proveUploadDiv}>
                <div className={style.title}>
                    <div className={style.titleFont}>证明材料上传</div>
                    <div className={style.tip}>选择特殊群体的同学需上传对应证明材料，如无上述情况，可直接点击下一步。</div>
                </div>
                <div className={style.formContent}>
                    {
                        data.jiandangpingkunsheng == "1" &&
                        <div className={style.cell}>
                            <div className={style.cellTitle}>
                                建档立卡贫困家庭学生证明材料（如符合但无证书，请手写材料签字拍照上传）
                            </div>
                            <div className={style.imgListDiv}>
                                <UploadMore  defaultImg={jdlkList} onUpload={jdlkUpload}/>
                            </div>
                        </div>
                    }
                    {
                       data.zuidishenghuobaozhang == "1" &&
                       <div className={style.cell}>
                           <div className={style.cellTitle}>
                               最低生活保障家庭学生证明材料（如符合但无证书，请手写材料签字拍照上传）
                           </div>
                           <div className={style.imgListDiv}>
                               <UploadMore  defaultImg={zdshList} onUpload={zdshUpload}/>
                           </div>
                       </div>
                    }

                    {
                        data.tekunsheng == "1" &&
                        <div className={style.cell}>
                            <div className={style.cellTitle}>
                                特困供养学生证明材料（如符合但无证书，请手写材料签字拍照上传）
                            </div>
                            <div className={style.imgListDiv}>
                                <UploadMore  defaultImg={tkgyList} onUpload={tkgyUpload}/>
                            </div>
                        </div>
                    }
                    {
                        data.gucanxuesheng == "1" &&
                        <div className={style.cell}>
                            <div className={style.cellTitle}>
                                孤残学生证明材料（如符合但无证书，请手写材料签字拍照上传）
                            </div>
                            <div className={style.imgListDiv}>
                                <UploadMore  defaultImg={gcxsList} onUpload={gcxsUpload}/>
                            </div>
                        </div>
                    }
                    {
                        data.lieshizinv == "1" &&
                        <div className={style.cell}>
                            <div className={style.cellTitle}>
                                烈士子女学生证明材料（如符合但无证书，请手写材料签字拍照上传）
                            </div>
                            <div className={style.imgListDiv}>
                                <UploadMore  defaultImg={lszvList} onUpload={lszvUpload}/>
                            </div>
                        </div>
                    }
                    {
                        data.canjirenzinv == "1" &&
                        <div className={`${style.cell}`}>
                            <div className={style.cellTitle}>
                                家庭经济困难残疾学生及残疾人子女学生证明材料（如符合但无证书，请手写材料签字拍照上传）
                            </div>
                            <div className={style.imgListDiv}>
                                <UploadMore  defaultImg={jtjjknList} onUpload={jtjjknUpload}/>
                            </div>
                        </div>
                    }
                </div>
                <div className={style.buttonDiv}>
                    <Button theme="primary" className={style.button} onClick={() => onHandleMark(4)}>上一步</Button>
                    <Button theme="primary" className={style.button} onClick={() =>subject()}>下一步</Button>
                </div>
            </div>
            {successShow &&
            <Popup visible={true} type={"success"} onClose={() => successClose()} title={"提交成功"} notice={"您已成功提交全部证明材料"}/>
            }
        </div>
    );
}
