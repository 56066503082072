import React from "react";
import style from "./index.module.scss";
import classNames from "classnames";
import right from "./img/success.png";
import error from "./img/error.png";

export default function Tips ({orphanFlag, reason}:{orphanFlag:string, reason?:string}) {
    return(

        // 孤儿审核状态（0.审核中，1.审核通过，2.审核未通过, 空：未上传）
        <div className={style.root}>
            {
                orphanFlag === "" && <div className={style.tip}>学费减免：在我省高校就读的孤儿大学生（不含部属学校），全部免除学费和宿费额，民办高校按照公办学校同类专业收费标准予以减免。</div>
            }
            {
                orphanFlag === '0' && <div className={classNames(style.tip)}><div className={style.xing}>*</div><div>您的证明材料已经上传，请等待校方审核</div></div>
            }

            {
                orphanFlag === '1' && <div className={classNames(style.tip,{[style.tipSuccess]:true})}><img src={right} alt="" className={style.iconPic}/>审核已通过，经过审核确认符合减免政策，无需交费</div>
            }

            {
                orphanFlag === '2' && <div className={classNames(style.tip,{[style.tipError]:true})}><img src={error} alt="" className={style.iconPic}/>审核未通过，{reason}</div>
            }
        </div>
    )
}
