import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom"
import ReportPage from "../../../../Components/ReportPage";
import style from "./index.module.scss";
import {Button, Toast} from "zarm";
import ConfirmToast from "../../../../Components/ConfirmToast"
import useRequest from "../../../../Utils/useRequest";
import {getIndexProjectName, HomeStudentInfo, ProjectName} from "../../../../Service/Home";
import {onlineReportCheckIn} from "../../../../Service/CheckIn";
import {getEnrollmentStatus} from "../../../../Service/Enrollment";
import {errorMessage} from "../../../../Utils/utils";

interface ElectronicPageProps{
    stuInfo: HomeStudentInfo
}

export default function ElectronicPage({stuInfo}:ElectronicPageProps) {

    const [enrollmentStatus,{loading:enrollmentLoading}] = useRequest(() => getEnrollmentStatus())  //个人信息是否填报
    const [isFillIn, setFillIn] = useState(false)  //是否填报入学信息

    const history = useHistory()




    const onHandleCheckIn = async () => {
        if(stuInfo.education === "2" || stuInfo.education === "3") {//2研究生，3博士生不效验电子头像采集
            if(enrollmentStatus?.infoFlag !== "1" || enrollmentStatus?.familyFlag !== '1')  return setFillIn(true)  //是否填报入学登记
        }else {
            if(enrollmentStatus?.infoFlag !== "1" || enrollmentStatus?.familyFlag !== '1' || enrollmentStatus?.picFlag !== '1')  return setFillIn(true)  //是否填报入学登记
        }
        try{
            const res = await onlineReportCheckIn();
            Toast.show({content:"报到成功", afterClose:()  => {
                history.push(`/zaixianbaodaoSuccess`);
            }});
        }catch (e) {
            Toast.show(errorMessage(e));
        }
    }

    return (
        <div className={style.root}>
            {stuInfo && !enrollmentLoading  &&
                <div className={style.container}>
                    <div className={style.title}>东北财经大学欢迎您</div>

                    <div className={style.card}>
                        <div className={style.stuInfo}>
                            <ReportPage data={stuInfo}/>
                        </div>
                    </div>

                    {/*按钮区域*/}
                    <div className={style.btn}>
                        <Button theme="primary" className={style.button} onClick={onHandleCheckIn}>确认报到</Button>
                    </div>
                </div>
            }

            {/*未填报入学信息*/}
            { isFillIn &&
                <ConfirmToast
                    toastHight={style.notFillIn}
                    title={"报到失败"}
                    tip={
                        <div className={style.tip}>
                            <span>您尚未完成入学信息填报</span>
                            <span>请完成信息录入后重新报到</span>
                        </div>
                    }
                >
                    <div className={style.toastContent}>
                        <Button theme="primary" className={style.notFillInBtn} onClick={() => history.push(`/enrollment`)}>去完成</Button>
                    </div>
                </ConfirmToast>
            }
        </div>
    )
}
