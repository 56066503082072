import {useEffect} from "react";
import {Loading} from "zarm";

export default function useLoading(params: boolean[], callback?: () => void) {
    useEffect(() => {
        if(params.some(item => item === true)) {
            Loading.show();
        }
        if((params.every(item => item === false))) {
            callback?.();
            Loading.hide();
        }
    }, [...params]);
}
