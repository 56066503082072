import React from "react";
import style from "./index.module.scss"
import {HomeStudentInfo} from "Service/Home";

interface ReportPageProps {
    data: HomeStudentInfo;
}

export default function ReportPage({data}:ReportPageProps) {
    return (
        <div className={style.root}>
                <div className={style.stuInfo}>
                    <ul>
                        <li><div className={style.left}>姓名</div><div className={style.right}>{data.name}</div></li>
                        <li><div className={style.left}>学号</div><div className={style.right}>{data.employeeNo}</div></li>
                        <li><div className={style.left}>学院</div><div className={style.right}>{data.college}</div></li>
                        <li><div className={style.left}>专业</div><div className={style.right}>{data.major}</div></li>
                        <li><div className={style.left}>班级</div><div className={style.right}>{data.classNo}</div></li>
                    </ul>
                </div>
        </div>
    );
}
