import React from "react";
import style from "./index.module.scss";
import {Popup as PopupZarm} from "zarm";

interface IProps{
    visible: boolean;
    title: string;
    notice: string;
    onClose: () => void;
    type: "success" | "notice";
}

export default function Popup({visible, title, onClose, notice, type}: IProps) {
    return (
        <PopupZarm
            visible={visible}
            direction="center"
        >
            <div className={style.root}>
                {type === "success" && <img className={style.img} src={require("./img/success.png").default} alt=""/>}
                {type === "notice" && <img className={style.img} src={require("./img/notice.png").default} alt=""/>}
                <div className={style.notice}>
                    {title}
                </div>
                <div className={style.description}>
                    {notice}
                </div>
                <img className={style.close} src={require("./img/close.png").default} onClick={onClose} alt=""/>
            </div>
        </PopupZarm>
    );
}

Popup.defaultProps = {
    type: 'notice'
}
