import React from "react";
import style from "./index.module.scss";
import {Button as BtnZarm} from "zarm";
import {TogetherInfo} from "../../../../Service/Transportation";

interface Props {
    dataList:TogetherInfo[];
    companionClick:(personId:string) => void;
}

export default function Companion({dataList, companionClick}:Props) {
    return (
        <div className={style.container}>
            <div className={style.companionDiv}>
                <div className={style.comTip}>
                    系统查询到有<span>{dataList.length}</span>位同学与您同行，信息如下
                </div>
                <div className={style.companionList}>
                    {
                        dataList.map((item,index) => {
                            return <div key={index} className={style.companionRow} onClick={() => companionClick(item.personId)}>
                                <div className={`${style.topIcon} ${item.sex=="1"?style.man:style.woman}`}>
                                    {index<9 ? '0'+(index+1) : index+1}
                                </div>
                                <img className={style.comHeadImg} src={item.pic}/>
                                <div className={style.name}>{item.name}</div>
                                <div className={style.area}>{item.outPlace}</div>
                            </div>
                        })
                    }
                </div>

            </div>
        </div>
    );
}
