import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import style from "./index.module.scss";
import {Button} from "zarm";
import right from "./img/right.png";
import error from "./img/error.png";
import leftLine from "./img/leftLine.png";
import rightLine from "./img/rightLine.png";
import useRequest from "../../../Utils/useRequest";
import {getOnlinePay} from "../../../Service/Payment";
import {getHomeStudentInfo, getIndexProjectName, ProjectName} from "../../../Service/Home";
import ConfirmToast from "../../../Components/ConfirmToast";
import dayjs from "dayjs";
import useTitle from "../../../Utils/useTitle";

export default function Payment () {

    useTitle("在线交费", {restoreOnUnmount: true});
    const [open, setOpen] = useState('1');
    const history = useHistory();
    const [onlineData] = useRequest(() => getOnlinePay());
    const [isOpenDate] = useRequest(() => getIndexProjectName());  //是否开启交费通道
    const [infoData] = useRequest(() => getHomeStudentInfo());
    const [zaixianjiaofei, setZaixianjiaofei] = useState<ProjectName>();

    useEffect(() => {
        if(isOpenDate){
            isOpenDate?.map((item) => {
                if(item.indexName == "zaixianjiaofei") {
                    setZaixianjiaofei(item);
                    setOpen(item.isUser)
                }
            })

        }
    },[isOpenDate])

    return (
        <div className={style.root}>
            <div className={style.paymentDiv}>
                <div className={style.stuInfo}>
                    <span>姓名：{infoData?.name}</span>
                    <span>学号：{infoData?.employeeNo}</span>
                </div>
                <div className={style.paymentRow}>
                    <div>
                        <div className={style.title}>符合学费减免政策</div>
                        <div className={style.subTitle}>In line with the exemption policy</div>
                    </div>
                    <Button theme="primary" className={style.button} onClick={() => history.push(`/tuitionWaiver/${infoData?.orphanFlag}`)}>去申请</Button>
                </div>
                <div className={style.paymentRow}>
                    <div>
                        <div className={style.title}>已申请了助学贷款</div>
                        <div className={style.subTitle}>Apply for student loan</div>
                    </div>
                    <Button theme="primary" className={style.button} onClick={() => history.push(`/studentLoans/${infoData?.name}/${infoData?.employeeNo}/${infoData?.loanFlag}`)}>去提交</Button>
                </div>
            </div>

            <div className={style.dividerLine}/>

            <div className={style.paymentDiv}>
                <div className={style.tip}>
                    { zaixianjiaofei && zaixianjiaofei.isUser === '1' && <><img src={right} alt=""/><span>温馨提示：交费通道已经开启，现在可以进行交费</span></> }
                    { zaixianjiaofei && zaixianjiaofei.isUser !== '1' && <><img src={error} alt=""/><span style={{color: '#E13F3F'}}>温馨提示：交费通道将于2021年9月1日开启</span> </> }
                </div>
                <div className={style.paymentRow} style={{marginBottom: 0}}>
                    <div>
                        <div className={style.title}>正常交费</div>
                        <div className={style.subTitle}>Normal payment</div>
                    </div>
                    {((zaixianjiaofei && zaixianjiaofei.isUser !== '1')) && <button className={style.greyButton}>去交费</button>}
                    {((zaixianjiaofei && zaixianjiaofei.isUser === '1')) && <Button theme="primary" className={style.button} onClick={() => window.open(`https://pay.dufe.edu.cn/dufecas.aspx`)}>去交费</Button>}
                </div>
                <div className={style.card}>
                    <div>
                        <img src={leftLine} className={style.leftLine}/>
                        <img src={rightLine} className={style.rightLine}/>
                    </div>
                    <div className={style.itemHead}>
                        <div>交费项目</div>
                        <div>欠费金额</div>
                        <div>交费状态</div>
                    </div>

                    {/*开启交费并且有未交费用时显示*/}
                    {onlineData?.length !== 0 && zaixianjiaofei && zaixianjiaofei.isUser === '1' && onlineData?.map(item =>
                            <div className={style.item} key={item.payItems}>
                                <div className={style.chargeName}>{item.payItems}</div>
                                <div className={style.money}>{item.money}元</div>
                                <div className={style.isPay} >{item.statusFlag}</div>
                            </div>
                        )
                    }
                    {(onlineData?.length === 0 || (zaixianjiaofei && zaixianjiaofei.isUser === '0')) &&
                            <div className={style.item} style={{color: '#999'}}>
                                <div className={style.chargeName}>--</div>
                                <div className={style.money}>--</div>
                                <div className={style.isPay} style={{color: '#999'}}>--</div>
                            </div>
                    }
                </div>
            </div>

            {/*弹窗*/}
            { open !== '1' && zaixianjiaofei &&
                <ConfirmToast
                    toastHight={style.toastHeight}
                    title={"温馨提示"}
                    tip={
                        <div className={style.toastTip}>
                            <span>交费通道将于</span>
                            <span>{dayjs(zaixianjiaofei.date).format("YYYY年MM月DD日开启")}</span>
                        </div>
                    }
                >
                    <div>
                        <Button theme="primary" className={style.toastBtn} onClick={() => setOpen('1')}>我知道了</Button>
                    </div>
                </ConfirmToast>
            }
        </div>
    )
}
