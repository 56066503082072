import React, {useEffect} from "react";
import useRequest from "../../../Utils/useRequest";
import {getHomeStudentInfo} from "../../../Service/Home";
import CheckSuccess from "./CheckSuccess"
import ElectronicPage from "./ElectronicPage"
import {updateSignQRFlag} from "../../../Service/CheckIn";
import {useTitle} from "react-use";

export default function ElectronicSignIn() {

    useTitle("新生电子报到", {restoreOnUnmount: true});
    const [stuInfo] = useRequest(() => getHomeStudentInfo())

    //记录学生是否扫描签到二维码
    useEffect(()=>{
        updateSignQRFlag()
    },[])

    return (
        <div>
            { stuInfo && stuInfo.reportFlag !== '1' && <ElectronicPage stuInfo={stuInfo}/> }
            { stuInfo && stuInfo.reportFlag === '1' && <CheckSuccess/> }
        </div>

    )
}

