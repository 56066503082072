import React from "react";
import style from "./index.module.scss";
import {Button} from "zarm";
import {useHistory} from "react-router-dom";

export default function Guide() {

    const history = useHistory();

    return (
        <div className={style.container}>
            <div className={style.guideDiv}>
                <div className={style.guideContent}>
                    <div className={style.guideTitle}>2021级新生入学指南</div>
                    <div className={style.guideSubTitle}>新生入学必看的内容都在这里</div>
                    <Button theme="primary" className={style.button} onClick={() => history.push("/admissionGuide")}>马上去看看</Button>
                </div>
            </div>
        </div>
    );
}
