import React, {useState} from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import style from './index.module.scss'
import {Button} from "zarm";

export default function Pdf() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({numPages}: any) {
        setNumPages(numPages);
    }

    return (
        <div  className={style.pdfDiv}>
            {/*todo:上线正式环境需要改变域名才能预览*/}
            {/*<Document*/}
            {/*    file="https://stu.ops.edufe.com.cn/1.pdf"*/}
            {/*    onLoadSuccess={onDocumentLoadSuccess}*/}
            {/*>*/}
            {/*    <Page pageNumber={pageNumber} />*/}
            {/*</Document>*/}
            <div className={style.pageDiv}>
                <div className={style.flex1}>
                {pageNumber !== 1 &&
                    <div>
                        <Button theme="primary" className={style.button} onClick={() => setPageNumber(pageNumber - 1)}>上一页</Button>
                    </div>
                }
                </div>
                <div className={`${style.flex1} ${style.marginBottom}`}>{pageNumber} / {numPages}</div>
                <div className={style.flex1}>
                { pageNumber!== numPages &&
                    <div>
                        <Button theme="primary" className={style.button} onClick={() => setPageNumber(pageNumber + 1)}>下一页</Button>
                    </div>
                }
                </div>
            </div>

        </div>
    );
}
