import React from "react";
import style from "./index.module.scss";
import {Select as SelectZarm} from "zarm";

export default function Select(props: any) {

    return (
        <div className={style.root}>
            <SelectZarm {...props} />
        </div>
    );
}
