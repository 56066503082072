import React, {ChangeEvent, useEffect, useState} from "react";
import style from "./index.module.scss";
import {Button, ImagePreview, Toast} from "zarm";
import {useParams} from "react-router-dom";
import Tips from "./components/Tips"
import ProgressBar from "Components/ProgressBar";
import useRequestFn from "../../../Utils/useRequestFn";
import {errorMessage} from "../../../Utils/utils";
import {getQiniuToken, uploadToQiNiu} from "../../../Service/Enrollment";
import deleteImg from "./img/deleteImg.png";
import {saveOrphanPic} from "../../../Service/Payment";
import useRequest from "../../../Utils/useRequest";
import {getHomeStudentInfo} from "../../../Service/Home";
import tuition from "./img/tuition.png";
import useTitle from "../../../Utils/useTitle";

const commonImages = [
    tuition
];

interface Iparams{
    orphanFlag:string;
}

export default function TuitionWaiver() {

    useTitle("在线交费-减免申请", {restoreOnUnmount: true});
    const [infoData, ,loadInfoData] = useRequest(() => getHomeStudentInfo(),{ autoStart: false});
    const [qiNiuImg] = useRequestFn((file: File,token:string) => uploadToQiNiu(file,token));
    const [uploadImg] = useRequestFn((pic:string) => saveOrphanPic(pic));
    const [visible, setVisible] = useState(false);    //弹窗状态
    const {orphanFlag} = useParams<Iparams>();
    const [imgList, setImgList] = useState<string[]>([]);
    const [flag, setFlag] = useState<string>(orphanFlag || "")             //审核状态

    //取照片
    useEffect(() => {
        if(infoData && infoData.orphanPic !== '') {
            setImgList(infoData.orphanPic.split('|'))
        }
    },[infoData])

    useEffect(() =>{
        if(flag !== "") {
            loadInfoData();
        }
    }, [flag])

    //上传照片-调用接口
    async function handleUpLoad() {
        if(imgList) {
            try{
                const res = await uploadImg(imgList.join('|'));
                Toast.show("上传成功！");
                setFlag('0')
                loadInfoData();

            }catch (e) {
                Toast.show(errorMessage(e));
            }
        }else {
            Toast.show("请选择照片上传");
        }
    }

    //前端删除照片-不掉删除接口
    const deletePic = (index:number) => {
        const str = imgList;
        imgList.splice(index,1)
        setImgList([...str])
    }

    //上传，修改
    async function upload(e:ChangeEvent<HTMLInputElement>, index:number) {
        if(e.target.files!.length !== 0) {
            try {
                let data = await getQiniuToken();
                let token = data.token;
                let domain = data.domain;
                const res = await qiNiuImg(e.target.files![0], token);

                if(index === -1){
                    setImgList([...imgList, `${domain}/${res.key}`])
                } else {
                    const change = imgList.map((item, i) => {
                        if(index === i) return `${domain}/${res.key}`;
                        return item;
                    });
                    setImgList(change);
                }
            } catch (e) {
                Toast.show(errorMessage(e));
            }
        }
    }

    return (
        <div className={style.root}>
            <div className={style.container}>

                <div className={style.progress}>
                    <ProgressBar auditStatus={flag}/>
                </div>

                <div className={style.tuitionDiv}>

                    <Tips orphanFlag={flag} reason={infoData?.orphanReason}/>

                    <div className={style.card}>
                        <div className={style.title}>
                            <div className={style.line}/>
                            { flag === "" ? '请上传《儿童福利证》或相关证明' : '已上传《儿童福利证》或相关证明'}

                        </div>
                        <div className={style.content}>
                            {
                                imgList.map ((item, index) =>
                                    <div className={style.message} key={item}>
                                        <div className={style.materialPic}>
                                            <img src={imgList[index]} className={style.showImg}/>
                                            {flag !== '1' && <img src={deleteImg} className={style.deleteImg} onClick={()=>deletePic(index)}/>}
                                            {flag !== '1' && <input type="file" name="file" accept="image/*" onChange={(event) => upload(event,index)}/>}
                                        </div>
                                        <div onClick={() => setVisible(true)} className={style.simpleBtn}>
                                            <span>查看样例</span>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                imgList.length <= 3 && flag !== '1' &&
                                <div className={style.message}>
                                    <div className={style.materialPic}>
                                        <input type="file" name="file" accept="image/*" onChange={(event) => upload(event,-1)}/>
                                    </div>
                                    <div onClick={() => setVisible(true)} className={style.simpleBtn}>
                                        <span>查看样例</span>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
                {flag !== '1' && flag !== '2' && <Button theme="primary" className={style.button} onClick={() => handleUpLoad()}>提交</Button>}
                {flag === '2' && <Button theme="primary" className={style.button} onClick={() => handleUpLoad()}>修改</Button>}
            </div>

            {/*孤儿证样例*/}
            <ImagePreview
                visible={visible}
                images={commonImages}
                onClose={() => setVisible(false)}
            />
        </div>
    )
}
