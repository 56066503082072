import React from "react";
import style from "./index.module.scss";
import redOne from "./img/redOne.png";
import redTwo from "./img/redTwo.png";
import whiteTwo from "./img/whiteTwo.png";
import whiteThree from "./img/whiteThree.png";
import redThree from "./img/redThree.png";
import classNames from "classnames";
import moment from "moment";

interface step {
    step1Font: string;
    step1Date: string;
    step2Font: string;
    step2Date: string;
    step3Font: string;
    step3Date: string;
}

interface ProgressBarProps {
    firstFlag: string;
    secondFlag: string;
    stepTnfo: step;
}

export default function ProgressBar({firstFlag, secondFlag, stepTnfo}: ProgressBarProps) {

    return (
        // 当前时间>学院审核时间 : 学院审核状态点亮
        // 当前时间>学校认定时间 : 学校认定状态点亮
        <div className={style.root}>
            <div className={style.container}>
                <div className={style.stepOne}>
                    <img src={redOne} alt=""/>
                    <div className={classNames(style.font, style.fontRed)}>{stepTnfo.step1Font}</div>
                    <div className={style.date}>{stepTnfo.step1Date}</div>
                </div>

                <div className={classNames(style.lineOne, {[style.lineRed]: !moment().isBefore(firstFlag)})}/>

                <div className={style.stepTwo}>
                    {moment().isBefore(firstFlag) && <img src={whiteTwo} className={style.whileImg} alt=""/>}
                    {!moment().isBefore(firstFlag) && <img src={redTwo} alt="" className={style.redImg}/>}
                    <div className={classNames(style.font, {[style.fontRed]: !moment().isBefore(firstFlag)})}>{stepTnfo.step2Font}</div>
                    <div className={style.date}>{stepTnfo.step2Date}</div>
                </div>

                <div className={classNames(style.lineTwo)}/>
                {!moment().isBefore(secondFlag) && <div className={classNames(style.lineLong)}/>}

                <div className={style.stepTwo}>
                    {moment().isBefore(secondFlag) && <img src={whiteThree} className={style.whileImg} alt=""/>}
                    {!moment().isBefore(secondFlag) && <img src={redThree} className={style.redImg} alt=""/>}
                    <div className={classNames(style.font, {[style.fontRed]: !moment().isBefore(secondFlag)})}>{stepTnfo.step3Font}</div>
                    <div className={style.date}>{stepTnfo.step3Date}</div>
                </div>
            </div>

        </div>
    )
}
