import React, {ChangeEvent, useEffect, useState} from "react";
import style from "./index.module.scss";
import ProgressBar from "../../../Components/ProgressBar";
import Tips from "./components/Tips";
import {Button, ImagePreview, Toast} from "zarm";
import {useParams} from "react-router-dom";
import {Input} from 'zarm';
import deleteImg from "../TuitionWaiver/img/deleteImg.png";
import {getQiniuToken, uploadToQiNiu} from "../../../Service/Enrollment";
import {errorMessage} from "../../../Utils/utils";
import useRequest from "../../../Utils/useRequest";
import {getHomeStudentInfo} from "../../../Service/Home";
import useRequestFn from "../../../Utils/useRequestFn";
import {saveLoanPic} from "../../../Service/Payment";
import studentLoan from "./img/studentLoan.png";
import useTitle from "../../../Utils/useTitle";

const commonImages = [
    studentLoan
];

interface Iparams{
    loanFlag:string;
    name:string;
    employeeNo:string;
}

export default function StudentLoans () {

    useTitle("在线交费-助学贷款", {restoreOnUnmount: true});
    const [infoData, ,loadInfoData] = useRequest(() => getHomeStudentInfo(),{ autoStart: false});
    const [qiNiuImg] = useRequestFn((file: File,token:string) => uploadToQiNiu(file,token));
    const [uploadImgAndCode] = useRequestFn((pic:string, receiptCode:string) => saveLoanPic(pic,receiptCode));
    const [visible, setVisible] = useState(false);
    const [imgList, setImgList] = useState<string[]>([]);
    const {loanFlag, name, employeeNo} = useParams<Iparams>();
    const [flag, setFlag] = useState<string>(loanFlag || "");             //审核状态
    const [code, setCode] = useState<string>(infoData?.receiptCode  || "")

    //取照片
    useEffect(() => {
        if(infoData && infoData.loanPic !== '') {
            setImgList(infoData.loanPic.split('|'))
        }
    },[infoData])

    useEffect(() =>{
        if(flag !== "") {
            loadInfoData();
        }
    }, [flag])

    //设置验证码表单
    useEffect(() => {
        if(infoData !== null ){
            setCode(infoData.receiptCode)
        }
    },[infoData])

    //上传照片，回执-调用接口
    async function handleUpLoad() {
        if(imgList) {
            try{
                const res = await uploadImgAndCode(imgList.join('|'), code);
                Toast.show("上传成功！");
                setFlag('0')
                loadInfoData();
            }catch (e) {
                Toast.show(errorMessage(e));
            }
        }else {
            Toast.show("请选择照片上传");
        }
    }

    //前端删除照片-不掉删除接口
    const deletePic = (index:number) => {
        const str = imgList;
        imgList.splice(index,1)
        setImgList([...str])
    }

    //上传，修改
    async function upload(e:ChangeEvent<HTMLInputElement>, index:number) {
        if(e.target.files!.length !== 0) {
            try {
                let data = await getQiniuToken();
                let token = data.token;
                let domain = data.domain;
                const res = await qiNiuImg(e.target.files![0], token);

                if(index === -1){
                    setImgList([...imgList, `${domain}/${res.key}`])
                } else {
                    const change = imgList.map((item, i) => {
                        if(index === i) return `${domain}/${res.key}`;
                        return item;
                    });
                    setImgList(change);
                }
            } catch (e) {
                Toast.show(errorMessage(e));
            }
        }
    }

    return(
        <div className={style.root}>
            <div className={style.container}>
                <div className={style.stuInfo}>
                    <span>姓名：{name}</span>
                    <span>学号：{employeeNo}</span>
                </div>

                <div className={style.progress}>
                    <ProgressBar auditStatus={flag}/>
                </div>

                <div className={style.tuitionDiv}>

                    <Tips orphanFlag={flag} reason={infoData?.loanReason}/>

                    <div className={style.card}>
                        <div className={style.code}>
                            <div className={style.line}/>
                            助学贷款申请回执验证码
                            <Input
                                clearable
                                type="number"
                                placeholder={"助学贷款申请回执验证码在生源地助学贷款受理证明上"}
                                className={style.input}
                                value={code}
                                onChange={
                                    (e: string|undefined) => {
                                        if(e && e.length < 7) {
                                            setCode(e)
                                        }else if(e?.length === 0){
                                            setCode(e)
                                        }else {
                                            return
                                        }
                                    }
                                }
                                disabled={flag === '1' ? true : false}
                                maxLength={6}
                            />

                        </div>

                        <div className={style.title}>
                            <div className={style.line}/>
                            { flag === "" ? '请拍照上传《生源地助学贷款受理证明》' : '已上传《生源地助学贷款受理证明》'}
                        </div>
                        <div className={style.content}>
                            {
                                imgList.map ((item, index) =>
                                    <div className={style.message} key={item}>
                                        <div className={style.materialPic}>
                                            <img src={imgList[index]} className={style.showImg}/>
                                            {flag !== '1' && <img src={deleteImg} className={style.deleteImg} onClick={()=>deletePic(index)}/>}
                                            {flag !== '1' &&<input type="file" name="file" accept="image/*" onChange={(event) => upload(event,index)}/>}
                                        </div>
                                        <div onClick={() => setVisible(true)} className={style.simpleBtn}>
                                            <span>查看样例</span>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                imgList.length <= 3 && flag !== '1' &&
                                <div className={style.message}>
                                    <div className={style.materialPic}>
                                        <input type="file" name="file" accept="image/*" onChange={(event) => upload(event,-1)}/>
                                    </div>
                                    <div onClick={() => setVisible(true)} className={style.simpleBtn}>
                                        <span>查看样例</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {flag !== '1' && flag !== '2' && <Button theme="primary" className={style.button} onClick={() => handleUpLoad()}>提交</Button>}
                {flag === '2' && <Button theme="primary" className={style.button} onClick={() => handleUpLoad()}>修改</Button>}
            </div>

            {/*助学贷款样例*/}
            <ImagePreview
                visible={visible}
                images={commonImages}
                onClose={() => setVisible(false)}
            />
        </div>
    )
}
